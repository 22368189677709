window.theme = window.theme || {};
window.slate = window.slate || {};

/* ================ SLATE ================ */
theme.Sections = function Sections() {
  this.constructors = {};
  this.instances = [];

  $(document)
    .on('shopify:section:load', this._onSectionLoad.bind(this))
    .on('shopify:section:unload', this._onSectionUnload.bind(this))
    .on('shopify:section:select', this._onSelect.bind(this))
    .on('shopify:section:deselect', this._onDeselect.bind(this))
    .on('shopify:block:select', this._onBlockSelect.bind(this))
    .on('shopify:block:deselect', this._onBlockDeselect.bind(this));
};

theme.Sections.prototype = _.assignIn({}, theme.Sections.prototype, {
  _createInstance: function(container, constructor) {
    var $container = $(container);
    var id = $container.attr('data-section-id');
    var type = $container.attr('data-section-type');

    constructor = constructor || this.constructors[type];

    if (_.isUndefined(constructor)) {
      return;
    }

    var instance = _.assignIn(new constructor(container), {
      id: id,
      type: type,
      container: container
    });

    this.instances.push(instance);
  },

  _onSectionLoad: function(evt) {
    var container = $('[data-section-id]', evt.target)[0];
    if (container) {
      this._createInstance(container);
    }
  },

  _onSectionUnload: function(evt) {
    this.instances = _.filter(this.instances, function(instance) {
      var isEventInstance = instance.id === evt.detail.sectionId;

      if (isEventInstance) {
        if (_.isFunction(instance.onUnload)) {
          instance.onUnload(evt);
        }
      }

      return !isEventInstance;
    });
  },

  _onSelect: function(evt) {
    // eslint-disable-next-line no-shadow
    var instance = _.find(this.instances, function(instance) {
      return instance.id === evt.detail.sectionId;
    });

    if (!_.isUndefined(instance) && _.isFunction(instance.onSelect)) {
      instance.onSelect(evt);
    }
  },

  _onDeselect: function(evt) {
    // eslint-disable-next-line no-shadow
    var instance = _.find(this.instances, function(instance) {
      return instance.id === evt.detail.sectionId;
    });

    if (!_.isUndefined(instance) && _.isFunction(instance.onDeselect)) {
      instance.onDeselect(evt);
    }
  },

  _onBlockSelect: function(evt) {
    // eslint-disable-next-line no-shadow
    var instance = _.find(this.instances, function(instance) {
      return instance.id === evt.detail.sectionId;
    });

    if (!_.isUndefined(instance) && _.isFunction(instance.onBlockSelect)) {
      instance.onBlockSelect(evt);
    }
  },

  _onBlockDeselect: function(evt) {
    // eslint-disable-next-line no-shadow
    var instance = _.find(this.instances, function(instance) {
      return instance.id === evt.detail.sectionId;
    });

    if (!_.isUndefined(instance) && _.isFunction(instance.onBlockDeselect)) {
      instance.onBlockDeselect(evt);
    }
  },

  register: function(type, constructor) {
    this.constructors[type] = constructor;

    $('[data-section-type=' + type + ']').each(
      function(index, container) {
        this._createInstance(container, constructor);
      }.bind(this)
    );
  }
});

window.slate = window.slate || {};

/**
 * iFrames
 * -----------------------------------------------------------------------------
 * Wrap videos in div to force responsive layout.
 *
 * @namespace iframes
 */

slate.rte = {
  wrapTable: function() {
    $('.rte table').wrap('<div class="rte__table-wrapper"></div>');
  },

  iframeReset: function() {
    var $iframeVideo = $(
      '.rte iframe[src*="youtube.com/embed"], .rte iframe[src*="player.vimeo"]'
    );
    var $iframeReset = $iframeVideo.add('.rte iframe#admin_bar_iframe');

    $iframeVideo.each(function() {
      // Add wrapper to make video responsive
      $(this).wrap('<div class="video-wrapper"></div>');
    });

    $iframeReset.each(function() {
      // Re-set the src attribute on each iframe after page load
      // for Chrome's "incorrect iFrame content on 'back'" bug.
      // https://code.google.com/p/chromium/issues/detail?id=395791
      // Need to specifically target video and admin bar
      this.src = this.src;
    });
  }
};

window.slate = window.slate || {};

/**
 * A11y Helpers
 * -----------------------------------------------------------------------------
 * A collection of useful functions that help make your theme more accessible
 * to users with visual impairments.
 *
 *
 * @namespace a11y
 */

slate.a11y = {
  /**
   * For use when focus shifts to a container rather than a link
   * eg for In-page links, after scroll, focus shifts to content area so that
   * next `tab` is where user expects if focusing a link, just $link.focus();
   *
   * @param {JQuery} $element - The element to be acted upon
   */
  pageLinkFocus: function($element) {
    var focusClass = 'js-focus-hidden';

    $element
      .first()
      .attr('tabIndex', '-1')
      .focus()
      .addClass(focusClass)
      .one('blur', callback);

    function callback() {
      $element
        .first()
        .removeClass(focusClass)
        .removeAttr('tabindex');
    }
  },

  /**
   * If there's a hash in the url, focus the appropriate element
   */
  focusHash: function() {
    var hash = window.location.hash;

    // is there a hash in the url? is it an element on the page?
    if (hash && document.getElementById(hash.slice(1))) {
      this.pageLinkFocus($(hash));
    }
  },

  /**
   * When an in-page (url w/hash) link is clicked, focus the appropriate element
   */
  bindInPageLinks: function() {
    $('a[href*=#]').on(
      'click',
      function(evt) {
        this.pageLinkFocus($(evt.currentTarget.hash));
      }.bind(this)
    );
  },

  /**
   * Traps the focus in a particular container
   *
   * @param {object} options - Options to be used
   * @param {jQuery} options.$container - Container to trap focus within
   * @param {jQuery} options.$elementToFocus - Element to be focused when focus leaves container
   * @param {string} options.namespace - Namespace used for new focus event handler
   */
  trapFocus: function(options) {
    var eventName = options.namespace
      ? 'focusin.' + options.namespace
      : 'focusin';

    if (!options.$elementToFocus) {
      options.$elementToFocus = options.$container;
    }

    options.$container.attr('tabindex', '-1');
    options.$elementToFocus.focus();

    $(document).on(eventName, function(evt) {
      if (
        options.$container[0] !== evt.target &&
        !options.$container.has(evt.target).length
      ) {
        options.$container.focus();
      }
    });
  },

  /**
   * Removes the trap of focus in a particular container
   *
   * @param {object} options - Options to be used
   * @param {jQuery} options.$container - Container to trap focus within
   * @param {string} options.namespace - Namespace used for new focus event handler
   */
  removeTrapFocus: function(options) {
    var eventName = options.namespace
      ? 'focusin.' + options.namespace
      : 'focusin';

    if (options.$container && options.$container.length) {
      options.$container.removeAttr('tabindex');
    }

    $(document).off(eventName);
  }
};

/**
 * Currency Helpers
 * -----------------------------------------------------------------------------
 * A collection of useful functions that help with currency formatting
 *
 * Current contents
 * - formatMoney - Takes an amount in cents and returns it as a formatted dollar value.
 *
 * Alternatives
 * - Accounting.js - http://openexchangerates.github.io/accounting.js/
 *
 */

theme.Currency = (function() {
  var moneyFormat = '${{amount}}'; // eslint-disable-line camelcase

  function formatMoney(cents, format) {
    if (typeof cents === 'string') {
      cents = cents.replace('.', '');
    }
    var value = '';
    var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    var formatString = format || moneyFormat;

    function formatWithDelimiters(number, precision, thousands, decimal) {
      thousands = thousands || ',';
      decimal = decimal || '.';

      if (isNaN(number) || number === null) {
        return 0;
      }

      number = (number / 100.0).toFixed(precision);

      var parts = number.split('.');
      var dollarsAmount = parts[0].replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        '$1' + thousands
      );
      var centsAmount = parts[1] ? decimal + parts[1] : '';

      return dollarsAmount + centsAmount;
    }

    switch (formatString.match(placeholderRegex)[1]) {
      case 'amount':
        value = formatWithDelimiters(cents, 2);
        break;
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0);
        break;
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',');
        break;
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',');
        break;
      case 'amount_no_decimals_with_space_separator':
        value = formatWithDelimiters(cents, 0, ' ');
        break;
      case 'amount_with_apostrophe_separator':
        value = formatWithDelimiters(cents, 2, "'");
        break;
    }

    return formatString.replace(placeholderRegex, value);
  }

  return {
    formatMoney: formatMoney
  };
})();

/**
 * Image Helper Functions
 * -----------------------------------------------------------------------------
 * A collection of functions that help with basic image operations.
 *
 */

theme.Images = (function() {
  /**
   * Preloads an image in memory and uses the browsers cache to store it until needed.
   *
   * @param {Array} images - A list of image urls
   * @param {String} size - A shopify image size attribute
   */

  function preload(images, size) {
    if (typeof images === 'string') {
      images = [images];
    }

    for (var i = 0; i < images.length; i++) {
      var image = images[i];
      this.loadImage(this.getSizedImageUrl(image, size));
    }
  }

  /**
   * Loads and caches an image in the browsers cache.
   * @param {string} path - An image url
   */
  function loadImage(path) {
    new Image().src = path;
  }

  /**
   * Swaps the src of an image for another OR returns the imageURL to the callback function
   * @param image
   * @param element
   * @param callback
   */
  function switchImage(image, element, callback) {
    var size = this.imageSize(element.src);
    var imageUrl = this.getSizedImageUrl(image.src, size);

    if (callback) {
      callback(imageUrl, image, element); // eslint-disable-line callback-return
    } else {
      element.src = imageUrl;
    }
  }

  /**
   * +++ Useful
   * Find the Shopify image attribute size
   *
   * @param {string} src
   * @returns {null}
   */
  function imageSize(src) {
    var match = src.match(
      /.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\\.@]/
    );

    if (match === null) {
      return null;
    } else {
      return match[1];
    }
  }

  /**
   * +++ Useful
   * Adds a Shopify size attribute to a URL
   *
   * @param src
   * @param size
   * @returns {*}
   */
  function getSizedImageUrl(src, size) {
    if (size === null) {
      return src;
    }

    if (size === 'master') {
      return this.removeProtocol(src);
    }

    var match = src.match(
      /\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i
    );

    if (match !== null) {
      var prefix = src.split(match[0]);
      var suffix = match[0];

      return this.removeProtocol(prefix[0] + '_' + size + suffix);
    }

    return null;
  }

  function removeProtocol(path) {
    return path.replace(/http(s)?:/, '');
  }

  return {
    preload: preload,
    loadImage: loadImage,
    switchImage: switchImage,
    imageSize: imageSize,
    getSizedImageUrl: getSizedImageUrl,
    removeProtocol: removeProtocol
  };
})();

/**
 * Variant Selection scripts
 * ------------------------------------------------------------------------------
 *
 * Handles change events from the variant inputs in any `cart/add` forms that may
 * exist.  Also updates the master select and triggers updates when the variants
 * price or image changes.
 *
 * @namespace variants
 */

slate.Variants = (function() {
  /**
   * Variant constructor
   *
   * @param {object} options - Settings from `product.js`
   */
  function Variants(options) {
    this.$container = options.$container;
    this.product = options.product;
    this.singleOptionSelector = options.singleOptionSelector;
    this.originalSelectorId = options.originalSelectorId;
    this.enableHistoryState = options.enableHistoryState;
    this.currentVariant = this._getVariantFromOptions();

    $(this.singleOptionSelector, this.$container).on(
      'change',
      this._onSelectChange.bind(this)
    );
  }

  Variants.prototype = _.assignIn({}, Variants.prototype, {
    /**
     * Get the currently selected options from add-to-cart form. Works with all
     * form input elements.
     *
     * @return {array} options - Values of currently selected variants
     */
    _getCurrentOptions: function() {
      var currentOptions = _.map(
        $(this.singleOptionSelector, this.$container),
        function(element) {
          var $element = $(element);
          var type = $element.attr('type');
          var currentOption = {};

          if (type === 'radio' || type === 'checkbox') {
            if ($element[0].checked) {
              currentOption.value = $element.val();
              currentOption.index = $element.data('index');

              return currentOption;
            } else {
              return false;
            }
          } else {
            currentOption.value = $element.val();
            currentOption.index = $element.data('index');

            return currentOption;
          }
        }
      );

      // remove any unchecked input values if using radio buttons or checkboxes
      currentOptions = _.compact(currentOptions);

      return currentOptions;
    },

    /**
     * Find variant based on selected values.
     *
     * @param  {array} selectedValues - Values of variant inputs
     * @return {object || undefined} found - Variant object from product.variants
     */
    _getVariantFromOptions: function() {
      var selectedValues = this._getCurrentOptions();
      var variants = this.product.variants;

      var found = _.find(variants, function(variant) {
        return selectedValues.every(function(values) {
          return _.isEqual(variant[values.index], values.value);
        });
      });

      return found;
    },

    /**
     * Event handler for when a variant input changes.
     */
    _onSelectChange: function() {
      var variant = this._getVariantFromOptions();

      this.$container.trigger({
        type: 'variantChange',
        variant: variant
      });

      if (!variant) {
        return;
      }

      this._updateMasterSelect(variant);
      this._updateImages(variant);
      this._updatePrice(variant);
      this._updateSKU(variant);
      this.currentVariant = variant;

      if (this.enableHistoryState) {
        this._updateHistoryState(variant);
      }
    },

    /**
     * Trigger event when variant image changes
     *
     * @param  {object} variant - Currently selected variant
     * @return {event}  variantImageChange
     */
    _updateImages: function(variant) {
      var variantImage = variant.featured_image || {};
      var currentVariantImage = this.currentVariant.featured_image || {};

      if (
        !variant.featured_image ||
        variantImage.src === currentVariantImage.src
      ) {
        return;
      }

      this.$container.trigger({
        type: 'variantImageChange',
        variant: variant
      });
    },

    /**
     * Trigger event when variant price changes.
     *
     * @param  {object} variant - Currently selected variant
     * @return {event} variantPriceChange
     */
    _updatePrice: function(variant) {
      if (
        variant.price === this.currentVariant.price &&
        variant.compare_at_price === this.currentVariant.compare_at_price
      ) {
        return;
      }

      this.$container.trigger({
        type: 'variantPriceChange',
        variant: variant
      });
    },

    /**
     * Trigger event when variant sku changes.
     *
     * @param  {object} variant - Currently selected variant
     * @return {event} variantSKUChange
     */
    _updateSKU: function(variant) {
      if (variant.sku === this.currentVariant.sku) {
        return;
      }

      this.$container.trigger({
        type: 'variantSKUChange',
        variant: variant
      });
    },

    /**
     * Update history state for product deeplinking
     *
     * @param  {variant} variant - Currently selected variant
     * @return {k}         [description]
     */
    _updateHistoryState: function(variant) {
      if (!history.replaceState || !variant) {
        return;
      }

      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?variant=' +
        variant.id;
      window.history.replaceState({ path: newurl }, '', newurl);
    },

    /**
     * Update hidden master select of variant change
     *
     * @param  {variant} variant - Currently selected variant
     */
    _updateMasterSelect: function(variant) {
      $(this.originalSelectorId, this.$container).val(variant.id);
    }
  });

  return Variants;
})();


/*================ MODULES ================*/
window.Drawers = (function() {
  var Drawer = function(id, position, options) {
    var defaults = {
      close: '.js-drawer-close',
      open: '.js-drawer-open-' + position,
      openClass: 'js-drawer-open',
      dirOpenClass: 'js-drawer-open-' + position
    };

    this.nodes = {
      $parent: $('body, html'),
      $page: $('.page-element'),
      $moved: $('.is-moved-by-drawer')
    };

    this.config = $.extend(defaults, options);
    this.position = position;

    this.$drawer = $('#' + id);
    this.$open = $(this.config.open);

    if (!this.$drawer.length) {
      return false;
    }

    this.drawerIsOpen = false;
    this.init();
  };

  Drawer.prototype.init = function() {
    this.$open.attr('aria-expanded', 'false');
    this.$open.on('click', $.proxy(this.open, this));
    this.$drawer.find(this.config.close).on('click', $.proxy(this.close, this));
  };

  Drawer.prototype.open = function(evt) {
    // Keep track if drawer was opened from a click, or called by another function
    var externalCall = false;

    // don't open an opened drawer
    if (this.drawerIsOpen) {
      return;
    }

    this.$open.addClass(this.config.openClass);

    // Prevent following href if link is clicked
    if (evt) {
      evt.preventDefault();
    } else {
      externalCall = true;
    }

    // Without this, the drawer opens, the click event bubbles up to $nodes.page
    // which closes the drawer.
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
      // save the source of the click, we'll focus to this on close
      this.$activeSource = $(evt.currentTarget);
    }

    if (this.drawerIsOpen && !externalCall) {
      return this.close();
    }

    // Add is-transitioning class to moved elements on open so drawer can have
    // transition for close animation
    this.nodes.$moved.addClass('is-transitioning');
    this.$drawer.prepareTransition();

    this.nodes.$parent.addClass(
      this.config.openClass + ' ' + this.config.dirOpenClass
    );
    this.drawerIsOpen = true;

    // Set focus on drawer
    slate.a11y.trapFocus({
      $container: this.$drawer,
      namespace: 'drawer_focus'
    });

    // Run function when draw opens if set
    if (
      this.config.onDrawerOpen &&
      typeof this.config.onDrawerOpen === 'function'
    ) {
      if (!externalCall) {
        this.config.onDrawerOpen();
      }
    }

    if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
      this.$activeSource.attr('aria-expanded', 'true');
    }

    this.bindEvents();
  };

  Drawer.prototype.close = function() {
    // don't close a closed drawer
    if (!this.drawerIsOpen) {
      return;
    }

    this.$open.removeClass(this.config.openClass);

    // deselect any focused form elements
    $(document.activeElement).trigger('blur');

    // Ensure closing transition is applied to moved elements, like the nav
    this.nodes.$moved.prepareTransition({ disableExisting: true });
    this.$drawer.prepareTransition({ disableExisting: true });

    this.nodes.$parent.removeClass(
      this.config.dirOpenClass + ' ' + this.config.openClass
    );

    this.drawerIsOpen = false;

    // Remove focus on drawer
    slate.a11y.removeTrapFocus({
      $container: this.$drawer,
      namespace: 'drawer_focus'
    });

    if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
      this.$activeSource.attr('aria-expanded', 'false');
    }

    this.unbindEvents();
  };

  Drawer.prototype.bindEvents = function() {
    // Lock scrolling on mobile
    this.nodes.$page.on('touchmove.drawer', function() {
      return false;
    });

    // Clicking out of drawer closes it
    this.nodes.$page.on(
      'click.drawer',
      $.proxy(function() {
        this.close();
        return false;
      }, this)
    );

    // Pressing escape closes drawer
    this.nodes.$parent.on(
      'keyup.drawer',
      $.proxy(function(evt) {
        if (evt.keyCode === 27) {
          this.close();
        }
      }, this)
    );
  };

  Drawer.prototype.unbindEvents = function() {
    this.nodes.$page.off('.drawer');
    this.nodes.$parent.off('.drawer');
  };

  return Drawer;
})();

theme.Hero = (function() {
  var selectors = {
    hero: '.hero',
    heroWrapper: '.hero-wrapper',
    heroArrows: '.hero__arrow',
    heroImage: '.hero__image',
    heroPause: '.hero__pause'
  };

  function Hero() {
    this.namespace = '.hero';
    this.$hero = $(selectors.hero);
    this.$hero.on('init' + this.namespace, this._a11y.bind(this));
    this.$hero.on('init' + this.namespace, this._arrowsInit.bind(this));

    this.$hero.slick({
      accessibility: true,
      arrows: false, // this theme has custom arrows
      draggable: false,
      autoplay: this.$hero.data('autoplay'),
      autoplaySpeed: this.$hero.data('speed')
    });

    $(selectors.heroImage).on(
      'click' + this.namespace,
      function() {
        this.$hero.slick('slickNext');
      }.bind(this)
    );

    $(selectors.heroPause).on(
      'click' + this.namespace,
      function() {
        if ($(selectors.heroPause).hasClass('is-paused')) {
          this._play();
        } else {
          this._pause();
        }
      }.bind(this)
    );
  }

  Hero.prototype = _.assignIn({}, Hero.prototype, {
    _pause: function() {
      this.$hero.slick('slickPause');
      $(selectors.heroPause).addClass('is-paused');
    },

    _play: function() {
      this.$hero.slick('slickPlay');
      $(selectors.heroPause).removeClass('is-paused');
    },

    _a11y: function(event, obj) {
      var $list = obj.$list;

      // Remove default Slick aria-live attr until slider is focused
      $list.removeAttr('aria-live');

      // When an element in the slider is focused
      // pause slideshow and set aria-live
      $(selectors.heroWrapper).on(
        'focusin' + this.namespace,
        function(evt) {
          if (!$(selectors.heroWrapper).has(evt.target).length) {
            return;
          }

          $list.attr('aria-live', 'polite');
          this._pause();
        }.bind(this)
      );

      // Resume autoplay
      $(selectors.heroWrapper).on(
        'focusout' + this.namespace,
        function(evt) {
          if (!$(selectors.heroWrapper).has(evt.target).length) {
            return;
          }

          $list.removeAttr('aria-live');
          this._play();
        }.bind(this)
      );
    },

    _arrowsInit: function(event, obj) {
      // Slider is initialized. Setup custom arrows
      var count = obj.slideCount;
      var $slider = obj.$slider;
      var $arrows = $(selectors.heroArrows);

      if ($arrows.length && count > 1) {
        $arrows.on(
          'click' + this.namespace,
          function(evt) {
            evt.preventDefault();
            if ($(evt.currentTarget).hasClass('hero__arrow--prev')) {
              $slider.slick('slickPrev');
            } else {
              $slider.slick('slickNext');
            }

            this._scrollTop();
          }.bind(this)
        );
      } else {
        $arrows.remove();
      }
    },

    _scrollTop: function() {
      var currentScroll = $(document).scrollTop();
      var heroOffset = this.$hero.offset().top;

      if (currentScroll > heroOffset) {
        $('html')
          .add('body')
          .animate(
            {
              scrollTop: heroOffset
            },
            250
          );
      }
    },

    goToSlide: function(slideIndex) {
      this.$hero.slick('slickGoTo', slideIndex);
    },

    pause: function() {
      this.$hero.slick('slickPause');
    },

    play: function() {
      this.$hero.slick('slickPlay');
    },

    destroy: function() {
      this.$hero.off(this.namespace);
      $(selectors.heroImage).off(this.namespace);
      $(selectors.heroPause).off(this.namespace);
      $(selectors.heroWrapper).off(this.namespace);
      $(selectors.heroArrows).off(this.namespace);

      this.$hero.slick('unslick');
    }
  });

  return Hero;
})();

window.Modals = (function() {
  var Modal = function(id, name, options) {
    var defaults = {
      close: '.js-modal-close',
      open: '.js-modal-open-' + name,
      openClass: 'modal--is-active'
    };

    this.$modal = $('#' + id);

    if (!this.$modal.length) {
      return false;
    }

    this.nodes = {
      $body: $('body')
    };

    this.config = $.extend(defaults, options);

    this.modalIsOpen = false;
    this.$focusOnOpen = this.config.focusOnOpen
      ? $(this.config.focusOnOpen)
      : this.$modal;
    this.init();
  };

  Modal.prototype.init = function() {
    var $openBtn = $(this.config.open);

    // Add aria controls
    $openBtn.attr('aria-expanded', 'false');

    $(this.config.open).on('click', $.proxy(this.open, this));
    this.$modal.find(this.config.close).on('click', $.proxy(this.close, this));
  };

  Modal.prototype.open = function(evt) {
    // Keep track if modal was opened from a click, or called by another function
    var externalCall = false;

    // don't open an opened modal
    if (this.modalIsOpen) {
      return;
    }

    // Prevent following href if link is clicked
    if (evt) {
      evt.preventDefault();
    } else {
      externalCall = true;
    }

    // Without this, the modal opens, the click event bubbles up to $nodes.page
    // which closes the modal.
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
      // save the source of the click, we'll focus to this on close
      this.$activeSource = $(evt.currentTarget);
    }

    if (this.modalIsOpen && !externalCall) {
      return this.close();
    }

    this.$modal.prepareTransition().addClass(this.config.openClass);
    this.nodes.$body.addClass(this.config.openClass);

    this.modalIsOpen = true;

    // Set focus on modal
    slate.a11y.trapFocus({
      $container: this.$modal,
      namespace: 'modal_focus',
      $elementToFocus: this.$focusOnOpen
    });

    if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
      this.$activeSource.attr('aria-expanded', 'true');
    }

    this.bindEvents();
  };

  Modal.prototype.close = function() {
    // don't close a closed modal
    if (!this.modalIsOpen) {
      return;
    }

    // deselect any focused form elements
    $(document.activeElement).trigger('blur');

    this.$modal.prepareTransition().removeClass(this.config.openClass);
    this.nodes.$body.removeClass(this.config.openClass);

    this.modalIsOpen = false;

    // Remove focus on modal
    slate.a11y.removeTrapFocus({
      $container: this.$modal,
      namespace: 'modal_focus'
    });

    if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
      this.$activeSource.attr('aria-expanded', 'false').focus();
    }

    this.unbindEvents();
  };

  Modal.prototype.bindEvents = function() {
    // Pressing escape closes modal
    this.nodes.$body.on(
      'keyup.modal',
      $.proxy(function(evt) {
        if (evt.keyCode === 27) {
          this.close();
        }
      }, this)
    );
  };

  Modal.prototype.unbindEvents = function() {
    this.nodes.$body.off('.modal');
  };

  return Modal;
})();

window.Meganav = (function() {
  var Meganav = function(options) {
    this.cache = {
      $document: $(document),
      $page: $('.page-element')
    };

    var defaults = {
      $meganavs: $('.meganav'),
      $megaNav: $('.meganav__nav'),
      $meganavToggle: $('.meganav-toggle'),
      $meganavDropdownContainers: $('.site-nav__dropdown-container'),
      $meganavToggleThirdLevel: $('.meganav__link-toggle'),
      $meganavLinkSecondLevel: $('.meganav__link--second-level'),
      $meganavLinkThirdLevel: $('.meganav__link--third-level'),
      $meganavDropdownThirdLevel: $('.site-nav__dropdown--third-level'),
      isOpen: false,
      preventDuplicates: false,
      closeOnPageClick: false,
      closeThirdLevelOnBlur: false,
      activeClass: 'meganav--active',
      drawerClass: 'meganav--drawer',
      meganavDropdown: '.site-nav__dropdown',
      drawerToggleClass: 'drawer__nav-toggle-btn',
      drawerNavItem: '.drawer__nav-item',
      navCollectionClass: 'meganav__nav--collection',
      thirdLevelClass: 'meganav__link-toggle',
      noAnimationClass: 'meganav--no-animation'
    };

    this.config = $.extend(defaults, options);
    this.init();
  };

  Meganav.prototype.init = function() {
    var $openBtn = this.config.$meganavToggle;

    $openBtn.on('click', $.proxy(this.requestMeganav, this));

    if (this.config.closeThirdLevelOnBlur) {
      this.config.$meganavLinkThirdLevel.on(
        'blur',
        $.proxy(this.closeThirdLevelMenu, this)
      );
    }
  };

  Meganav.prototype.requestMeganav = function(evt) {
    var $targetedMeganav;

    // Prevent following href if link is clicked
    if (evt) {
      evt.preventDefault();
    }

    // Without this, the meganav opens, the click event bubbles up to
    // theme.cache.$page which closes the drawer.
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
    }

    var $el = $(evt.currentTarget);
    var anotherNavIsOpen = this.config.isOpen;
    var isThirdLevelBtn = $el.hasClass(this.config.thirdLevelClass);

    // The $targetedMeganav is different for the drawer and non-drawer navs
    if ($el.hasClass(this.config.drawerToggleClass)) {
      $targetedMeganav = $el
        .closest(this.config.drawerNavItem)
        .children('.' + this.config.drawerClass);
    } else {
      $targetedMeganav = $el.siblings(this.config.meganavDropdown);
    }

    // Navigate to link href or close menu if already active
    if ($el.hasClass(this.config.activeClass) && $el.is('a')) {
      window.location = $el.attr('href');
      return;
    }

    // If true, don't let multiple meganavs be open simultaneously
    if (!isThirdLevelBtn && this.config.preventDuplicates) {
      this.close();
    }

    if ($targetedMeganav.hasClass(this.config.drawerClass)) {
      var isExpanded = $el.attr('aria-expanded') === 'true';

      $el
        .toggleClass(this.config.activeClass)
        .attr('aria-expanded', !isExpanded);

      $targetedMeganav.stop().slideToggle(200);
    } else {
      $el.addClass(this.config.activeClass).attr('aria-expanded', 'true');

      // Show targeted nav, letting it know whether another meganav is already open
      this.open($el, $targetedMeganav, anotherNavIsOpen);
    }

    // Setup event handlers when meganav is open
    this.bindEvents();
    this.config.isOpen = true;

    // If dropdown has third level, calculate width for container
    var $dropdown = $el.next();
    var isCollection = $dropdown
      .find(this.config.$megaNav)
      .hasClass(this.config.navCollectionClass);

    if (isCollection) {
      this.updateThirdLevelContainerWidth($el, $dropdown);
    }
  };

  Meganav.prototype.updateThirdLevelContainerWidth = function($el, $dropdown) {
    var $thirdLevel = $dropdown.find(this.config.$meganavDropdownThirdLevel);

    if (!$thirdLevel.length) {
      return;
    }

    $.each(
      $thirdLevel,
      function(key, container) {
        var $container = $(container);
        var $lastChild = $container.find('li:last-child');

        this.updateContainerWidth($container, $lastChild);
      }.bind(this)
    );
  };

  Meganav.prototype.updateContainerWidth = function(container, element) {
    var containerRect = container[0].getBoundingClientRect();
    var elementRect = element[0].getBoundingClientRect();

    if (elementRect.left < containerRect.right) {
      return;
    }

    var columnWidth = containerRect.width;
    var containerFixedWidth =
      elementRect.left + columnWidth - containerRect.left;
    var numberOfColumns = containerFixedWidth / columnWidth;
    var containerPercentageWidth = numberOfColumns * 20;

    container
      .width(containerPercentageWidth + '%')
      .find('li')
      .css('width', 100 / numberOfColumns + '%');
  };

  Meganav.prototype.open = function($el, $target, noAnimation) {
    var isThirdLevelBtn = $el.hasClass(this.config.thirdLevelClass);

    $target.addClass(this.config.activeClass);

    if (isThirdLevelBtn) {
      this.toggleSubNav($el, $target);
    }

    // Add class to override animation - CSS determined
    if (noAnimation) {
      $target.addClass(this.config.noAnimationClass);
    }
  };

  Meganav.prototype.toggleSubNav = function($el) {
    this.removeMenuActiveState();

    $el
      .addClass(this.config.activeClass)
      .attr('aria-expanded', 'true')
      .siblings(this.config.$meganavDropdownThirdLevel)
      .addClass(this.config.activeClass);

    $el.parent().addClass(this.config.activeClass);
  };

  Meganav.prototype.close = function(evt, $target) {
    if (this.config.preventDuplicates) {
      // Close all meganavs
      this.config.$meganavs.removeClass(
        [this.config.activeClass, this.config.noAnimationClass].join(' ')
      );
      this.config.$meganavToggle
        .removeClass(this.config.activeClass)
        .attr('aria-expanded', 'false');

      this.config.$meganavDropdownContainers.removeClass(
        this.config.activeClass
      );
    } else {
      // Close targeted nav
      var $targetedMeganav = $('#' + $target.attr('aria-controls'));
      $targetedMeganav.removeClass(
        [this.config.activeClass, this.config.noAnimationClass].join(' ')
      );
      $target
        .removeClass(this.config.activeClass)
        .attr('aria-expanded', 'false');
    }

    // Remove event listeners
    this.unbindEvents();
    this.config.isOpen = false;
  };

  Meganav.prototype.closeThirdLevelMenu = function(evt) {
    var $el = $(evt.currentTarget);
    var $parent = $el.parent();

    if (!$parent.is(':last-child')) {
      return;
    }

    this.config.$meganavLinkSecondLevel.one(
      'focus.meganav',
      $.proxy(function() {
        this.removeMenuActiveState();
      }, this)
    );
  };

  Meganav.prototype.removeMenuActiveState = function() {
    var activeClasses = [this.config.activeClass, this.config.noAnimationClass];

    this.config.$meganavToggleThirdLevel
      .removeClass(activeClasses.join(' '))
      .attr('aria-expanded', 'false');

    this.config.$meganavDropdownThirdLevel.removeClass(activeClasses.join(' '));
    this.config.$meganavDropdownContainers.removeClass(this.config.activeClass);
  };

  Meganav.prototype.bindEvents = function() {
    if (!this.config.closeOnPageClick) {
      return;
    }

    // Clicking away from the meganav will close it
    this.cache.$page.on('click.meganav', $.proxy(this.close, this));

    // Exception to above: clicking anywhere on the meganav will NOT close it
    this.config.$meganavs.on('click.meganav', function(evt) {
      evt.stopImmediatePropagation();
    });

    // Pressing escape closes meganav and focuses the target parent link
    this.cache.$document.on(
      'keyup.meganav',
      $.proxy(function(evt) {
        if (evt.keyCode !== 27) return;

        this.config.$meganavToggle
          .filter('.' + this.config.activeClass)
          .focus();
        this.close();
      }, this)
    );
  };

  Meganav.prototype.unbindEvents = function() {
    if (!this.config.closeOnPageClick) {
      return;
    }

    this.cache.$page.off('.meganav');
    this.config.$meganavs.off('.meganav');
    this.cache.$document.off('.meganav');
    this.config.$meganavLinkSecondLevel.off('.meganav');
    this.config.$meganavLinkThirdLevel.off('.meganav');
  };

  return Meganav;
})();

window.QtySelector = (function() {
  var QtySelector = function($el) {
    this.cache = {
      $body: $('body'),
      $subtotal: $('#CartSubtotal'),
      $discountTotal: $('#cartDiscountTotal'),
      $cartTable: $('.cart-table'),
      $cartTemplate: $('#CartProducts')
    };

    this.settings = {
      loadingClass: 'js-qty--is-loading',
      isCartTemplate: this.cache.$body.hasClass('template-cart'),
      // On the cart template, minimum is 0. Elsewhere min is 1
      minQty: this.cache.$body.hasClass('template-cart') ? 0 : 1
    };

    this.$el = $el;
    this.qtyUpdateTimeout;
    this.createInputs();
    this.bindEvents();
  };

  QtySelector.prototype.createInputs = function() {
    var $el = this.$el;

    var data = {
      value: $el.val(),
      key: $el.attr('id'),
      name: $el.attr('name'),
      line: $el.attr('data-line')
    };
    var source = $('#QuantityTemplate').html();
    var template = Handlebars.compile(source);

    this.$wrapper = $(template(data)).insertBefore($el);

    // Remove original number input
    $el.remove();
  };

  QtySelector.prototype.validateAvailability = function(line, quantity) {
    var product = theme.cartObject.items[line - 1]; // 0-based index in API
    var handle = product.handle; // needed for the ajax request
    var id = product.id; // needed to find right variant from ajax results

    var params = {
      type: 'GET',
      url: '/products/' + handle + '.js',
      dataType: 'json',
      success: $.proxy(function(cartProduct) {
        this.validateAvailabilityCallback(line, quantity, id, cartProduct);
      }, this)
    };

    $.ajax(params);
  };

  QtySelector.prototype.validateAvailabilityCallback = function(
    line,
    quantity,
    id,
    product
  ) {
    var quantityIsAvailable = true;

    // This returns all variants of a product.
    // Loop through them to get our desired one.
    for (var i = 0; i < product.variants.length; i++) {
      var variant = product.variants[i];
      if (variant.id === id) {
        break;
      }
    }

    // If the variant tracks inventory and does not sell when sold out
    // we can compare the requested with available quantity
    if (
      variant.inventory_management !== null &&
      variant.inventory_policy === 'deny'
    ) {
      if (variant.inventory_quantity < quantity) {
        // Show notification with error message
        theme.Notify.open('error', theme.strings.noStockAvailable, true);

        // Set quantity to max amount available
        this.$wrapper.find('.js-qty__input').val(variant.inventory_quantity);

        quantityIsAvailable = false;
        this.$wrapper.removeClass(this.settings.loadingClass);
      }
    }

    if (quantityIsAvailable) {
      this.updateItemQuantity(line, quantity);
    }
  };

  QtySelector.prototype.validateQty = function(qty) {
    if (parseFloat(qty) === parseInt(qty, 10) && !isNaN(qty)) {
      // We have a valid number!
    } else {
      // Not a number. Default to 1.
      qty = 1;
    }
    return parseInt(qty, 10);
  };

  QtySelector.prototype.adjustQty = function(evt) {
    var $el = $(evt.currentTarget);
    var $input = $el.siblings('.js-qty__input');
    var qty = this.validateQty($input.val());
    var line = $input.attr('data-line');

    if ($el.hasClass('js-qty__adjust--minus')) {
      qty -= 1;
      if (qty <= this.settings.minQty) {
        qty = this.settings.minQty;
      }
    } else {
      qty += 1;
    }

    if (this.settings.isCartTemplate) {
      $el.parent().addClass(this.settings.loadingClass);
      this.updateCartItemPrice(line, qty);
    } else {
      $input.val(qty);
    }
  };

  QtySelector.prototype.bindEvents = function() {
    this.$wrapper
      .find('.js-qty__adjust')
      .on('click', $.proxy(this.adjustQty, this));

    // Select input text on click
    this.$wrapper.on('click', '.js-qty__input', function() {
      this.setSelectionRange(0, this.value.length);
    });

    // If the quantity changes on the cart template, update the price
    if (this.settings.isCartTemplate) {
      this.$wrapper.on(
        'change',
        '.js-qty__input',
        $.proxy(function(evt) {
          var $input = $(evt.currentTarget);
          var line = $input.attr('data-line');
          var qty = this.validateQty($input.val());
          $input.parent().addClass(this.settings.loadingClass);
          this.updateCartItemPrice(line, qty);
        }, this)
      );
    }
  };

  QtySelector.prototype.updateCartItemPrice = function(line, qty) {
    // Update cart after short timeout so user doesn't create simultaneous ajax calls
    clearTimeout(this.qtyUpdateTimeout);
    this.qtyUpdateTimeout = setTimeout(
      $.proxy(function() {
        this.validateAvailability(line, qty);
      }, this),
      200
    );
  };

  QtySelector.prototype.updateItemQuantity = function(line, quantity) {
    var params = {
      type: 'POST',
      url: '/cart/change.js',
      data: 'quantity=' + quantity + '&line=' + line,
      dataType: 'json',
      success: $.proxy(function(cart) {
        this.updateCartItemCallback(cart);
      }, this)
    };

    $.ajax(params);
  };

  QtySelector.prototype.updateCartItemCallback = function(cart) {
    // Reload the page to show the empty cart if no items
    if (cart.item_count === 0) {
      location.reload();
      return;
    }

    // Update cart object
    theme.cartObject = cart;

    // Handlebars.js cart layout
    var data = {};
    var items = [];
    var item = {};
    var source = $('#CartProductTemplate').html();
    var template = Handlebars.compile(source);
    var prodImg;

    // Add each item to our handlebars.js data
    $.each(cart.items, function(index, cartItem) {
      /* Hack to get product image thumbnail
       *   - If image is not null
       *     - Remove file extension, add 240x240, and re-add extension
       *     - Create server relative link
       *   - A hard-coded url of no-image
      */

      if (cartItem.image === null) {
        prodImg =
          '//cdn.shopify.com/s/assets/admin/no-image-medium-cc9732cb976dd349a0df1d39816fbcc7.gif';
      } else {
        prodImg = cartItem.image
          .replace(/(\.[^.]*)$/, '_240x240$1')
          .replace('http:', '');
      }

      if (cartItem.properties !== null) {
        $.each(cartItem.properties, function(key, value) {
          if (key.charAt(0) === '_' || !value) {
            delete cartItem.properties[key];
          }
        });
      }

      // Create item's data object and add to 'items' array
      item = {
        key: cartItem.key,
        line: index + 1, // Shopify uses a 1+ index in the API
        url: cartItem.url,
        img: prodImg,
        name: cartItem.product_title,
        variation: cartItem.variant_title,
        properties: cartItem.properties,
        itemQty: cartItem.quantity,
        price: theme.Currency.formatMoney(cartItem.price, theme.moneyFormat),
        vendor: cartItem.vendor,
        linePrice: theme.Currency.formatMoney(
          cartItem.line_price,
          theme.moneyFormat
        ),
        originalLinePrice: theme.Currency.formatMoney(
          cartItem.original_line_price,
          theme.moneyFormat
        ),
        discounts: cartItem.discounts,
        discountsApplied:
          cartItem.line_price === cartItem.original_line_price ? false : true
      };

      items.push(item);
    });

    // Gather all cart data and add to DOM
    data = {
      items: items
    };
    this.cache.$cartTemplate.empty().append(template(data));

    // Create new quantity selectors
    this.cache.$cartTable.find('input[type="number"]').each(function(i, el) {
      new QtySelector($(el));
    });

    // Update the cart subtotal
    this.cache.$subtotal.html(
      theme.Currency.formatMoney(cart.total_price, theme.moneyFormat)
    );

    // Update the cart total discounts
    if (cart.total_discount > 0) {
      this.cache.$discountTotal.html(
        theme.strings.totalCartDiscount.replace(
          '[savings]',
          theme.Currency.formatMoney(cart.total_discount, theme.moneyFormat)
        )
      );
    } else {
      this.cache.$discountTotal.empty();
    }

    // Set focus on cart table
    slate.a11y.pageLinkFocus(this.cache.$cartTable);
  };

  return QtySelector;
})();

/*
  Allow product to be added to cart via ajax with
  custom success and error responses.
*/
window.AjaxCart = (function() {
  var cart = function($form) {
    this.cache = {
      $cartIconIndicator: $('.site-header__cart-indicator')
    };

    this.$form = $form;
    this.eventListeners();
  };

  cart.prototype.eventListeners = function() {
    if (this.$form.length) {
      this.$form.on('submit', $.proxy(this.addItemFromForm, this));
    }
  };

  cart.prototype.addItemFromForm = function(evt) {
    evt.preventDefault();

    var params = {
      type: 'POST',
      url: '/cart/add.js',
      data: this.$form.serialize(),
      dataType: 'json',
      success: $.proxy(function(lineItem) {
        this.success(lineItem);
      }, this),
      error: $.proxy(function(XMLHttpRequest, textStatus) {
        this.error(XMLHttpRequest, textStatus);
      }, this)
    };

    $.ajax(params);
  };

  cart.prototype.success = function() {
    theme.Notify.open('success', false, true);

    // Update cart notification bubble's state
    this.cache.$cartIconIndicator.removeClass('hide');
  };

  // Error handling reference from Shopify.onError in api.jquery.js
  cart.prototype.error = function(XMLHttpRequest) {
    var data = JSON.parse(XMLHttpRequest.responseText);

    if (data.message) {
      theme.Notify.open('error', data.description, true);
    }
  };

  return cart;
})();

window.Notify = (function() {
  var notify = function() {
    this.cache = {
      $scrollParent: $('html').add('body'),
      $notificationSuccess: $('#NotificationSuccess'),
      $notificationSuccessLink: $('#NotificationSuccess').find('a'),
      $notificationError: $('#NotificationError'),
      $notificationPromo: $('#NotificationPromo'),
      $notificationClose: $('.notification__close'),
      $notificationErrorMessage: $('.notification__message--error')
    };

    this.settings = {
      notifyActiveClass: 'notification--active',
      closeTimer: 5000,
      promoKeyName: 'promo-' + this.cache.$notificationPromo.data('text')
    };

    this.notifyTimer;
    this.$lastFocusedElement = null;

    this.isLocalStorageSupported = isLocalStorageSupported();

    this.cache.$notificationClose.on('click', this.close.bind(this));
    this.showPromo();
  };

  function isLocalStorageSupported() {
    // Return false if we are in an iframe without access to sessionStorage
    if (window.self !== window.top) {
      return false;
    }

    var testKey = 'test';
    try {
      var storage = window.sessionStorage;
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  notify.prototype.open = function(state, message, autoclose) {
    this.close();

    if (state === 'success') {
      this.cache.$notificationSuccess
        .attr('aria-hidden', false)
        .addClass(this.settings.notifyActiveClass);

      // Set last focused element to return to once success
      // notification is dismissed (by timeout)
      this.$lastFocusedElement = $(document.activeElement);

      // Set focus on link to cart after transition
      this.cache.$notificationSuccess.one(
        'TransitionEnd webkitTransitionEnd transitionend oTransitionEnd',
        $.proxy(function() {
          slate.a11y.pageLinkFocus(this.cache.$notificationSuccessLink);
        }, this)
      );

      // Fallback for no transitions
      if (this.cache.$scrollParent.hasClass('no-csstransitions')) {
        slate.a11y.pageLinkFocus(this.cache.$notificationSuccessLink);
      }
    } else {
      this.cache.$notificationErrorMessage.html(message);
      this.cache.$notificationError
        .attr('aria-hidden', false)
        .addClass(this.settings.notifyActiveClass);
    }

    // Timeout to close the notification
    if (autoclose) {
      clearTimeout(this.notifyTimer);
      this.notifyTimer = setTimeout(
        this.close.bind(this),
        this.settings.closeTimer
      );
    }
  };

  notify.prototype.close = function(evt) {
    if (evt && $(evt.currentTarget).attr('id') === 'NotificationPromoClose') {
      if (this.isLocalStorageSupported) {
        localStorage.setItem(this.settings.promoKeyName, 'hidden');
      }
    }

    // Return focus to previous element if one is defined
    // and the user has not strayed from the success notification link
    if (
      this.$lastFocusedElement &&
      this.cache.$notificationSuccessLink.is(':focus')
    ) {
      slate.a11y.pageLinkFocus(this.$lastFocusedElement);
    }

    // Close all notification bars
    this.cache.$notificationSuccess
      .attr('aria-hidden', true)
      .removeClass(this.settings.notifyActiveClass);
    this.cache.$notificationError
      .attr('aria-hidden', true)
      .removeClass(this.settings.notifyActiveClass);
    this.cache.$notificationPromo
      .attr('aria-hidden', true)
      .removeClass(this.settings.notifyActiveClass);

    // Reset last focused element
    this.$lastFocusedElement = null;
  };

  notify.prototype.showPromo = function(SFEevent) {
    // If reloaded in the storefront editor, update selectors/settings
    if (SFEevent) {
      this.initCache();
    }

    if (
      this.isLocalStorageSupported &&
      localStorage[this.settings.promoKeyName] === 'hidden'
    ) {
      return;
    }

    this.cache.$notificationPromo
      .attr('aria-hidden', false)
      .addClass(this.settings.notifyActiveClass);
  };

  return notify;
})();

theme.Maps = (function() {
  var config = {
    zoom: 14,
    styles: [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#cacaca' }, { lightness: 17 }]
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#e1e1e1' }, { lightness: 20 }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ffffff' }, { lightness: 17 }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 18 }]
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 16 }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#e1e1e1' }, { lightness: 21 }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#bbbbbb' }, { lightness: 21 }]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }]
      },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#fefefe' }, { lightness: 20 }]
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }]
      }
    ] // eslint-disable-line key-spacing, comma-spacing
  };
  var apiStatus = null;
  var mapsToLoad = [];

  function Map(container) {
    theme.$currentMapContainer = this.$container = $(container);
    var key = this.$container.data('api-key');

    if (typeof key !== 'string' || key === '') {
      return;
    }

    if (apiStatus === 'loaded') {
      var self = this;

      // Check if the script has previously been loaded with this key
      var $script = $('script[src*="' + key + '&"]');
      if ($script.length === 0) {
        $.getScript(
          'https://maps.googleapis.com/maps/api/js?key=' + key
        ).then(function() {
          apiStatus = 'loaded';
          self.createMap();
        });
      } else {
        this.createMap();
      }
    } else {
      mapsToLoad.push(this);

      if (apiStatus !== 'loading') {
        apiStatus = 'loading';
        if (typeof window.google === 'undefined') {
          $.getScript(
            'https://maps.googleapis.com/maps/api/js?key=' + key
          ).then(function() {
            apiStatus = 'loaded';
            initAllMaps();
          });
        }
      }
    }
  }

  function initAllMaps() {
    // API has loaded, load all Map instances in queue
    $.each(mapsToLoad, function(index, instance) {
      instance.createMap();
    });
  }

  function geolocate($map) {
    var deferred = $.Deferred();
    var geocoder = new google.maps.Geocoder();
    var address = $map.data('address-setting');

    geocoder.geocode({ address: address }, function(results, status) {
      if (status !== google.maps.GeocoderStatus.OK) {
        deferred.reject(status);
      }

      deferred.resolve(results);
    });

    return deferred;
  }

  Map.prototype = _.assignIn({}, Map.prototype, {
    createMap: function() {
      var $map = this.$container.find('.map-section__container');

      return geolocate($map)
        .then(
          function(results) {
            var mapOptions = {
              zoom: config.zoom,
              styles: config.styles,
              center: results[0].geometry.location,
              draggable: false,
              clickableIcons: false,
              scrollwheel: false,
              disableDoubleClickZoom: true,
              disableDefaultUI: true
            };

            var map = (this.map = new google.maps.Map($map[0], mapOptions));
            var center = (this.center = map.getCenter());
            var enablePin = $map.data('enable-pin');

            var markerColor = $map.data('marker-color');

            var markerIcon = {
              path:
                'M57.7,0C25.8,0,0,25.8,0,57.7C0,89.5,50,170,57.7,170s57.7-80.5,57.7-112.3C115.3,25.8,89.5,0,57.7,0z M57.7,85 c-14.9,0-27-12.1-27-27c0-14.9,12.1-27,27-27c14.9,0,27,12.1,27,27C84.7,72.9,72.6,85,57.7,85z',
              fillColor: markerColor,
              fillOpacity: 0.9,
              scale: 0.2,
              strokeWeight: 0
            };

            //eslint-disable-next-line no-unused-vars
            var marker = new google.maps.Marker({
              map: map,
              position: center,
              icon: markerIcon,
              visible: enablePin
            });

            google.maps.event.addDomListener(
              window,
              'resize',
              $.debounce(250, function() {
                google.maps.event.trigger(map, 'resize');
                map.setCenter(center);
              })
            );
          }.bind(this)
        )
        .fail(function() {
          var errorMessage;

          switch (status) {
            case 'ZERO_RESULTS':
              errorMessage = theme.strings.addressNoResults;
              break;
            case 'OVER_QUERY_LIMIT':
              errorMessage = theme.strings.addressQueryLimit;
              break;
            default:
              errorMessage = theme.strings.addressError;
              break;
          }

          // Only show error in the theme editor
          if (Shopify.designMode) {
            var $mapContainer = $map.parents('.map-section');

            $mapContainer.addClass('page-width map-section--load-error');
            $mapContainer.find('.map-section__content-wrapper').remove();
            $mapContainer
              .find('.map-section__wrapper')
              .html(
                '<div class="errors text-center" style="width: 100%;">' +
                  errorMessage +
                  '</div>'
              );
          }
        });
    },

    onUnload: function() {
      if (typeof window.google !== 'undefined') {
        google.maps.event.clearListeners(this.map, 'resize');
      }
    }
  });

  return Map;
})();

// Global function called by Google on auth errors.
// Show an auto error message on all map instances.
// eslint-disable-next-line camelcase, no-unused-vars
function gm_authFailure() {
  if (!Shopify.designMode) return;

  theme.$currentMapContainer.addClass('page-width map-section--load-error');
  theme.$currentMapContainer.find('.map-section__content-wrapper').remove();
  theme.$currentMapContainer
    .find('.map-section__wrapper')
    .html(
      '<div class="errors text-center" style="width: 100%;">' +
        theme.strings.authError +
        '</div>'
    );
}

theme.stickyHeader = (function() {
  var selectors = {
    searchCartWrapper: '#SiteNavSearchCart',
    stickyNavSearchCart: '#StickyNavSearchCart',
    stickyNavWrapper: '#StickNavWrapper',
    stickyBar: '#StickyBar'
  };

  var config = {
    lastScroll: 0,
    navClass: 'sticky--active',
    openTransitionClass: 'sticky--open',
    closeTransitionClass: 'sticky--close'
  };

  var cache = {};

  function cacheSelectors() {
    cache = {
      $window: $(window),
      $siteNavSearchCart: $(selectors.searchCartWrapper),
      $stickyBar: $(selectors.stickyBar)
    };
  }

  function init() {
    cacheSelectors();
    config.isActive = false;

    // Clone search/cart icons into fixed nav
    if (cache.$siteNavSearchCart.contents().length) {
      cache.$siteNavSearchCart
        .contents()
        .clone()
        .appendTo($(selectors.stickyNavSearchCart));
    }

    cache.$window.on('scroll.stickynav', $.throttle(15, stickyHeaderOnScroll));
  }

  function stickyHeaderOnScroll() {
    var scroll = cache.$window.scrollTop();
    var $el = $(selectors.stickyNavWrapper);
    var threshold = $el.offset().top + $el.height() + 10; // default for scrolling down

    // Check if scrolling up
    if (scroll < config.lastScroll) {
      threshold = $el.offset().top;
    }

    if (scroll > threshold) {
      stickNav();
    } else {
      unstickNav();
    }

    config.lastScroll = scroll;
  }

  function stickNav() {
    if (config.isActive) {
      return;
    }

    config.isActive = true;

    cache.$stickyBar.addClass(config.navClass);

    // Add open transition class after element is set to fixed
    // so CSS animation is applied correctly
    setTimeout(function() {
      cache.$stickyBar.addClass(config.openTransitionClass);
    }, 0);
  }

  function unstickNav() {
    if (!config.isActive) {
      return;
    }

    cache.$stickyBar
      .removeClass(config.openTransitionClass)
      .removeClass(config.navClass);

    config.isActive = false;
  }

  function unload() {
    $(window).off('.stickynav');
  }

  return {
    init: init,
    unload: unload
  };
})();

theme.headerNav = (function() {
  var selectors = {
    siteNav: '#SiteNav',
    siteNavCompressed: '#SiteNavCompressed',
    siteNavParent: '#SiteNavParent',
    siteNavItem: '.site-nav__item',
    stickyNavWrapper: '#StickNavWrapper',
    stickyNav: '#StickyNav'
  };

  var config = {
    lastScroll: 0,
    isActive: false,
    navClass: 'sticky--active',
    openTransitionClass: 'sticky--open',
    closeTransitionClass: 'sticky--close',
    searchShowClass: 'site-header__search-input--visible',
    searchInputClass: 'site-header__search-input',
    searchSubmitClass: 'site-header__search-submit',
    searchInnerActiveClass: 'search-header__search-inner--active'
  };

  function init() {
    sizeNav();
    initMegaNavs();
    initHeaderSearch();
    $(window).on('resize.headernav', $.debounce(250, sizeNav));
  }

  function sizeNav() {
    var navWidth = 0;
    var parentWidth = $(selectors.siteNavParent).width();
    var hideClass = 'hide';

    // Set height on nav-bar wrapper so when fixed, doesn't break layout
    $(selectors.stickyNavWrapper).height($(selectors.stickyNav).height());

    // Calculate the width of each nav item
    // after forcing them to be visible for the calculations
    $(selectors.siteNav).removeClass(hideClass);
    $(selectors.siteNavItem).each(function(i, el) {
      navWidth += $(el).width();
    });

    if (navWidth > parentWidth) {
      $(selectors.siteNav).addClass(hideClass);
      $(selectors.siteNavCompressed).removeClass(hideClass);
    } else {
      $(selectors.siteNav).removeClass(hideClass);
      $(selectors.siteNavCompressed).addClass(hideClass);
    }
  }

  function initMegaNavs() {
    new window.Meganav({
      $meganavs: $('.site-nav__dropdown'),
      $meganavToggle: $('.site-nav__link-toggle'),
      preventDuplicates: true,
      closeOnPageClick: true,
      closeThirdLevelOnBlur: true
    });

    new window.Meganav({
      $meganavs: $('.meganav--index'),
      $meganavToggle: $('.index__meganav-toggle')
    });

    new window.Meganav({
      $meganavs: $('.meganav--drawer'),
      $meganavToggle: $('.drawer__meganav-toggle')
    });
  }

  function initHeaderSearch() {
    // This function runs after the header search element
    // is duplicated into the sticky nav, meaning there are
    // two search fields to be aware of at this point.
    var $searchForm = $('.site-header__search');

    $searchForm.each(function(i, el) {
      var $form = $(el);
      var $input = $form.find('.' + config.searchInputClass);
      var $submit = $form.find('.' + config.searchSubmitClass);
      var $searchInner = $('.site-header__search-inner');

      $input.on('focus', function() {
        $searchInner.addClass(config.searchInnerActiveClass);
        $input.addClass(config.searchShowClass);
      });

      $input.on('blur', function() {
        $searchInner.removeClass(config.searchInnerActiveClass);
        if ($input.val()) {
          $input.addClass(config.searchShowClass);
        } else {
          $input.removeClass(config.searchShowClass);
        }
      });

      $submit.on('click', function(evt) {
        if ($input.hasClass(config.searchShowClass)) {
          return;
        }

        evt.preventDefault();
        $input.addClass(config.searchShowClass).focus();
      });
    });
  }

  function unload() {
    $(window).off('.stickynav');
  }

  return {
    init: init,
    unload: unload
  };
})();


/*================ TEMPLATES ================*/
theme.customerTemplates = (function() {
  function initEventListeners() {
    // Show reset password form
    $('#RecoverPassword').on('click', function(evt) {
      evt.preventDefault();
      toggleRecoverPasswordForm();
    });

    // Hide reset password form
    $('#HideRecoverPasswordLink').on('click', function(evt) {
      evt.preventDefault();
      toggleRecoverPasswordForm();
    });
  }

  /**
   *
   *  Show/Hide recover password form
   *
   */
  function toggleRecoverPasswordForm() {
    $('#RecoverPasswordForm').toggleClass('hide');
    $('#CustomerLoginForm').toggleClass('hide');
  }

  /**
   *
   *  Show reset password success message
   *
   */
  function resetPasswordSuccess() {
    // check if reset password form was successfully submited
    if (!$('.reset-password-success').length) {
      return;
    }

    // show success message
    $('#ResetSuccess').removeClass('hide');
  }

  /**
   *
   *  Show/hide customer address forms
   *
   */
  function customerAddressForm() {
    var $newAddressForm = $('#AddressNewForm');

    if (!$newAddressForm.length) {
      return;
    }

    // Initialize observers on address selectors, defined in shopify_common.js
    if (Shopify) {
      // eslint-disable-next-line no-new
      new Shopify.CountryProvinceSelector(
        'AddressCountryNew',
        'AddressProvinceNew',
        {
          hideElement: 'AddressProvinceContainerNew'
        }
      );
    }

    // Initialize each edit form's country/province selector
    $('.address-country-option').each(function() {
      var formId = $(this).data('form-id');
      var countrySelector = 'AddressCountry_' + formId;
      var provinceSelector = 'AddressProvince_' + formId;
      var containerSelector = 'AddressProvinceContainer_' + formId;

      // eslint-disable-next-line no-new
      new Shopify.CountryProvinceSelector(countrySelector, provinceSelector, {
        hideElement: containerSelector
      });
    });

    // Toggle new/edit address forms
    $('.address-new-toggle').on('click', function() {
      $newAddressForm.toggleClass('hide');
    });

    $('.address-edit-toggle').on('click', function() {
      var formId = $(this).data('form-id');
      $('#EditAddress_' + formId).toggleClass('hide');
    });

    $('.address-delete').on('click', function() {
      var $el = $(this);
      var formId = $el.data('form-id');
      var confirmMessage = $el.data('confirm-message');

      // eslint-disable-next-line no-alert
      if (
        confirm(
          confirmMessage || 'Are you sure you wish to delete this address?'
        )
      ) {
        Shopify.postLink('/account/addresses/' + formId, {
          parameters: { _method: 'delete' }
        });
      }
    });
  }

  /**
   *
   *  Check URL for reset password hash
   *
   */
  function checkUrlHash() {
    var hash = window.location.hash;

    // Allow deep linking to recover password form
    if (hash === '#recover') {
      toggleRecoverPasswordForm();
    }
  }

  return {
    init: function() {
      checkUrlHash();
      initEventListeners();
      resetPasswordSuccess();
      customerAddressForm();
    }
  };
})();


/*================ SECTIONS ================*/
theme.HeaderSection = (function() {
  function Header() {
    theme.stickyHeader.init();
    theme.headerNav.init();
    theme.Notify = new window.Notify();
    theme.NavDrawer = new window.Drawers('NavDrawer', 'left');
    drawerSearch();
  }

  function drawerSearch() {
    var $drawerSearch = $('.drawer__search-input');
    var $drawerSearchSubmit = $('.drawer__search-submit');

    $drawerSearchSubmit.on('click', function(evt) {
      if ($drawerSearch.val().length !== 0) {
        return;
      }

      evt.preventDefault();
      $drawerSearch.focus();
    });
  }

  Header.prototype = _.assignIn({}, Header.prototype, {
    onUnload: function() {
      theme.stickyHeader.unload();
      theme.headerNav.unload();
    }
  });

  return Header;
})();

theme.Filters = (function() {
  var selectors = {
    filterSelection: '#SortTags',
    sortSelection: '#SortBy'
  };

  function Filters() {
    this.$filterSelect = $(selectors.filterSelection);
    this.$sortSelect = $(selectors.sortSelection);

    this.default = this._getDefaultSortValue();
    this.$sortSelect.val(this.default);

    this.$filterSelect.on('change', this._onFilterChange.bind(this));
    this.$sortSelect.on('change', this._onSortChange.bind(this));
  }

  Filters.prototype = _.assignIn({}, Filters.prototype, {
    _getDefaultSortValue: function() {
      return Shopify.queryParams.sort_by
        ? Shopify.queryParams.sort_by
        : this.$sortSelect.data('default-sort');
    },

    _onFilterChange: function() {
      delete Shopify.queryParams.page;
      if ($.isEmptyObject(Shopify.queryParams)) {
        location.href = this.$filterSelect.val();
      } else {
        location.href =
          this.$filterSelect.val() + '?' + $.param(Shopify.queryParams);
      }
    },

    _onSortChange: function() {
      Shopify.queryParams.sort_by = this.$sortSelect.val();
      location.search = $.param(Shopify.queryParams);
    },

    onUnload: function() {
      this.$filterSelect.off('change', this._onFilterChange);
      this.$sortSelect.off('change', this._onSortChange);
    }
  });

  return Filters;
})();

theme.Product = (function() {
  var defaults = {
    smallBreakpoint: 750, // copied from variables.scss
    productThumbIndex: 0,
    productThumbMax: 0,
    ajaxCart: false,
    stockSetting: false
  };

  function Product(container) {
    var $container = (this.$container = $(container));
    var sectionId = $container.attr('data-section-id');

    this.selectors = {
      originalSelectorId: '#ProductSelect-' + sectionId,
      modal: 'ProductModal',
      productZoomImage: '#ProductZoomImg',
      addToCart: '#AddToCart-' + sectionId,
      productPrice: '#ProductPrice-' + sectionId,
      comparePrice: '#ComparePrice-' + sectionId,
      addToCartText: '#AddToCartText-' + sectionId,
      SKU: '.variant-sku',
      productFeaturedImage: '#ProductPhotoImg-' + sectionId,
      productImageWrap: '#ProductPhotoLink-' + sectionId,
      productThumbAllImages: '.product-single__thumbnail',
      productThumbImages: '.product-single__thumbnail-' + sectionId,
      productThumbs: '#ProductThumbs-' + sectionId,
      saleTag: '#ProductSaleTag-' + sectionId,
      productStock: '#ProductStock-' + sectionId,
      singleOptionSelector: '.single-option-selector-' + sectionId
    };

    this.settings = $.extend({}, defaults, {
      sectionId: sectionId,
      ajaxCart: $container.data('ajax'),
      stockSetting: $container.data('stock'),
      enableHistoryState: $container.data('enable-history-state') || false,
      namespace: '.product-' + sectionId,
      imageSize: theme.Images.imageSize(
        $(this.selectors.productFeaturedImage).attr('src')
      )
    });

    // Stop parsing if we don't have the product json script tag
    if (!$('#ProductJson-' + sectionId).html()) {
      return;
    }

    this.productSingleObject = JSON.parse(
      $('#ProductJson-' + sectionId).html()
    );
    this.addVariantInfo();

    this.init();
  }

  Product.prototype = _.assignIn({}, Product.prototype, {
    init: function() {
      this._stringOverrides();
      this._initVariants();
      this._productZoomImage();
      this._productThumbSwitch();
      this._productThumbnailSlider();
      this._initQtySelector();

      if (this.settings.ajaxCart) {
        theme.AjaxCart = new window.AjaxCart($('#AddToCartForm'));
      }

      // Pre-loading product images to avoid a lag when a thumbnail is clicked, or
      // when a variant is selected that has a variant image.
      theme.Images.preload(
        this.productSingleObject.images,
        this.settings.imageSize
      );
    },

    _stringOverrides: function() {
      window.productStrings = window.productStrings || {};
      $.extend(theme.strings, window.productStrings);
    },

    addVariantInfo: function() {
      if (!this.productSingleObject || !this.settings.stockSetting) {
        return;
      }

      var variantInfo = JSON.parse(
        $('#VariantJson-' + this.settings.sectionId).html()
      );

      for (var i = 0; i < variantInfo.length; i++) {
        $.extend(this.productSingleObject.variants[i], variantInfo[i]);
      }
    },

    _initVariants: function() {
      var options = {
        $container: this.$container,
        enableHistoryState: this.settings.enableHistoryState,
        product: this.productSingleObject,
        singleOptionSelector: this.selectors.singleOptionSelector,
        originalSelectorId: this.selectors.originalSelectorId
      };

      // eslint-disable-next-line no-new
      this.variants = new slate.Variants(options);

      this.$container.on(
        'variantChange' + this.settings.namespace,
        this._updateAddToCartBtn.bind(this)
      );
      this.$container.on(
        'variantPriceChange' + this.settings.namespace,
        this._updatePrice.bind(this)
      );
      this.$container.on(
        'variantSKUChange' + this.settings.namespace,
        this._updateSKU.bind(this)
      );
      this.$container.on(
        'variantImageChange' + this.settings.namespace,
        this._updateImages.bind(this)
      );
    },

    _updateAddToCartBtn: function(evt) {
      var variant = evt.variant;

      var cache = {
        $stock: $(this.selectors.productStock),
        $addToCart: $(this.selectors.addToCart),
        $addToCartText: $(this.selectors.addToCartText)
      };

      if (variant) {
        // Select a valid variant if available
        if (variant.available) {
          // We have a valid product variant, so enable the submit button
          cache.$addToCart.removeClass('btn--sold-out').prop('disabled', false);
          cache.$addToCartText.html(theme.strings.addToCart);
          // Show how many items are left, if below 10
          if (this.settings.stockSetting) {
            if (variant.inventory_management) {
              if (
                variant.inventory_quantity < 10 &&
                variant.inventory_quantity > 0
              ) {
                cache.$stock
                  .html(
                    theme.strings.stockAvailable.replace(
                      '1',
                      variant.inventory_quantity
                    )
                  )
                  .removeClass('hide');
              } else if (variant.inventory_quantity <= 0 && variant.incoming) {
                cache.$stock
                  .html(
                    theme.strings.willNotShipUntil.replace(
                      '[date]',
                      variant.next_incoming_date
                    )
                  )
                  .removeClass('hide');
              } else {
                cache.$stock.addClass('hide');
              }
            } else {
              cache.$stock.addClass('hide');
            }
          }
        } else {
          // Variant is sold out, disable the submit button
          cache.$addToCart.prop('disabled', true).addClass('btn--sold-out');
          cache.$addToCartText.html(theme.strings.soldOut);
          if (this.settings.stockSetting) {
            if (variant.incoming) {
              cache.$stock
                .html(
                  theme.strings.willBeInStockAfter.replace(
                    '[date]',
                    variant.next_incoming_date
                  )
                )
                .removeClass('hide');
            } else {
              cache.$stock.addClass('hide');
            }
          }
        }
      } else {
        cache.$addToCart.prop('disabled', true).removeClass('btn--sold-out');
        cache.$addToCartText.html(theme.strings.unavailable);
        if (this.settings.stockSetting) {
          cache.$stock.addClass('hide');
        }
      }
    },

    _updatePrice: function(evt) {
      var variant = evt.variant;

      if (variant) {
        $(this.selectors.productPrice).html(
          theme.Currency.formatMoney(variant.price, theme.moneyFormat)
        );

        // Update and show the product's compare price if necessary
        if (variant.compare_at_price > variant.price) {
          $(this.selectors.comparePrice)
            .html(
              theme.Currency.formatMoney(
                variant.compare_at_price,
                theme.moneyFormat
              )
            )
            .removeClass('hide');
          $(this.selectors.saleTag).removeClass('hide');
        } else {
          $(this.selectors.comparePrice).addClass('hide');
          $(this.selectors.saleTag).addClass('hide');
        }
      } else {
        $(this.selectors.comparePrice).addClass('hide');
      }
    },

    _updateSKU: function(evt) {
      var variant = evt.variant;

      if (variant) {
        $(this.selectors.SKU).html(variant.sku);
      }
    },

    _updateImages: function(evt) {
      var variant = evt.variant;

      if (variant && variant.featured_image) {
        var sizedImgUrl = theme.Images.getSizedImageUrl(
          variant.featured_image.src,
          this.settings.imageSize
        );
        var largeImgUrl = theme.Images.getSizedImageUrl(
          variant.featured_image.src,
          '1024x1024'
        );
        this.switchProductImage(sizedImgUrl, largeImgUrl);
        this.setActiveThumbnail();
      }
    },

    switchProductImage: function(image, largeImage) {
      $(this.selectors.productFeaturedImage).attr('src', image);
      $(this.selectors.productImageWrap).attr('href', largeImage);
    },

    setActiveThumbnail: function($el) {
      var $thumbnails = $(this.selectors.productThumbs);

      // If there is no element passed, find it by the current product image
      if (typeof $el === 'undefined') {
        var src = $(this.selectors.productFeaturedImage).attr('src');
        $el = $('.product-single__thumbnail[href="' + src + '"]');
      }

      // Set active thumbnail class
      $thumbnails
        .find('.product-single__thumbnail-item')
        .removeClass('is-active');
      $el.parent().addClass('is-active');

      // If there is a slick carousel, get the slide index, and position it into view with animation.
      if ($thumbnails.hasClass('slick-initialized')) {
        // eslint-disable-next-line shopify/jquery-dollar-sign-reference
        var currentActiveSlideIndex = $thumbnails.slick('slickCurrentSlide');
        var newActiveSlideIndex = $el.parent().attr('data-slick-index');
        if (currentActiveSlideIndex !== newActiveSlideIndex) {
          $thumbnails.slick('slickGoTo', newActiveSlideIndex, false);
        }
      }
    },

    _productZoomImage: function() {
      if (!$(this.selectors.productFeaturedImage).length) {
        return;
      }

      var self = this;

      $(this.selectors.productImageWrap).on(
        'click' + this.settings.namespace,
        function(evt) {
          evt.preventDefault();
          // Empty src before loadig new image to avoid awkward image swap
          $(self.selectors.productZoomImage)
            .attr('src', '')
            .attr('src', $(this).attr('href'));
        }
      );

      this.ProductModal = new window.Modals(
        this.selectors.modal,
        'product-modal'
      );

      // Close modal if clicked, but not if the image is clicked
      this.ProductModal.$modal.on('click' + this.settings.namespace, function(
        evt
      ) {
        if (evt.target.nodeName !== 'IMG') {
          self.ProductModal.close();
        }
      });
    },

    _productThumbSwitch: function() {
      if (!$(this.selectors.productThumbs).length) {
        return;
      }

      var self = this;

      $(this.selectors.productThumbImages).on(
        'click' + this.settings.namespace,
        function(evt) {
          evt.preventDefault();
          var $el = $(this);
          var sizedImageUrl = $el.attr('href');
          var largeImageUrl = $el.attr('data-zoom');
          self.setActiveThumbnail($el);
          self.switchProductImage(sizedImageUrl, largeImageUrl);
        }
      );
    },

    /*
      Thumbnail slider
     */
    _productThumbnailSlider: function() {
      var $productThumbWrapper = $(this.selectors.productThumbs);
      if (!$productThumbWrapper.length) {
        return;
      }

      var thumbnailCount = $productThumbWrapper.find(
        this.selectors.productThumbAllImages
      ).length;

      if (thumbnailCount > 2) {
        $productThumbWrapper.on(
          'init' + this.settings.namespace,
          this._productSwipeInit.bind(this)
        );

        var nextArrow;
        var prevArrow;
        var sliderArrows = window.sliderArrows || false;

        // sliderArrows is an object defined in product.liquid to set custom
        // SVG arrow icons.
        if (sliderArrows) {
          nextArrow =
            '<button type="button" class="slick-next"><span class="medium-up--hide">' +
            sliderArrows.right +
            '</span><span class="small--hide">' +
            sliderArrows.down +
            '</span></button>';
          prevArrow =
            '<button type="button" class="slick-prev"><span class="medium-up--hide">' +
            sliderArrows.left +
            '</span><span class="small--hide">' +
            sliderArrows.up +
            '</span></button>';
        }

        $productThumbWrapper.slick({
          accessibility: false,
          arrows: true,
          dots: false,
          infinite: false,
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          vertical: true,
          verticalSwiping: true,
          nextArrow: nextArrow,
          prevArrow: prevArrow,
          responsive: [
            {
              breakpoint: this.settings.smallBreakpoint,
              settings: {
                vertical: false,
                verticalSwiping: false
              }
            }
          ]
        });

        // Show highlighted thumbnail by repositioning slider
        $productThumbWrapper.slick(
          'slickGoTo',
          $productThumbWrapper.find('.is-active').attr('data-slick-index'),
          true
        );
      }
    },

    _productSwipeInit: function(evt, obj) {
      // Slider is initialized. Setup custom swipe events
      this.settings.productThumbIndex = obj.currentSlide;
      this.settings.productThumbMax = obj.slideCount - 1; // we need the 0-based index

      $(this.selectors.productFeaturedImage).on(
        'swipeleft swiperight',
        function(event) {
          if (event.type === 'swipeleft') {
            this._goToNextThumbnail();
          }

          if (event.type === 'swiperight') {
            this._goToPrevThumbnail();
          }

          // Trigger click on newly requested thumbnail
          $(
            '.product-single__thumbnail-item[data-slick-index="' +
              this.settings.productThumbIndex +
              '"]'
          )
            .find('.product-single__thumbnail')
            .trigger('click');
        }
      );
    },

    _goToNextThumbnail: function() {
      this.settings.productThumbIndex++;

      if (this.settings.productThumbIndex > this.settings.productThumbMax) {
        this.settings.productThumbIndex = 0;
      }

      $(this.selectors.productThumbs).slick(
        'slickGoTo',
        this.settings.productThumbIndex,
        true
      );
    },

    _goToPrevThumbnail: function() {
      this.settings.productThumbIndex--;

      if (this.settings.productThumbIndex < 0) {
        this.settings.productThumbIndex = this.settings.productThumbMax;
      }

      $(this.selectors.productThumbs).slick(
        'slickGoTo',
        this.settings.productThumbIndex,
        true
      );
    },

    _initQtySelector: function() {
      this.$container.find('.product-form__quantity').each(function(i, el) {
        // eslint-disable-next-line no-new
        new QtySelector($(el));
      });
    },

    onUnload: function() {
      $(this.selectors.productImageWrap).off(this.settings.namespace);
      $(this.selectors.productThumbImages).off(this.settings.namespace);
      $(this.selectors.productThumbs).slick('unslick');
      this.ProductModal.$modal.off(this.settings.namespace);
    }
  });

  return Product;
})();

theme.Slideshow = (function() {
  function Slideshow() {
    this.slideshow = new theme.Hero();
  }

  Slideshow.prototype = _.assignIn({}, Slideshow.prototype, {
    onUnload: function() {
      this.slideshow.destroy();
    },

    onSelect: function() {
      this.slideshow.pause();
    },

    onDeselect: function() {
      this.slideshow.play();
    },

    onBlockSelect: function(evt) {
      var $slide = $(
        '.hero__slide--' + evt.detail.blockId + ':not(.slick-cloned)'
      );
      var slideIndex = $slide.data('slick-index');

      this.slideshow.pause();
      this.slideshow.goToSlide(slideIndex);
    },

    onBlockDeselect: function() {
      this.slideshow.play();
    }
  });

  return Slideshow;
})();

theme.Cart = (function() {
  var selectors = {
    cartNote: '#CartSpecialInstructions',
    cartQtyInput: '.cart__quantity',
    cartNoCookiesClass: 'cart--no-cookies'
  };

  function Cart(container) {
    var $container = (this.$container = $(container));
    var sectionId = $container.attr('data-section-id');

    theme.cartObject = JSON.parse($('#CartJson-' + sectionId).html());

    this.init($container);
  }

  Cart.prototype = _.assignIn({}, Cart.prototype, {
    init: function($container) {
      this._initQtySelector();
      this._initCartNote();

      if (!this._cookiesEnabled()) {
        $container.addClass(selectors.cartNoCookiesClass);
      }
    },

    _initQtySelector: function() {
      $(selectors.cartQtyInput).each(function(i, el) {
        // eslint-disable-next-line no-new
        new QtySelector($(el));
      });
    },

    _initCartNote: function() {
      if (!$(selectors.cartNote).length) {
        return;
      }

      var $el = $(selectors.cartNote);
      var noteText;
      var params;
      var noteOffset = $el[0].offsetHeight - $el[0].clientHeight;

      // Auto grow the cart note if text fills it up
      $el.on('keyup input', function() {
        $(this)
          .css('height', 'auto')
          .css('height', $el[0].scrollHeight + noteOffset);
      });

      // Save the cart note via ajax. A safeguard in case
      // a user decides to leave the page before clicking 'Update Cart'
      $el.on(
        'change',
        $.proxy(function() {
          noteText = $el.val();
          params = {
            type: 'POST',
            url: '/cart/update.js',
            data: 'note=' + this._attributeToString(noteText),
            dataType: 'json'
          };
          $.ajax(params);
        }, this)
      );
    },

    _attributeToString: function(attr) {
      if (typeof attr !== 'string') {
        attr = String(attr);
        if (attr === 'undefined') {
          attr = '';
        }
      }
      return $.trim(attr);
    },

    _cookiesEnabled: function() {
      var cookieEnabled = navigator.cookieEnabled;

      if (!cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
      }
      return cookieEnabled;
    }
  });

  return Cart;
})();

theme.Quotes = (function() {
  function Quotes(container) {
    this.$container = $(container).on('init', this._a11y.bind(this));

    this.$container.slick({
      accessibility: true,
      arrows: false,
      dots: true,
      draggable: true,
      autoplay: false
    });
  }

  Quotes.prototype = _.assignIn({}, Quotes.prototype, {
    _a11y: function(event, obj) {
      var $list = obj.$list;
      var $wrapper = this.$container.parent();

      // Remove default Slick aria-live attr until slider is focused
      $list.removeAttr('aria-live');

      // When an element in the slider is focused set aria-live
      $wrapper.on('focusin', function(evt) {
        if ($wrapper.has(evt.target).length) {
          $list.attr('aria-live', 'polite');
        }
      });

      // Remove aria-live
      $wrapper.on('focusout', function(evt) {
        if ($wrapper.has(evt.target).length) {
          $list.removeAttr('aria-live');
        }
      });
    },

    _goToSlide: function(slideIndex) {
      this.$container.slick('slickGoTo', slideIndex);
    },

    onUnload: function() {
      delete this.$container;
    },

    onBlockSelect: function(evt) {
      // Ignore the cloned version
      var $slide = $(
        '.quote__slide-wrapper--' + evt.detail.blockId + ':not(.slick-cloned)'
      );
      var slideIndex = $slide.data('slick-index');

      // Go to selected slide, pause autoplay
      this._goToSlide(slideIndex);
    }
  });

  return Quotes;
})();

theme.Video = (function() {
  var promiseYoutubeAPI;
  var promiseVimeoAPI;

  var youtube = {
    promiseAPI: function() {
      if (!promiseYoutubeAPI) {
        var tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        promiseYoutubeAPI = $.Deferred(function(defer) {
          window.onYouTubeIframeAPIReady = defer.resolve;

          setTimeout(function() {
            defer.reject('Request for YouTube API timed out after 30 seconds.');
          }, 30000);
        });
      }

      return promiseYoutubeAPI;
    },
    promisePlayer: function(id, options) {
      return this.promiseAPI().then(function() {
        return $.Deferred(function(defer) {
          if (typeof window.YT === 'undefined') {
            defer.reject(
              "We're sorry, something went wrong. The YouTube API has not loaded correctly."
            );
          }

          /* eslint-disable no-undef */
          var player = new YT.Player(id, options); // global YT variable injected by YouTube API

          player.addEventListener('onReady', function() {
            defer.resolve(player);
          });

          setTimeout(function() {
            defer.reject(
              'Request for YouTube player has timed out after 30 seconds.'
            );
          }, 30000);
        });
      });
    }
  };

  var vimeo = {
    promiseAPI: function() {
      if (!promiseVimeoAPI) {
        promiseVimeoAPI = $.Deferred(function(defer) {
          var tag = document.createElement('script');
          tag.src = 'https://player.vimeo.com/api/player.js';
          tag.onload = tag.onreadystatechange = function() {
            if (!this.readyState || this.readyState === 'complete') {
              defer.resolve();
            }
          };

          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          setTimeout(function() {
            defer.reject('Request for Vimeo API timed out after 30 seconds.');
          }, 30000);
        });
      }

      return promiseVimeoAPI;
    },

    promisePlayer: function(id, options) {
      return this.promiseAPI().then(function() {
        return $.Deferred(function(defer) {
          if (typeof window.Vimeo === 'undefined') {
            defer.reject(
              "We're sorry, something went wrong. The Vimeo API has not loaded correctly."
            );
          }

          var player = new window.Vimeo.Player(id, options);

          setTimeout(function() {
            defer.reject(
              'Request for Vimeo player has timed out after 30 seconds.'
            );
          }, 30000);

          player.ready().then(function() {
            defer.resolve(player);
          });
        });
      });
    }
  };

  var selectors = {
    loadPlayerButton: '.video-section__load-player-button',
    closePlayerButton: '.video-section__player-close',
    playerContainer: '.video-section__player',
    cover: '.video-section__cover',
    errorMessage: '.video-section__error',
    bodyOverlay: '.video-section__body-overlay',
    body: 'body'
  };
  var classes = {
    playerLoading: 'video-section--loading',
    playerLoaded: 'video-section--loaded',
    playerError: 'video-section--error',
    videoPlaying: 'video-playing'
  };

  function Video(container) {
    this.$container = $(container);
    var sectionId = this.$container.attr('data-section-id');
    this.namespace = '.' + sectionId;
    this.onLoad();
  }

  Video.prototype = _.assignIn({}, Video.prototype, {
    onLoad: function() {
      this.$container
        .on('click', selectors.loadPlayerButton, this._loadPlayer.bind(this))
        .on('click', selectors.closePlayerButton, this._closePlayer.bind(this))
        .on('click', selectors.bodyOverlay, this._closePlayer.bind(this));
    },

    _loadPlayer: function() {
      var $container = this.$container;
      var $playerContainer = $(selectors.playerContainer, $container);
      var playerType = this.$container.attr('data-video-type');

      var promiseVideoPlayer;

      if (playerType === 'youtube') {
        promiseVideoPlayer = this._loadYoutubePlayer($playerContainer[0]);
      } else if (playerType === 'vimeo') {
        promiseVideoPlayer = this._loadVimeoPlayer($playerContainer[0]);
      }

      return promiseVideoPlayer
        .then(this._onPlayerLoadReady.bind(this))
        .fail(this._onPlayerLoadError.bind(this));
    },

    _loadYoutubePlayer: function(container) {
      return youtube
        .promisePlayer(container, {
          videoId: this.$container.attr('data-video-id'),
          ratio: 16 / 9,
          playerVars: {
            modestbranding: 1,
            autoplay: 1,
            showinfo: 0,
            rel: 0
          }
        })
        .then(
          function(player) {
            this.player = player;
          }.bind(this)
        );
    },

    _loadVimeoPlayer: function(container) {
      return vimeo
        .promisePlayer(container, {
          id: this.$container.attr('data-video-id')
        })
        .then(
          function(player) {
            this.player = player;
            this.player.play();
          }.bind(this)
        );
    },

    _onPlayerLoadReady: function() {
      $(selectors.closePlayerButton, this.$container)
        .show()
        .focus();
      $(selectors.cover, this.$container).addClass(classes.playerLoaded);
      this.$container.addClass(classes.playerLoaded);

      this._setScrollPositionValues();

      $(selectors.body).addClass(classes.videoPlaying);

      $(document).on('keyup' + this.namespace, this._closeOnEscape.bind(this));
      $(window).on(
        'resize' + this.namespace,
        this._setScrollPositionValues.bind(this)
      );
      slate.a11y.trapFocus({
        $container: this.$container,
        namespace: this.namespace
      });
    },

    _onPlayerLoadError: function(err) {
      this.$container.addClass(classes.playerError);
      $(selectors.errorMessage, this.$container).text(err);
    },

    _closeOnEscape: function(evt) {
      if (evt.keyCode !== 27) {
        return;
      }

      this._closePlayer();
      $(selectors.loadPlayerButton, this.$container).focus();
    },

    _onScroll: function() {
      var scrollTop = $(window).scrollTop();

      if (
        scrollTop > this.videoTop + 0.25 * this.videoHeight ||
        scrollTop + this.windowHeight <
          this.videoBottom - 0.25 * this.videoHeight
      ) {
        // Debounce DOM edits to the next frame with requestAnimationFrame
        requestAnimationFrame(this._closePlayer.bind(this));
      }
    },

    _setScrollPositionValues: function() {
      this.videoHeight = this.$container.outerHeight(true);
      this.videoTop = this.$container.offset().top;
      this.videoBottom = this.videoTop + this.videoHeight;
      this.windowHeight = $(window).innerHeight();
    },

    _closePlayer: function() {
      $(selectors.body).removeClass(classes.videoPlaying);
      $(selectors.cover, this.$container).removeClass(classes.playerLoaded);
      this.$container.removeClass(classes.playerLoaded);
      $(selectors.closePlayerButton, this.$container).hide();

      slate.a11y.removeTrapFocus({
        $container: this.$container,
        namespace: this.namespace
      });

      if (typeof this.player.destroy === 'function') {
        this.player.destroy();
      } else if (typeof this.player.unload === 'function') {
        this.player.unload();
      }

      $(document).off(this.namespace);
      $(window).off(this.namespace);
    }
  });

  return Video;
})();

theme.CollectionsList = (function() {
  function CollectionsList(container) {
    var $container = (this.$container = $(container));
    var stretchImage = $container.is('[data-stretch-image]');

    // Early return if 'secondary image layout' is enabled
    if (stretchImage) return;

    var namespace = (this.namespace = '.' + $container.attr('data-section-id'));
    var self = this;

    self._collectionListFix();
    $(window).on(
      'resize' + namespace,
      $.debounce(250, function() {
        self._collectionListFix();
      })
    );
  }

  CollectionsList.prototype = _.assignIn({}, CollectionsList.prototype, {
    onUnload: function() {
      $(window).off(this.namespace);
    },

    _collectionListFix: function() {
      var numberRows = this.$container.find('.grid').data('number-rows');
      var $featureCards = this.$container.find('.featured-card');

      // We can exit if 'Use secondary image layout' is enabled
      if ($featureCards.is('[data-stretch-image]')) return;

      // Go through each of the rows
      for (var i = 0; i < numberRows; i++) {
        var maxWrapperHeight = 0;
        var maxHeaderHeight = 0;
        var $currentRow = $featureCards.filter(
          "[data-row-number='" + (i + 1) + "']"
        );
        var $cardHeaders = $currentRow.find('.featured-card__header');

        // Find the max heights for each row
        $currentRow.each(function() {
          var $cardTitle = $(this).find('.featured-card__title');
          var $cardAction = $(this).find('.featured-card__action');
          var $cardImageWrapper = $(this).find('.featured-card__image-wrapper');
          var headerHeight =
            $cardTitle.outerHeight() + $cardAction.outerHeight() + 65;
          var wrapperHeight = $cardImageWrapper.outerHeight();
          if (headerHeight > maxHeaderHeight) {
            maxHeaderHeight = headerHeight;
          }
          if (wrapperHeight > maxWrapperHeight) {
            maxWrapperHeight = wrapperHeight;
          }
        });

        // Set the heights of the headers and cards for this row with padding
        $cardHeaders.outerHeight(maxHeaderHeight);
        $currentRow.height(maxWrapperHeight + maxHeaderHeight + 40);
      }
    }
  });

  return CollectionsList;
})();


theme.init = function() {
  theme.customerTemplates.init();
  slate.rte.wrapTable();
  slate.rte.iframeReset();

  // Common a11y fixes
  slate.a11y.pageLinkFocus($(window.location.hash));

  $('.in-page-link').on('click', function(evt) {
    slate.a11y.pageLinkFocus($(evt.currentTarget.hash));
  });

  $('a[href="#"]').on('click', function(evt) {
    evt.preventDefault();
  });

  // Sections
  var sections = new theme.Sections();
  sections.register('header', theme.HeaderSection);
  sections.register('product', theme.Product);
  sections.register('collection-filters', theme.Filters);
  sections.register('map', theme.Maps);
  sections.register('slideshow', theme.Slideshow);
  sections.register('cart', theme.Cart);
  sections.register('quotes', theme.Quotes);
  sections.register('video', theme.Video);
  sections.register('collections-list', theme.CollectionsList);

  // Standalone modules
  $(window).on('load', theme.articleImages);
  theme.passwordModalInit();
};

theme.articleImages = function() {
  var $indentedRteImages = $('.rte--indented-images');
  if (!$indentedRteImages.length) {
    return;
  }

  $indentedRteImages.find('img').each(function(i, el) {
    var $el = $(el);
    var attr = $el.attr('style');

    // Check if undefined or float: none
    if (!attr || attr === 'float: none;') {
      // Add class to parent paragraph tag if image is wider than container
      if ($el.width() >= $indentedRteImages.width()) {
        $el.parent('p').addClass('rte__image-indent');
      }
    }
  });
};

theme.passwordModalInit = function() {
  var $loginModal = $('#LoginModal');
  if (!$loginModal.length) {
    return;
  }

  // Initialize modal
  theme.PasswordModal = new window.Modals('LoginModal', 'login-modal', {
    focusOnOpen: '#Password'
  });

  // Open modal if errors exist
  if ($loginModal.find('.errors').length) {
    theme.PasswordModal.open();
  }
};

$(theme.init);






// Mailchimp JS
//>>built
(function(b,l){var g,n=function(){},d=function(a){for(var c in a)return 0;return 1},f={}.toString,m=function(a){return"[object Function]"==f.call(a)},h=function(a){return"[object String]"==f.call(a)},p=function(a){return"[object Array]"==f.call(a)},a=function(a,c){if(a)for(var b=0;b<a.length;)c(a[b++])},e=function(a,c){for(var b in c)a[b]=c[b];return a},k=function(a,c){return e(Error(a),{src:"dojoLoader",info:c})},c=1,t=function(){return"_"+c++},q=function(a,c,b){return Ma(a,c,b,0,q)},r=this,u=r.document,
v=u&&u.createElement("DiV"),x=q.has=function(a){return m(y[a])?y[a]=y[a](r,u,v):y[a]},y=x.cache=l.hasCache;x.add=function(a,c,b,e){(void 0===y[a]||e)&&(y[a]=c);return b&&x(a)};x.add("host-webworker","undefined"!==typeof WorkerGlobalScope&&self instanceof WorkerGlobalScope);x("host-webworker")&&(e(l.hasCache,{"host-browser":0,dom:0,"dojo-dom-ready-api":0,"dojo-sniff":0,"dojo-inject-api":1,"host-webworker":1}),l.loaderPatch={injectUrl:function(a,c){try{importScripts(a),c()}catch(b){}}});for(var z in b.has)x.add(z,
b.has[z],0,1);var w=0,B=[],E=0,X=n,O=n,Q;q.isXdUrl=n;q.initSyncLoader=function(a,c,b){E||(E=a,X=c,O=b);return{sync:"sync",requested:1,arrived:2,nonmodule:3,executing:4,executed:5,syncExecStack:B,modules:H,execQ:T,getModule:Y,injectModule:ra,setArrived:ga,signal:A,finishExec:ia,execModule:ja,dojoRequirePlugin:E,getLegacyMode:function(){return w},guardCheckComplete:ka}};var P=location.protocol,S=location.host;q.isXdUrl=function(a){return/^\./.test(a)?!1:/^\/\//.test(a)?!0:(a=a.match(/^([^\/\:]+\:)\/+([^\/]+)/))&&
(a[1]!=P||S&&a[2]!=S)};x.add("dojo-force-activex-xhr",!u.addEventListener&&"file:"==window.location.protocol);x.add("native-xhr","undefined"!=typeof XMLHttpRequest);if(x("native-xhr")&&!x("dojo-force-activex-xhr"))Q=function(){return new XMLHttpRequest};else{var J=["Msxml2.XMLHTTP","Microsoft.XMLHTTP","Msxml2.XMLHTTP.4.0"],K;for(g=0;3>g;)try{if(K=J[g++],new ActiveXObject(K))break}catch(jb){}Q=function(){return new ActiveXObject(K)}}q.getXhr=Q;x.add("dojo-gettext-api",1);q.getText=function(a,c,b){var e=
Q();e.open("GET",sa(a),!1);e.send(null);if(200==e.status||!location.host&&!e.status)b&&b(e.responseText,c);else throw k("xhrFailed",e.status);return e.responseText};var R=new Function("return eval(arguments[0]);");q.eval=function(a,c){return R(a+"\r\n//# sourceURL\x3d"+c)};var F={},A=q.signal=function(c,b){var e=F[c];a(e&&e.slice(0),function(a){a.apply(null,p(b)?b:[b])})},D=q.on=function(a,c){var b=F[a]||(F[a]=[]);b.push(c);return{remove:function(){for(var a=0;a<b.length;a++)if(b[a]===c){b.splice(a,
1);break}}}},I=[],ea={},L=[],C={},M=q.map={},G=[],H={},V="",N={},aa={},W={},ba=0,ca=function(a){var c,b,e,k;for(c in aa)b=aa[c],(e=c.match(/^url\:(.+)/))?N["url:"+Na(e[1],a)]=b:"*now"==c?k=b:"*noref"!=c&&(e=la(c,a,!0),N[e.mid]=N["url:"+e.url]=b);k&&k(Ca(a));aa={}},Oa=function(a){return a.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g,function(a){return"\\"+a})},Da=function(a,c){c.splice(0,c.length);for(var b in a)c.push([b,a[b],new RegExp("^"+Oa(b)+"(/|$)"),b.length]);c.sort(function(a,c){return c[3]-a[3]});
return c},ab=function(c,b){a(c,function(a){b.push([h(a[0])?new RegExp("^"+Oa(a[0])+"$"):a[0],a[1]])})},Pa=function(a){var c=a.name;c||(c=a,a={name:c});a=e({main:"main"},a);a.location=a.location?a.location:c;a.packageMap&&(M[c]=a.packageMap);a.main.indexOf("./")||(a.main=a.main.substring(2));C[c]=a},Qa=[],ma=function(c,b,k){for(var d in c){"waitSeconds"==d&&(q.waitms=1E3*(c[d]||0));"cacheBust"==d&&(V=c[d]?h(c[d])?c[d]:(new Date).getTime()+"":"");if("baseUrl"==d||"combo"==d)q[d]=c[d];if("async"==d){var f=
c[d];q.legacyMode=w=h(f)&&/sync|legacyAsync/.test(f)?f:f?!1:"sync";q.async=!w}c[d]!==y&&(q.rawConfig[d]=c[d],"has"!=d&&x.add("config-"+d,c[d],0,b))}q.baseUrl||(q.baseUrl="./");/\/$/.test(q.baseUrl)||(q.baseUrl+="/");for(d in c.has)x.add(d,c.has[d],0,b);a(c.packages,Pa);for(var m in c.packagePaths)a(c.packagePaths[m],function(a){var c=m+"/"+a;h(a)&&(a={name:a});a.location=c;Pa(a)});Da(e(M,c.map),G);a(G,function(a){a[1]=Da(a[1],[]);"*"==a[0]&&(G.star=a)});Da(e(ea,c.paths),L);ab(c.aliases,I);if(b)Qa.push({config:c.config});
else for(d in c.config)b=Y(d,k),b.config=e(b.config||{},c.config[d]);c.cache&&(ca(),aa=c.cache,c.cache["*noref"]&&ca());A("config",[c,q.rawConfig])};x("dojo-cdn");var ta=u.getElementsByTagName("script");g=0;for(var Z,fa,ua,na;g<ta.length;)if(Z=ta[g++],(ua=Z.getAttribute("src"))&&(na=ua.match(/(((.*)\/)|^)dojo\.js(\W|$)/i))&&(fa=na[3]||"",l.baseUrl=l.baseUrl||fa,ba=Z),ua=Z.getAttribute("data-dojo-config")||Z.getAttribute("djConfig"))W=q.eval("({ "+ua+" })","data-dojo-config"),ba=Z;q.rawConfig={};ma(l,
1);x("dojo-cdn")&&((C.dojo.location=fa)&&(fa+="/"),C.dijit.location=fa+"../dijit/",C.dojox.location=fa+"../dojox/");ma(b,1);ma(W,1);var oa=function(c){ka(function(){a(c.deps,ra)})},Ma=function(a,c,b,d,f){var m;if(h(a)){if((m=Y(a,d,!0))&&m.executed)return m.result;throw k("undefinedModule",a);}p(a)||(ma(a,0,d),a=c,c=b);if(p(a))if(a.length){b="require*"+t();for(var g,r=[],E=0;E<a.length;)g=a[E++],r.push(Y(g,d));m=e(va("",b,0,""),{injected:2,deps:r,def:c||n,require:d?d.require:q,gc:1});H[m.mid]=m;oa(m);
var x=pa&&"sync"!=w;ka(function(){ja(m,x)});m.executed||T.push(m);ha()}else c&&c();return f},Ca=function(a){if(!a)return q;var c=a.require;c||(c=function(b,e,k){return Ma(b,e,k,a,c)},a.require=e(c,q),c.module=a,c.toUrl=function(c){return Na(c,a)},c.toAbsMid=function(c){return Ea(c,a)},c.syncLoadNls=function(c){c=la(c,a);var b=H[c.mid];if(!b||!b.executed)if(da=N[c.mid]||N["url:"+c.url])wa(da),b=H[c.mid];return b&&b.executed&&b.result});return c},T=[],xa=[],U={},bb=function(a){a.injected=1;U[a.mid]=
1;a.url&&(U[a.url]=a.pack||1);Ra()},ga=function(a){a.injected=2;delete U[a.mid];a.url&&delete U[a.url];d(U)&&(ya(),"xd"==w&&(w="sync"))},cb=q.idle=function(){return!xa.length&&d(U)&&!T.length&&!pa},Fa=function(a,c){if(c)for(var b=0;b<c.length;b++)if(c[b][2].test(a))return c[b];return 0},Sa=function(a){var c=[],b,e;for(a=a.replace(/\\/g,"/").split("/");a.length;)b=a.shift(),".."==b&&c.length&&".."!=e?(c.pop(),e=c[c.length-1]):"."!=b&&c.push(e=b);return c.join("/")},va=function(a,c,b,e){var k=q.isXdUrl(e);
return{pid:a,mid:c,pack:b,url:e,executed:0,def:0,isXd:k,isAmd:!!(k||C[a]&&C[a].isAmd)}},Ta=function(c,b,e,d,f,h,g,p,t){var r,E,q,l;l=/^\./.test(c);if(/(^\/)|(\:)|(\.js$)/.test(c)||l&&!b)return va(0,c,0,c);c=Sa(l?b.mid+"/../"+c:c);if(/^\./.test(c))throw k("irrationalPath",c);b&&(q=Fa(b.mid,h));(q=(q=q||h.star)&&Fa(c,q[1]))&&(c=q[1]+c.substring(q[3]));b=(na=c.match(/^([^\/]+)(\/(.+))?$/))?na[1]:"";(r=e[b])?c=b+"/"+(E=na[3]||r.main):b="";var n=0;a(p,function(a){var b=c.match(a[0]);b&&0<b.length&&(n=
m(a[1])?c.replace(a[0],a[1]):a[1])});if(n)return Ta(n,0,e,d,f,h,g,p,t);if(e=d[c])return t?va(e.pid,e.mid,e.pack,e.url):d[c];d=(q=Fa(c,g))?q[1]+c.substring(q[3]):b?r.location+"/"+E:x("config-tlmSiblingOfDojo")?"../"+c:c;/(^\/)|(\:)/.test(d)||(d=f+d);return va(b,c,r,Sa(d+".js"))},la=function(a,c,b){return Ta(a,c,C,H,q.baseUrl,b?[]:G,b?[]:L,b?[]:I)},Ua=function(a,c,b){return a.normalize?a.normalize(c,function(a){return Ea(a,b)}):Ea(c,b)},Va=0,Y=function(a,c,b){var e,k;(e=a.match(/^(.+?)\!(.*)$/))?(k=
Y(e[1],c,b),"sync"!=w||k.executed||(ra(k),2!==k.injected||k.executed||ka(function(){ja(k)}),k.executed?za(k):T.unshift(k)),5!==k.executed||k.load||za(k),k.load?(e=Ua(k,e[2],c),a=k.mid+"!"+(k.dynamic?++Va+"!":"")+e):(e=e[2],a=k.mid+"!"+ ++Va+"!waitingForPlugin"),a={plugin:k,mid:a,req:Ca(c),prid:e}):a=la(a,c);return H[a.mid]||!b&&(H[a.mid]=a)},Ea=q.toAbsMid=function(a,c){return la(a,c).mid},Na=q.toUrl=function(a,c){var b=la(a+"/x",c),e=b.url;return sa(0===b.pid?a:e.substring(0,e.length-5))},Wa={injected:2,
executed:5,def:3,result:3},Ga=function(a){return H[a]=e({mid:a},Wa)},db=Ga("require"),eb=Ga("exports"),fb=Ga("module"),Aa={},Ha=0,za=function(a){var c=a.result;a.dynamic=c.dynamic;a.normalize=c.normalize;a.load=c.load;return a},gb=function(c){var b={};a(c.loadQ,function(a){var k=Ua(c,a.prid,a.req.module),d=c.dynamic?a.mid.replace(/waitingForPlugin$/,k):c.mid+"!"+k,k=e(e({},a),{mid:d,prid:k,injected:0});H[d]||Xa(H[d]=k);b[a.mid]=H[d];ga(a);delete H[a.mid]});c.loadQ=0;var k=function(a){for(var c=a.deps||
[],e=0;e<c.length;e++)(a=b[c[e].mid])&&(c[e]=a)},d;for(d in H)k(H[d]);a(T,k)},ia=function(c){q.trace("loader-finish-exec",[c.mid]);c.executed=5;c.defOrder=Ha++;a(c.provides,function(a){a()});c.loadQ&&(za(c),gb(c));for(g=0;g<T.length;)T[g]===c?T.splice(g,1):g++;/^require\*/.test(c.mid)&&delete H[c.mid]},hb=[],ja=function(a,c){if(4===a.executed)return q.trace("loader-circular-dependency",[hb.concat(a.mid).join("-\x3e")]),!a.def||c?Aa:a.cjs&&a.cjs.exports;if(!a.executed){if(!a.def)return Aa;var b=a.mid,
e=a.deps||[],d,f=[],h=0;for(a.executed=4;d=e[h++];){d=d===db?Ca(a):d===eb?a.cjs.exports:d===fb?a.cjs:ja(d,c);if(d===Aa)return a.executed=0,q.trace("loader-exec-module",["abort",b]),Aa;f.push(d)}q.trace("loader-run-factory",[a.mid]);var b=a.def,g;B.unshift(a);if(x("config-dojo-loader-catches"))try{g=m(b)?b.apply(null,f):b}catch(p){A("error",a.result=k("factoryThrew",[a,p]))}else g=m(b)?b.apply(null,f):b;a.result=void 0===g&&a.cjs?a.cjs.exports:g;B.shift(a);ia(a)}return a.result},pa=0,ka=function(a){try{pa++,
a()}catch(c){}finally{pa--}cb()&&A("idle",[])},ha=function(){pa||ka(function(){X();for(var a,c,b=0;b<T.length;)a=Ha,c=T[b],ja(c),a!=Ha?(X(),b=0):b++})};void 0===x("dojo-loader-eval-hint-url")&&x.add("dojo-loader-eval-hint-url",1);var sa="function"==typeof b.fixupUrl?b.fixupUrl:function(a){a+="";return a+(V?(/\?/.test(a)?"\x26":"?")+V:"")},Xa=function(a){var c=a.plugin;5!==c.executed||c.load||za(c);var b=function(c){a.result=c;ga(a);ia(a);ha()};c.load?c.load(a.prid,a.req,b):c.loadQ?c.loadQ.push(a):
(c.loadQ=[a],T.unshift(c),ra(c))},da=0,qa=0,Ia=0,wa=function(a,c){x("config-stripStrict")&&(a=a.replace(/"use strict"/g,""));Ia=1;if(x("config-dojo-loader-catches"))try{a===da?da.call(null):q.eval(a,x("dojo-loader-eval-hint-url")?c.url:c.mid)}catch(b){A("error",k("evalModuleThrew",c))}else a===da?da.call(null):q.eval(a,x("dojo-loader-eval-hint-url")?c.url:c.mid);Ia=0},ra=function(c){var b=c.mid,d=c.url;if(!(c.executed||c.injected||U[b]||c.url&&(c.pack&&U[c.url]===c.pack||1==U[c.url])))if(bb(c),c.plugin)Xa(c);
else{var f=function(){Ya(c);if(2!==c.injected){if(x("dojo-enforceDefine")){A("error",k("noDefine",c));return}ga(c);e(c,Wa);q.trace("loader-define-nonmodule",[c.url])}w?!B.length&&ha():ha()};if(da=N[b]||N["url:"+c.url])q.trace("loader-inject",["cache",c.mid,d]),wa(da,c),f();else{if(w)if(c.isXd)"sync"==w&&(w="xd");else if(!c.isAmd||"sync"==w){var h=function(e){if("sync"==w){B.unshift(c);wa(e,c);B.shift();Ya(c);c.cjs||(ga(c),ia(c));if(c.finish){e=b+"*finish";var k=c.finish;delete c.finish;Ja(e,["dojo",
("dojo/require!"+k.join(",")).replace(/\./g,"/")],function(c){a(k,function(a){c.require(a)})});T.unshift(Y(e))}f()}else(e=O(c,e))?(wa(e,c),f()):(qa=c,q.injectUrl(sa(d),f,c),qa=0)};q.trace("loader-inject",["xhr",c.mid,d,"sync"!=w]);if(x("config-dojo-loader-catches"))try{q.getText(d,"sync"!=w,h)}catch(m){A("error",k("xhrInjectFailed",[c,m]))}else q.getText(d,"sync"!=w,h);return}q.trace("loader-inject",["script",c.mid,d]);qa=c;q.injectUrl(sa(d),f,c);qa=0}}},Ka=function(a,c,b){q.trace("loader-define-module",
[a.mid,c]);var d=a.mid;if(2===a.injected)return A("error",k("multipleDefine",a)),a;e(a,{deps:c,def:b,cjs:{id:a.mid,uri:a.url,exports:a.result={},setExports:function(c){a.cjs.exports=c},config:function(){return a.config}}});for(var f=0;c[f];f++)c[f]=Y(c[f],a);w&&!U[d]&&(oa(a),T.push(a),ha());ga(a);m(b)||c.length||(a.result=b,ia(a));return a},Ya=function(c,b){for(var e=[],k,d;xa.length;)d=xa.shift(),b&&(d[0]=b.shift()),k=d[0]&&Y(d[0])||c,e.push([k,d[1],d[2]]);ca(c);a(e,function(a){oa(Ka.apply(null,
a))})},Ba=0,ya=n,Ra=n,ya=function(){Ba&&clearTimeout(Ba);Ba=0},Ra=function(){ya();q.waitms&&(Ba=r.setTimeout(function(){ya();A("error",k("timeout",U))},q.waitms))};x.add("ie-event-behavior",u.attachEvent&&"undefined"===typeof Windows&&("undefined"===typeof opera||"[object Opera]"!=opera.toString()));var La=function(a,c,b,e){if(x("ie-event-behavior"))return a.attachEvent(b,e),function(){a.detachEvent(b,e)};a.addEventListener(c,e,!1);return function(){a.removeEventListener(c,e,!1)}},ib=La(window,"load",
"onload",function(){q.pageLoaded=1;"complete"!=u.readyState&&(u.readyState="complete");ib()}),ta=u.getElementsByTagName("script");for(g=0;!ba;)/^dojo/.test((Z=ta[g++])&&Z.type)||(ba=Z);q.injectUrl=function(a,c,b){b=b.node=u.createElement("script");var e=La(b,"load","onreadystatechange",function(a){a=a||window.event;var b=a.target||a.srcElement;if("load"===a.type||/complete|loaded/.test(b.readyState))e(),d(),c&&c()}),d=La(b,"error","onerror",function(c){e();d();A("error",k("scriptError",[a,c]))});
b.type="text/javascript";b.charset="utf-8";b.src=a;ba.parentNode.insertBefore(b,ba);return b};q.log=function(){try{for(var a=0;a<arguments.length;a++);}catch(c){}};q.trace=n;var Ja=function(a,c,b){var e=arguments.length,d=["require","exports","module"],f=[0,a,c];1==e?f=[0,m(a)?d:[],a]:2==e&&h(a)?f=[a,m(c)?d:[],c]:3==e&&(f=[a,c,b]);q.trace("loader-define",f.slice(0,2));if((e=f[0]&&Y(f[0]))&&!U[e.mid])oa(Ka(e,f[1],f[2]));else if(!x("ie-event-behavior")||Ia)xa.push(f);else{e=e||qa;if(!e)for(a in U)if((d=
H[a])&&d.node&&"interactive"===d.node.readyState){e=d;break}e?(ca(e),oa(Ka(e,f[1],f[2]))):A("error",k("ieDefineFailed",f[0]));ha()}};Ja.amd={vendor:"dojotoolkit.org"};e(e(q,l.loaderPatch),b.loaderPatch);D("error",function(a){try{if(a instanceof Error)for(var c in a);}catch(b){}});e(q,{uid:t,cache:N,packs:C});if(r.define)A("error",k("defineAlreadyDefined",0));else{r.define=Ja;r.require=q;a(Qa,function(a){ma(a)});var Za=W.deps||b.deps||l.deps,$a=W.callback||b.callback||l.callback;q.boot=Za||$a?[Za||
[],$a]:0}})(this.dojoConfig||this.djConfig||this.require||{},{async:0,hasCache:{"config-selectorEngine":"acme","config-tlmSiblingOfDojo":1,"dojo-built":1,"dojo-loader":1,dom:1,"host-browser":1},packages:[{location:"../dojox",name:"dojox"},{location:"../dijit",name:"dijit"},{location:".",name:"dojo"},{location:"../mojo",name:"mojo"}]});
require({cache:{"dojo/_base/fx":function(){define("./kernel ./config ./lang ../Evented ./Color ../aspect ../sniff ../dom ../dom-style".split(" "),function(b,l,g,n,d,f,m,h,p){var a=g.mixin,e={},k=e._Line=function(a,c){this.start=a;this.end=c};k.prototype.getValue=function(a){return(this.end-this.start)*a+this.start};var c=e.Animation=function(c){a(this,c);g.isArray(this.curve)&&(this.curve=new k(this.curve[0],this.curve[1]))};c.prototype=new n;g.extend(c,{duration:350,repeat:0,rate:20,_percent:0,_startRepeatCount:0,
_getStep:function(){var a=this._percent,c=this.easing;return c?c(a):a},_fire:function(a,c){var b=c||[];if(this[a])if(l.debugAtAllCosts)this[a].apply(this,b);else try{this[a].apply(this,b)}catch(e){}return this},play:function(a,c){this._delayTimer&&this._clearTimer();if(c)this._stopTimer(),this._active=this._paused=!1,this._percent=0;else if(this._active&&!this._paused)return this;this._fire("beforeBegin",[this.node]);var b=a||this.delay,e=g.hitch(this,"_play",c);if(0<b)return this._delayTimer=setTimeout(e,
b),this;e();return this},_play:function(a){this._delayTimer&&this._clearTimer();this._startTime=(new Date).valueOf();this._paused&&(this._startTime-=this.duration*this._percent);this._active=!0;this._paused=!1;a=this.curve.getValue(this._getStep());this._percent||(this._startRepeatCount||(this._startRepeatCount=this.repeat),this._fire("onBegin",[a]));this._fire("onPlay",[a]);this._cycle();return this},pause:function(){this._delayTimer&&this._clearTimer();this._stopTimer();if(!this._active)return this;
this._paused=!0;this._fire("onPause",[this.curve.getValue(this._getStep())]);return this},gotoPercent:function(a,c){this._stopTimer();this._active=this._paused=!0;this._percent=a;c&&this.play();return this},stop:function(a){this._delayTimer&&this._clearTimer();if(!this._timer)return this;this._stopTimer();a&&(this._percent=1);this._fire("onStop",[this.curve.getValue(this._getStep())]);this._active=this._paused=!1;return this},destroy:function(){this.stop()},status:function(){return this._active?this._paused?
"paused":"playing":"stopped"},_cycle:function(){if(this._active){var a=(new Date).valueOf(),a=0===this.duration?1:(a-this._startTime)/this.duration;1<=a&&(a=1);this._percent=a;this.easing&&(a=this.easing(a));this._fire("onAnimate",[this.curve.getValue(a)]);1>this._percent?this._startTimer():(this._active=!1,0<this.repeat?(this.repeat--,this.play(null,!0)):-1==this.repeat?this.play(null,!0):this._startRepeatCount&&(this.repeat=this._startRepeatCount,this._startRepeatCount=0),this._percent=0,this._fire("onEnd",
[this.node]),!this.repeat&&this._stopTimer())}return this},_clearTimer:function(){clearTimeout(this._delayTimer);delete this._delayTimer}});var t=0,q=null,r={run:function(){}};g.extend(c,{_startTimer:function(){this._timer||(this._timer=f.after(r,"run",g.hitch(this,"_cycle"),!0),t++);q||(q=setInterval(g.hitch(r,"run"),this.rate))},_stopTimer:function(){this._timer&&(this._timer.remove(),this._timer=null,t--);0>=t&&(clearInterval(q),q=null,t=0)}});var u=m("ie")?function(a){var c=a.style;c.width.length||
"auto"!=p.get(a,"width")||(c.width="auto")}:function(){};e._fade=function(c){c.node=h.byId(c.node);var b=a({properties:{}},c);c=b.properties.opacity={};c.start="start"in b?b.start:function(){return+p.get(b.node,"opacity")||0};c.end=b.end;c=e.animateProperty(b);f.after(c,"beforeBegin",g.partial(u,b.node),!0);return c};e.fadeIn=function(c){return e._fade(a({end:1},c))};e.fadeOut=function(c){return e._fade(a({end:0},c))};e._defaultEasing=function(a){return.5+Math.sin((a+1.5)*Math.PI)/2};var v=function(a){this._properties=
a;for(var c in a){var b=a[c];b.start instanceof d&&(b.tempColor=new d)}};v.prototype.getValue=function(a){var c={},b;for(b in this._properties){var e=this._properties[b],k=e.start;k instanceof d?c[b]=d.blendColors(k,e.end,a,e.tempColor).toCss():g.isArray(k)||(c[b]=(e.end-k)*a+k+("opacity"!=b?e.units||"px":0))}return c};e.animateProperty=function(e){var k=e.node=h.byId(e.node);e.easing||(e.easing=b._defaultEasing);e=new c(e);f.after(e,"beforeBegin",g.hitch(e,function(){var c={},b;for(b in this.properties){var e=
function(a,c){var b={height:a.offsetHeight,width:a.offsetWidth}[c];if(void 0!==b)return b;b=p.get(a,c);return"opacity"==c?+b:h?b:parseFloat(b)};if("width"==b||"height"==b)this.node.display="block";var f=this.properties[b];g.isFunction(f)&&(f=f(k));f=c[b]=a({},g.isObject(f)?f:{end:f});g.isFunction(f.start)&&(f.start=f.start(k));g.isFunction(f.end)&&(f.end=f.end(k));var h=0<=b.toLowerCase().indexOf("color");"end"in f?"start"in f||(f.start=e(k,b)):f.end=e(k,b);h?(f.start=new d(f.start),f.end=new d(f.end)):
f.start="opacity"==b?+f.start:parseFloat(f.start)}this.curve=new v(c)}),!0);f.after(e,"onAnimate",g.hitch(p,"set",e.node),!0);return e};e.anim=function(a,b,k,d,f,h){return e.animateProperty({node:a,duration:k||c.prototype.duration,properties:b,easing:d,onEnd:f}).play(h||0)};a(b,e);b._Animation=c;return e})},"dojo/dom-form":function(){define(["./_base/lang","./dom","./io-query","./json"],function(b,l,g,n){var d={fieldToObject:function(b){var d=null;if(b=l.byId(b)){var h=b.name,g=(b.type||"").toLowerCase();
if(h&&g&&!b.disabled)if("radio"==g||"checkbox"==g)b.checked&&(d=b.value);else if(b.multiple)for(d=[],b=[b.firstChild];b.length;)for(h=b.pop();h;h=h.nextSibling)if(1==h.nodeType&&"option"==h.tagName.toLowerCase())h.selected&&d.push(h.value);else{h.nextSibling&&b.push(h.nextSibling);h.firstChild&&b.push(h.firstChild);break}else d=b.value}return d},toObject:function(f){var g={};f=l.byId(f).elements;for(var h=0,p=f.length;h<p;++h){var a=f[h],e=a.name,k=(a.type||"").toLowerCase();if(e&&k&&0>"file|submit|image|reset|button".indexOf(k)&&
!a.disabled){var c=g,t=e,a=d.fieldToObject(a);if(null!==a){var q=c[t];"string"==typeof q?c[t]=[q,a]:b.isArray(q)?q.push(a):c[t]=a}"image"==k&&(g[e+".x"]=g[e+".y"]=g[e].x=g[e].y=0)}}return g},toQuery:function(b){return g.objectToQuery(d.toObject(b))},toJson:function(b,g){return n.stringify(d.toObject(b),null,g?4:0)}};return d})},"dojo/promise/tracer":function(){define(["../_base/lang","./Promise","../Evented"],function(b,l,g){function n(b){setTimeout(function(){f.apply(d,b)},0)}var d=new g,f=d.emit;
d.emit=null;l.prototype.trace=function(){var d=b._toArray(arguments);this.then(function(b){n(["resolved",b].concat(d))},function(b){n(["rejected",b].concat(d))},function(b){n(["progress",b].concat(d))});return this};l.prototype.traceRejected=function(){var d=b._toArray(arguments);this.otherwise(function(b){n(["rejected",b].concat(d))});return this};return d})},"dojo/errors/RequestError":function(){define(["./create"],function(b){return b("RequestError",function(b,g){this.response=g})})},"dojo/_base/html":function(){define("./kernel ../dom ../dom-style ../dom-attr ../dom-prop ../dom-class ../dom-construct ../dom-geometry".split(" "),
function(b,l,g,n,d,f,m,h){b.byId=l.byId;b.isDescendant=l.isDescendant;b.setSelectable=l.setSelectable;b.getAttr=n.get;b.setAttr=n.set;b.hasAttr=n.has;b.removeAttr=n.remove;b.getNodeProp=n.getNodeProp;b.attr=function(b,a,e){return 2==arguments.length?n["string"==typeof a?"get":"set"](b,a):n.set(b,a,e)};b.hasClass=f.contains;b.addClass=f.add;b.removeClass=f.remove;b.toggleClass=f.toggle;b.replaceClass=f.replace;b._toDom=b.toDom=m.toDom;b.place=m.place;b.create=m.create;b.empty=function(b){m.empty(b)};
b._destroyElement=b.destroy=function(b){m.destroy(b)};b._getPadExtents=b.getPadExtents=h.getPadExtents;b._getBorderExtents=b.getBorderExtents=h.getBorderExtents;b._getPadBorderExtents=b.getPadBorderExtents=h.getPadBorderExtents;b._getMarginExtents=b.getMarginExtents=h.getMarginExtents;b._getMarginSize=b.getMarginSize=h.getMarginSize;b._getMarginBox=b.getMarginBox=h.getMarginBox;b.setMarginBox=h.setMarginBox;b._getContentBox=b.getContentBox=h.getContentBox;b.setContentSize=h.setContentSize;b._isBodyLtr=
b.isBodyLtr=h.isBodyLtr;b._docScroll=b.docScroll=h.docScroll;b._getIeDocumentElementOffset=b.getIeDocumentElementOffset=h.getIeDocumentElementOffset;b._fixIeBiDiScrollLeft=b.fixIeBiDiScrollLeft=h.fixIeBiDiScrollLeft;b.position=h.position;b.marginBox=function(b,a){return a?h.setMarginBox(b,a):h.getMarginBox(b)};b.contentBox=function(b,a){return a?h.setContentSize(b,a):h.getContentBox(b)};b.coords=function(d,a){b.deprecated("dojo.coords()","Use dojo.position() or dojo.marginBox().");d=l.byId(d);var e=
g.getComputedStyle(d),e=h.getMarginBox(d,e),k=h.position(d,a);e.x=k.x;e.y=k.y;return e};b.getProp=d.get;b.setProp=d.set;b.prop=function(b,a,e){return 2==arguments.length?d["string"==typeof a?"get":"set"](b,a):d.set(b,a,e)};b.getStyle=g.get;b.setStyle=g.set;b.getComputedStyle=g.getComputedStyle;b.__toPixelValue=b.toPixelValue=g.toPixelValue;b.style=function(b,a,e){switch(arguments.length){case 1:return g.get(b);case 2:return g["string"==typeof a?"get":"set"](b,a)}return g.set(b,a,e)};return b})},"dojo/_base/kernel":function(){define(["../has",
"./config","require","module"],function(b,l,g,n){var d,f;d=function(){return this}();var m={},h={},p={config:l,global:d,dijit:m,dojox:h},m={dojo:["dojo",p],dijit:["dijit",m],dojox:["dojox",h]};n=g.map&&g.map[n.id.match(/[^\/]+/)[0]];for(f in n)m[f]?m[f][0]=n[f]:m[f]=[n[f],{}];for(f in m)n=m[f],n[1]._scopeName=n[0],l.noGlobals||(d[n[0]]=n[1]);p.scopeMap=m;p.baseUrl=p.config.baseUrl=g.baseUrl;p.isAsync=g.async;p.locale=l.locale;d="$Rev: f4fef70 $".match(/[0-9a-f]{7,}/);p.version={major:1,minor:10,patch:4,
flag:"",revision:d?d[0]:NaN,toString:function(){var a=p.version;return a.major+"."+a.minor+"."+a.patch+a.flag+" ("+a.revision+")"}};Function("d","d.eval \x3d function(){return d.global.eval ? d.global.eval(arguments[0]) : eval(arguments[0]);}")(p);p.exit=function(){};"undefined"!=typeof console||(console={});n="assert count debug dir dirxml error group groupEnd info profile profileEnd time timeEnd trace warn log".split(" ");var a;for(d=0;a=n[d++];)console[a]||function(){var b=a+"";console[b]="log"in
console?function(){var a=Array.prototype.slice.call(arguments);a.unshift(b+":");console.log(a.join(" "))}:function(){};console[b]._fake=!0}();b.add("dojo-debug-messages",!!l.isDebug);p.deprecated=p.experimental=function(){};b("dojo-debug-messages")&&(p.deprecated=function(a,b,c){},p.experimental=function(a,b){});if(l.modulePaths){p.deprecated("dojo.modulePaths","use paths configuration");b={};for(f in l.modulePaths)b[f.replace(/\./g,"/")]=l.modulePaths[f];g({paths:b})}p.moduleUrl=function(a,b){p.deprecated("dojo.moduleUrl()",
"use require.toUrl","2.0");var c=null;a&&(c=g.toUrl(a.replace(/\./g,"/")+(b?"/"+b:"")+"/*.*").replace(/\/\*\.\*/,"")+(b?"":"/"));return c};p._hasResource={};return p})},"dojo/io-query":function(){define(["./_base/lang"],function(b){var l={};return{objectToQuery:function(g){var n=encodeURIComponent,d=[],f;for(f in g){var m=g[f];if(m!=l[f]){var h=n(f)+"\x3d";if(b.isArray(m))for(var p=0,a=m.length;p<a;++p)d.push(h+n(m[p]));else d.push(h+n(m))}}return d.join("\x26")},queryToObject:function(g){var l=decodeURIComponent;
g=g.split("\x26");for(var d={},f,m,h=0,p=g.length;h<p;++h)if(m=g[h],m.length){var a=m.indexOf("\x3d");0>a?(f=l(m),m=""):(f=l(m.slice(0,a)),m=l(m.slice(a+1)));"string"==typeof d[f]&&(d[f]=[d[f]]);b.isArray(d[f])?d[f].push(m):d[f]=m}return d}}})},"dojo/_base/Deferred":function(){define("./kernel ../Deferred ../promise/Promise ../errors/CancelError ../has ./lang ../when".split(" "),function(b,l,g,n,d,f,m){var h=function(){},p=Object.freeze||function(){},a=b.Deferred=function(b){function k(a){if(m)throw Error("This deferred has already been resolved");
t=a;m=!0;c()}function c(){for(var a;!a&&y;){var c=y;y=y.next;if(a=c.progress==h)m=!1;var b=v?c.error:c.resolved;d("config-useDeferredInstrumentation")&&v&&l.instrumentRejected&&l.instrumentRejected(t,!!b);if(b)try{var e=b(t);e&&"function"===typeof e.then?e.then(f.hitch(c.deferred,"resolve"),f.hitch(c.deferred,"reject"),f.hitch(c.deferred,"progress")):(b=a&&void 0===e,a&&!b&&(v=e instanceof Error),c.deferred[b&&v?"reject":"resolve"](b?t:e))}catch(k){c.deferred.reject(k)}else v?c.deferred.reject(t):
c.deferred.resolve(t)}}var t,m,r,u,v,x,y,z=this.promise=new g;this.isResolved=z.isResolved=function(){return 0==u};this.isRejected=z.isRejected=function(){return 1==u};this.isFulfilled=z.isFulfilled=function(){return 0<=u};this.isCanceled=z.isCanceled=function(){return r};this.resolve=this.callback=function(a){this.fired=u=0;this.results=[a,null];k(a)};this.reject=this.errback=function(a){v=!0;this.fired=u=1;d("config-useDeferredInstrumentation")&&l.instrumentRejected&&l.instrumentRejected(a,!!y);
k(a);this.results=[null,a]};this.progress=function(a){for(var c=y;c;){var b=c.progress;b&&b(a);c=c.next}};this.addCallbacks=function(a,c){this.then(a,c,h);return this};z.then=this.then=function(b,e,d){var k=d==h?this:new a(z.cancel);b={resolved:b,error:e,progress:d,deferred:k};y?x=x.next=b:y=x=b;m&&c();return k.promise};var w=this;z.cancel=this.cancel=function(){if(!m){var a=b&&b(w);m||(a instanceof Error||(a=new n(a)),a.log=!1,w.reject(a))}r=!0};p(z)};f.extend(a,{addCallback:function(a){return this.addCallbacks(f.hitch.apply(b,
arguments))},addErrback:function(a){return this.addCallbacks(null,f.hitch.apply(b,arguments))},addBoth:function(a){var d=f.hitch.apply(b,arguments);return this.addCallbacks(d,d)},fired:-1});a.when=b.when=m;return a})},"dojo/NodeList-dom":function(){define("./_base/kernel ./query ./_base/array ./_base/lang ./dom-class ./dom-construct ./dom-geometry ./dom-attr ./dom-style".split(" "),function(b,l,g,n,d,f,m,h,p){function a(a){return function(c,b,e){return 2==arguments.length?a["string"==typeof b?"get":
"set"](c,b):a.set(c,b,e)}}var e=function(a){return 1==a.length&&"string"==typeof a[0]},k=function(a){var c=a.parentNode;c&&c.removeChild(a)},c=l.NodeList,t=c._adaptWithCondition,q=c._adaptAsForEach,r=c._adaptAsMap;n.extend(c,{_normalize:function(a,c){var e=!0===a.parse;if("string"==typeof a.template){var d=a.templateFunc||b.string&&b.string.substitute;a=d?d(a.template,a):a}d=typeof a;"string"==d||"number"==d?(a=f.toDom(a,c&&c.ownerDocument),a=11==a.nodeType?n._toArray(a.childNodes):[a]):n.isArrayLike(a)?
n.isArray(a)||(a=n._toArray(a)):a=[a];e&&(a._runParse=!0);return a},_cloneNode:function(a){return a.cloneNode(!0)},_place:function(a,c,e,d){if(1==c.nodeType||"only"!=e)for(var k,h=a.length,g=h-1;0<=g;g--){var m=d?this._cloneNode(a[g]):a[g];if(a._runParse&&b.parser&&b.parser.parse)for(k||(k=c.ownerDocument.createElement("div")),k.appendChild(m),b.parser.parse(k),m=k.firstChild;k.firstChild;)k.removeChild(k.firstChild);g==h-1?f.place(m,c,e):c.parentNode.insertBefore(m,c);c=m}},position:r(m.position),
attr:t(a(h),e),style:t(a(p),e),addClass:q(d.add),removeClass:q(d.remove),toggleClass:q(d.toggle),replaceClass:q(d.replace),empty:q(f.empty),removeAttr:q(h.remove),marginBox:r(m.getMarginBox),place:function(a,c){var b=l(a)[0];return this.forEach(function(a){f.place(a,b,c)})},orphan:function(a){return(a?l._filterResult(this,a):this).forEach(k)},adopt:function(a,c){return l(a).place(this[0],c)._stash(this)},query:function(a){if(!a)return this;var b=new c;this.map(function(c){l(a,c).forEach(function(a){void 0!==
a&&b.push(a)})});return b._stash(this)},filter:function(a){var c=arguments,b=this,e=0;if("string"==typeof a){b=l._filterResult(this,c[0]);if(1==c.length)return b._stash(this);e=1}return this._wrap(g.filter(b,c[e],c[e+1]),this)},addContent:function(a,c){a=this._normalize(a,this[0]);for(var b=0,e;e=this[b];b++)a.length?this._place(a,e,c,0<b):f.empty(e);return this}});return c})},"dojo/query":function(){define("./_base/kernel ./has ./dom ./on ./_base/array ./_base/lang ./selector/_loader ./selector/_loader!default".split(" "),
function(b,l,g,n,d,f,m,h){function p(a,c){var b=function(b,e){if("string"==typeof e&&(e=g.byId(e),!e))return new c([]);var d="string"==typeof b?a(b,e):b?b.end&&b.on?b:[b]:[];return d.end&&d.on?d:new c(d)};b.matches=a.match||function(a,c,e){return 0<b.filter([a],c,e).length};b.filter=a.filter||function(a,c,e){return b(c,e).filter(function(c){return-1<d.indexOf(a,c)})};if("function"!=typeof a){var e=a.search;a=function(a,c){return e(c||document,a)}}return b}l.add("array-extensible",function(){return 1==
f.delegate([],{length:1}).length&&!l("bug-for-in-skips-shadowed")});var a=Array.prototype,e=a.slice,k=a.concat,c=d.forEach,t=function(a,c,d){c=[0].concat(e.call(c,0));d=d||b.global;return function(b){c[0]=b;return a.apply(d,c)}},q=function(a){var c=this instanceof r&&l("array-extensible");"number"==typeof a&&(a=Array(a));var b=a&&"length"in a?a:arguments;if(c||!b.sort){for(var e=c?this:[],d=e.length=b.length,k=0;k<d;k++)e[k]=b[k];if(c)return e;b=e}f._mixin(b,u);b._NodeListCtor=function(a){return r(a)};
return b},r=q,u=r.prototype=l("array-extensible")?[]:{};r._wrap=u._wrap=function(a,c,b){a=new (b||this._NodeListCtor||r)(a);return c?a._stash(c):a};r._adaptAsMap=function(a,c){return function(){return this.map(t(a,arguments,c))}};r._adaptAsForEach=function(a,c){return function(){this.forEach(t(a,arguments,c));return this}};r._adaptAsFilter=function(a,c){return function(){return this.filter(t(a,arguments,c))}};r._adaptWithCondition=function(a,c,e){return function(){var d=arguments,k=t(a,d,e);if(c.call(e||
b.global,d))return this.map(k);this.forEach(k);return this}};c(["slice","splice"],function(c){var b=a[c];u[c]=function(){return this._wrap(b.apply(this,arguments),"slice"==c?this:null)}});c(["indexOf","lastIndexOf","every","some"],function(a){var c=d[a];u[a]=function(){return c.apply(b,[this].concat(e.call(arguments,0)))}});f.extend(q,{constructor:r,_NodeListCtor:r,toString:function(){return this.join(",")},_stash:function(a){this._parent=a;return this},on:function(a,c){var b=this.map(function(b){return n(b,
a,c)});b.remove=function(){for(var a=0;a<b.length;a++)b[a].remove()};return b},end:function(){return this._parent?this._parent:new this._NodeListCtor(0)},concat:function(a){var c=e.call(this,0),b=d.map(arguments,function(a){return e.call(a,0)});return this._wrap(k.apply(c,b),this)},map:function(a,c){return this._wrap(d.map(this,a,c),this)},forEach:function(a,b){c(this,a,b);return this},filter:function(a){var c=arguments,b=this,e=0;if("string"==typeof a){b=v._filterResult(this,c[0]);if(1==c.length)return b._stash(this);
e=1}return this._wrap(d.filter(b,c[e],c[e+1]),this)},instantiate:function(a,c){var b=f.isFunction(a)?a:f.getObject(a);c=c||{};return this.forEach(function(a){new b(c,a)})},at:function(){var a=new this._NodeListCtor(0);c(arguments,function(c){0>c&&(c=this.length+c);this[c]&&a.push(this[c])},this);return a._stash(this)}});var v=p(h,q);b.query=p(h,function(a){return q(a)});v.load=function(a,c,b){m.load(a,c,function(a){b(p(a,q))})};b._filterQueryResult=v._filterResult=function(a,c,b){return new q(v.filter(a,
c,b))};b.NodeList=v.NodeList=q;return v})},"dojo/has":function(){define(["require","module"],function(b,l){var g=b.has||function(){};g.add("dom-addeventlistener",!!document.addEventListener);g.add("touch","ontouchstart"in document||"onpointerdown"in document&&0<navigator.maxTouchPoints||window.navigator.msMaxTouchPoints);g.add("touch-events","ontouchstart"in document);g.add("pointer-events","pointerEnabled"in window.navigator?window.navigator.pointerEnabled:"PointerEvent"in window);g.add("MSPointer",
window.navigator.msPointerEnabled);g.add("device-width",screen.availWidth||innerWidth);var n=document.createElement("form");g.add("dom-attributes-explicit",0==n.attributes.length);g.add("dom-attributes-specified-flag",0<n.attributes.length&&40>n.attributes.length);g.clearElement=function(b){b.innerHTML="";return b};g.normalize=function(b,f){var m=b.match(/[\?:]|[^:\?]*/g),h=0,p=function(a){var b=m[h++];if(":"==b)return 0;if("?"==m[h++]){if(!a&&g(b))return p();p(!0);return p(a)}return b||0};return(b=
p())&&f(b)};g.load=function(b,f,g){b?f([b],g):g()};return g})},"dojo/_base/loader":function(){define("./kernel ../has require module ../json ./lang ./array".split(" "),function(b,l,g,n,d,f,m){var h=function(a){return a.replace(/\./g,"/")},p=/\/\/>>built/,a=[],e=[],k=function(b,d,k){a.push(k);m.forEach(b.split(","),function(a){a=S(a,d.module);e.push(a);J(a)});c()},c=function(){var c,b;for(b in Q)if(c=Q[b],void 0===c.noReqPluginCheck&&(c.noReqPluginCheck=/loadInit\!/.test(b)||/require\!/.test(b)?1:
0),!c.executed&&!c.noReqPluginCheck&&c.injected==z)return;I(function(){var c=a;a=[];m.forEach(c,function(a){a(1)})})},t=function(a,c,e){var d=/\(|\)/g,k=1;for(d.lastIndex=c;(c=d.exec(a))&&(")"==c[0]?--k:k+=1,0!=k););if(0!=k)throw"unmatched paren around character "+d.lastIndex+" in: "+a;return[b.trim(a.substring(e,d.lastIndex))+";\n",d.lastIndex]},q=/(\/\*([\s\S]*?)\*\/|\/\/(.*)$)/mg,r=/(^|\s)dojo\.(loadInit|require|provide|requireLocalization|requireIf|requireAfterIf|platformRequire)\s*\(/mg,u=/(^|\s)(require|define)\s*\(/m,
v=function(a,c){var b,e,d,k=[],f=[];b=[];for(c=c||a.replace(q,function(a){r.lastIndex=u.lastIndex=0;return r.test(a)||u.test(a)?"":a});b=r.exec(c);)e=r.lastIndex,d=e-b[0].length,e=t(c,e,d),"loadInit"==b[2]?k.push(e[0]):f.push(e[0]),r.lastIndex=e[1];b=k.concat(f);return b.length||!u.test(c)?[a.replace(/(^|\s)dojo\.loadInit\s*\(/g,"\n0 \x26\x26 dojo.loadInit("),b.join(""),b]:0},x=g.initSyncLoader(k,c,function(a,c){var b,e,k=[],f=[];if(p.test(c)||!(b=v(c)))return 0;e=a.mid+"-*loadInit";for(var g in S("dojo",
a).result.scopeMap)k.push(g),f.push('"'+g+'"');return"// xdomain rewrite of "+a.mid+"\ndefine('"+e+"',{\n\tnames:"+d.stringify(k)+",\n\tdef:function("+k.join(",")+"){"+b[1]+"}});\n\ndefine("+d.stringify(k.concat(["dojo/loadInit!"+e]))+", function("+k.join(",")+"){\n"+b[0]+"});"}),y=x.sync,z=x.requested,w=x.arrived,B=x.nonmodule,E=x.executing,X=x.executed,O=x.syncExecStack,Q=x.modules,P=x.execQ,S=x.getModule,J=x.injectModule,K=x.setArrived,R=x.signal,F=x.finishExec,A=x.execModule,D=x.getLegacyMode,
I=x.guardCheckComplete,k=x.dojoRequirePlugin;b.provide=function(a){var c=O[0],b=f.mixin(S(h(a),g.module),{executed:E,result:f.getObject(a,!0)});K(b);c&&(c.provides||(c.provides=[])).push(function(){b.result=f.getObject(a);delete b.provides;b.executed!==X&&F(b)});return b.result};l.add("config-publishRequireResult",1,0,0);b.require=function(a,c){var b=function(a,c){var b=S(h(a),g.module);if(O.length&&O[0].finish)O[0].finish.push(a);else{if(b.executed)return b.result;c&&(b.result=B);var e=D();J(b);
e=D();b.executed!==X&&b.injected===w&&x.guardCheckComplete(function(){A(b)});if(b.executed)return b.result;e==y?b.cjs?P.unshift(b):O.length&&(O[0].finish=[a]):P.push(b)}}(a,c);l("config-publishRequireResult")&&!f.exists(a)&&void 0!==b&&f.setObject(a,b);return b};b.loadInit=function(a){a()};b.registerModulePath=function(a,c){var b={};b[a.replace(/\./g,"/")]=c;g({paths:b})};b.platformRequire=function(a){a=(a.common||[]).concat(a[b._name]||a["default"]||[]);for(var c;a.length;)f.isArray(c=a.shift())?
b.require.apply(b,c):b.require(c)};b.requireIf=b.requireAfterIf=function(a,c,e){a&&b.require(c,e)};b.requireLocalization=function(a,c,b){g(["../i18n"],function(e){e.getLocalization(a,c,b)})};return{extractLegacyApiApplications:v,require:k,loadInit:function(a,c,e){c([a],function(a){c(a.names,function(){for(var d="",f=[],g=0;g<arguments.length;g++)d+="var "+a.names[g]+"\x3d arguments["+g+"]; ",f.push(arguments[g]);eval(d);var m=c.module,t=[],r,d={provide:function(a){a=h(a);a=S(a,m);a!==m&&K(a)},require:function(a,
c){a=h(a);c&&(S(a,m).result=B);t.push(a)},requireLocalization:function(a,c,e){r||(r=["dojo/i18n"]);e=(e||b.locale).toLowerCase();a=h(a)+"/nls/"+(/root/i.test(e)?"":e+"/")+h(c);S(a,m).isXd&&r.push("dojo/i18n!"+a)},loadInit:function(a){a()}},g={},q;try{for(q in d)g[q]=b[q],b[q]=d[q];a.def.apply(null,f)}catch(p){R("error",[{src:n.id,id:"failedDojoLoadInit"},p])}finally{for(q in d)b[q]=g[q]}r&&(t=t.concat(r));t.length?k(t.join(","),c,e):e()})})}}})},"dojo/json":function(){define(["./has"],function(b){var l=
"undefined"!=typeof JSON;b.add("json-parse",l);b.add("json-stringify",l&&'{"a":1}'==JSON.stringify({a:0},function(b,d){return d||1}));if(b("json-stringify"))return JSON;var g=function(b){return('"'+b.replace(/(["\\])/g,"\\$1")+'"').replace(/[\f]/g,"\\f").replace(/[\b]/g,"\\b").replace(/[\n]/g,"\\n").replace(/[\t]/g,"\\t").replace(/[\r]/g,"\\r")};return{parse:b("json-parse")?JSON.parse:function(b,d){if(d&&!/^([\s\[\{]*(?:"(?:\\.|[^"])*"|-?\d[\d\.]*(?:[Ee][+-]?\d+)?|null|true|false|)[\s\]\}]*(?:,|:|$))+$/.test(b))throw new SyntaxError("Invalid characters in JSON");
return eval("("+b+")")},stringify:function(b,d,f){function m(b,a,e){d&&(b=d(e,b));var k;k=typeof b;if("number"==k)return isFinite(b)?b+"":"null";if("boolean"==k)return b+"";if(null===b)return"null";if("string"==typeof b)return g(b);if("function"==k||"undefined"==k)return h;if("function"==typeof b.toJSON)return m(b.toJSON(e),a,e);if(b instanceof Date)return'"{FullYear}-{Month+}-{Date}T{Hours}:{Minutes}:{Seconds}Z"'.replace(/\{(\w+)(\+)?\}/g,function(a,c,e){a=b["getUTC"+c]()+(e?1:0);return 10>a?"0"+
a:a});if(b.valueOf()!==b)return m(b.valueOf(),a,e);var c=f?a+f:"",t=f?" ":"",q=f?"\n":"";if(b instanceof Array){var t=b.length,r=[];for(e=0;e<t;e++)k=m(b[e],c,e),"string"!=typeof k&&(k="null"),r.push(q+c+k);return"["+r.join(",")+q+a+"]"}r=[];for(e in b){var l;if(b.hasOwnProperty(e)){if("number"==typeof e)l='"'+e+'"';else if("string"==typeof e)l=g(e);else continue;k=m(b[e],c,e);"string"==typeof k&&r.push(q+c+l+":"+t+k)}}return"{"+r.join(",")+q+a+"}"}var h;"string"==typeof d&&(f=d,d=null);return m(b,
"","")}}})},"dojo/_base/declare":function(){define(["./kernel","../has","./lang"],function(b,l,g){function n(a,c){throw Error("declare"+(c?" "+c:"")+": "+a);}function d(a,c,b){var e,d,k,f,g,h,m,t=this._inherited=this._inherited||{};"string"==typeof a&&(e=a,a=c,c=b);b=0;f=a.callee;(e=e||f.nom)||n("can't deduce a name to call inherited()",this.declaredClass);g=this.constructor._meta;k=g.bases;m=t.p;if("constructor"!=e){if(t.c!==f&&(m=0,h=k[0],g=h._meta,g.hidden[e]!==f)){(d=g.chains)&&"string"==typeof d[e]&&
n("calling chained method with inherited: "+e,this.declaredClass);do if(g=h._meta,d=h.prototype,g&&(d[e]===f&&d.hasOwnProperty(e)||g.hidden[e]===f))break;while(h=k[++m]);m=h?m:-1}if(h=k[++m])if(d=h.prototype,h._meta&&d.hasOwnProperty(e))b=d[e];else{f=y[e];do if(d=h.prototype,(b=d[e])&&(h._meta?d.hasOwnProperty(e):b!==f))break;while(h=k[++m])}b=h&&b||y[e]}else{if(t.c!==f&&(m=0,(g=k[0]._meta)&&g.ctor!==f)){for((d=g.chains)&&"manual"===d.constructor||n("calling chained constructor with inherited",this.declaredClass);(h=
k[++m])&&(!(g=h._meta)||g.ctor!==f););m=h?m:-1}for(;(h=k[++m])&&!(b=(g=h._meta)?g.ctor:h););b=h&&b}t.c=b;t.p=m;if(b)return!0===c?b:b.apply(this,c||a)}function f(a,c){return"string"==typeof a?this.__inherited(a,c,!0):this.__inherited(a,!0)}function m(a,c,b){var e=this.getInherited(a,c);if(e)return e.apply(this,b||c||a)}function h(a){for(var c=this.constructor._meta.bases,b=0,e=c.length;b<e;++b)if(c[b]===a)return!0;return this instanceof a}function p(a,c){for(var b in c)"constructor"!=b&&c.hasOwnProperty(b)&&
(a[b]=c[b]);if(l("bug-for-in-skips-shadowed"))for(var e=g._extraNames,d=e.length;d;)b=e[--d],"constructor"!=b&&c.hasOwnProperty(b)&&(a[b]=c[b])}function a(a){v.safeMixin(this.prototype,a);return this}function e(a,c){a instanceof Array||"function"==typeof a||(c=a,a=void 0);c=c||{};a=a||[];return v([this].concat(a),c)}function k(a,c){return function(){var b=arguments,e=b,d=b[0],k,f;f=a.length;var g;if(!(this instanceof b.callee))return u(b);if(c&&(d&&d.preamble||this.preamble))for(g=Array(a.length),
g[0]=b,k=0;;){(d=b[0])&&(d=d.preamble)&&(b=d.apply(this,b)||b);d=a[k].prototype;(d=d.hasOwnProperty("preamble")&&d.preamble)&&(b=d.apply(this,b)||b);if(++k==f)break;g[k]=b}for(k=f-1;0<=k;--k)d=a[k],(d=(f=d._meta)?f.ctor:d)&&d.apply(this,g?g[k]:b);(d=this.postscript)&&d.apply(this,e)}}function c(a,c){return function(){var b=arguments,e=b,d=b[0];if(!(this instanceof b.callee))return u(b);c&&(d&&(d=d.preamble)&&(e=d.apply(this,e)||e),(d=this.preamble)&&d.apply(this,e));a&&a.apply(this,b);(d=this.postscript)&&
d.apply(this,b)}}function t(a){return function(){var c=arguments,b=0,e,d;if(!(this instanceof c.callee))return u(c);for(;e=a[b];++b)if(e=(d=e._meta)?d.ctor:e){e.apply(this,c);break}(e=this.postscript)&&e.apply(this,c)}}function q(a,c,b){return function(){var e,d,k=0,f=1;b&&(k=c.length-1,f=-1);for(;e=c[k];k+=f)d=e._meta,(e=(d?d.hidden:e.prototype)[a])&&e.apply(this,arguments)}}function r(a){w.prototype=a.prototype;a=new w;w.prototype=null;return a}function u(a){var c=a.callee,b=r(c);c.apply(b,a);return b}
function v(b,m,l){"string"!=typeof b&&(l=m,m=b,b="");l=l||{};var u,w,J,K,R,F,A,D=1,I=m;if("[object Array]"==z.call(m)){D=b;w=[];J=[{cls:0,refs:[]}];K={};F=1;for(var ea=m.length,L=0,C,M,G,H;L<ea;++L){(C=m[L])?"[object Function]"!=z.call(C)&&n("mixin #"+L+" is not a callable constructor.",D):n("mixin #"+L+" is unknown. Did you use dojo.require to pull it in?",D);M=C._meta?C._meta.bases:[C];G=0;for(C=M.length-1;0<=C;--C)H=M[C].prototype,H.hasOwnProperty("declaredClass")||(H.declaredClass="uniqName_"+
B++),H=H.declaredClass,K.hasOwnProperty(H)||(K[H]={count:0,refs:[],cls:M[C]},++F),H=K[H],G&&G!==H&&(H.refs.push(G),++G.count),G=H;++G.count;J[0].refs.push(G)}for(;J.length;){G=J.pop();w.push(G.cls);for(--F;u=G.refs,1==u.length;){G=u[0];if(!G||--G.count){G=0;break}w.push(G.cls);--F}if(G)for(L=0,ea=u.length;L<ea;++L)G=u[L],--G.count||J.push(G)}F&&n("can't build consistent linearization",D);C=m[0];w[0]=C?C._meta&&C===w[w.length-C._meta.bases.length]?C._meta.bases.length:1:0;F=w;J=F[0];D=F.length-J;m=
F[D]}else F=[0],m?"[object Function]"==z.call(m)?(J=m._meta,F=F.concat(J?J.bases:m)):n("base class is not a callable constructor.",b):null!==m&&n("unknown base class. Did you use dojo.require to pull it in?",b);if(m)for(w=D-1;;--w){u=r(m);if(!w)break;J=F[w];(J._meta?p:x)(u,J.prototype);K=new Function;K.superclass=m;K.prototype=u;m=u.constructor=K}else u={};v.safeMixin(u,l);J=l.constructor;J!==y.constructor&&(J.nom="constructor",u.constructor=J);for(w=D-1;w;--w)(J=F[w]._meta)&&J.chains&&(A=x(A||{},
J.chains));u["-chains-"]&&(A=x(A||{},u["-chains-"]));J=!A||!A.hasOwnProperty("constructor");F[0]=K=A&&"manual"===A.constructor?t(F):1==F.length?c(l.constructor,J):k(F,J);K._meta={bases:F,hidden:l,chains:A,parents:I,ctor:l.constructor};K.superclass=m&&m.prototype;K.extend=a;K.createSubclass=e;K.prototype=u;u.constructor=K;u.getInherited=f;u.isInstanceOf=h;u.inherited=E;u.__inherited=d;b&&(u.declaredClass=b,g.setObject(b,K));if(A)for(R in A)u[R]&&"string"==typeof A[R]&&"constructor"!=R&&(J=u[R]=q(R,
F,"after"===A[R]),J.nom=R);return K}var x=g.mixin,y=Object.prototype,z=y.toString,w=new Function,B=0,E=b.config.isDebug?m:d;b.safeMixin=v.safeMixin=function(a,c){var b,e;for(b in c)e=c[b],e===y[b]&&b in y||"constructor"==b||("[object Function]"==z.call(e)&&(e.nom=b),a[b]=e);if(l("bug-for-in-skips-shadowed"))for(var d=g._extraNames,k=d.length;k;)b=d[--k],e=c[b],e===y[b]&&b in y||"constructor"==b||("[object Function]"==z.call(e)&&(e.nom=b),a[b]=e);return a};return b.declare=v})},"dojo/dom":function(){define(["./sniff",
"./_base/window"],function(b,l){if(7>=b("ie"))try{document.execCommand("BackgroundImageCache",!1,!0)}catch(d){}var g={};b("ie")?g.byId=function(b,f){if("string"!=typeof b)return b;var g=f||l.doc,h=b&&g.getElementById(b);if(!h||h.attributes.id.value!=b&&h.id!=b){g=g.all[b];if(!g||g.nodeName)g=[g];for(var p=0;h=g[p++];)if(h.attributes&&h.attributes.id&&h.attributes.id.value==b||h.id==b)return h}else return h}:g.byId=function(b,f){return("string"==typeof b?(f||l.doc).getElementById(b):b)||null};g.isDescendant=
function(b,f){try{for(b=g.byId(b),f=g.byId(f);b;){if(b==f)return!0;b=b.parentNode}}catch(m){}return!1};b.add("css-user-select",function(b,f,g){if(!g)return!1;b=g.style;f=["Khtml","O","Moz","Webkit"];g=f.length;var h="userSelect";do if("undefined"!==typeof b[h])return h;while(g--&&(h=f[g]+"UserSelect"));return!1});var n=b("css-user-select");g.setSelectable=n?function(b,f){g.byId(b).style[n]=f?"":"none"}:function(b,f){b=g.byId(b);var m=b.getElementsByTagName("*"),h=m.length;if(f)for(b.removeAttribute("unselectable");h--;)m[h].removeAttribute("unselectable");
else for(b.setAttribute("unselectable","on");h--;)m[h].setAttribute("unselectable","on")};return g})},"dojo/_base/browser":function(){require.has&&require.has.add("config-selectorEngine","acme");define("../ready ./kernel ./connect ./unload ./window ./event ./html ./NodeList ../query ./xhr ./fx".split(" "),function(b){return b})},"dojo/selector/acme":function(){define(["../dom","../sniff","../_base/array","../_base/lang","../_base/window"],function(b,l,g,n,d){var f=n.trim,m=g.forEach,h="BackCompat"==
d.doc.compatMode,p=!1,a=function(){return!0},e=function(a){a=0<="\x3e~+".indexOf(a.slice(-1))?a+" * ":a+" ";for(var b=function(b,c){return f(a.slice(b,c))},c=[],e=-1,d=-1,k=-1,g=-1,h=-1,m=-1,t=-1,r,q="",l="",n,E=0,u=a.length,v=null,B=null,O=function(){0<=m&&(v.id=b(m,E).replace(/\\/g,""),m=-1);if(0<=t){var a=t==E?null:b(t,E);v[0>"\x3e~+".indexOf(a)?"tag":"oper"]=a;t=-1}0<=h&&(v.classes.push(b(h+1,E).replace(/\\/g,"")),h=-1)};q=l,l=a.charAt(E),E<u;E++)"\\"!=q&&(v||(n=E,v={query:null,pseudos:[],attrs:[],
classes:[],tag:null,oper:null,id:null,getTag:function(){return p?this.otag:this.tag}},t=E),r?l==r&&(r=null):"'"==l||'"'==l?r=l:0<=e?"]"==l?(B.attr?B.matchFor=b(k||e+1,E):B.attr=b(e+1,E),!(e=B.matchFor)||'"'!=e.charAt(0)&&"'"!=e.charAt(0)||(B.matchFor=e.slice(1,-1)),B.matchFor&&(B.matchFor=B.matchFor.replace(/\\/g,"")),v.attrs.push(B),B=null,e=k=-1):"\x3d"==l&&(k=0<="|~^$*".indexOf(q)?q:"",B.type=k+l,B.attr=b(e+1,E-k.length),k=E+1):0<=d?")"==l&&(0<=g&&(B.value=b(d+1,E)),g=d=-1):"#"==l?(O(),m=E+1):
"."==l?(O(),h=E):":"==l?(O(),g=E):"["==l?(O(),e=E,B={}):"("==l?(0<=g&&(B={name:b(g+1,E),value:null},v.pseudos.push(B)),d=E):" "==l&&q!=l&&(O(),0<=g&&v.pseudos.push({name:b(g+1,E)}),v.loops=v.pseudos.length||v.attrs.length||v.classes.length,v.oquery=v.query=b(n,E),v.otag=v.tag=v.oper?null:v.tag||"*",v.tag&&(v.tag=v.tag.toUpperCase()),c.length&&c[c.length-1].oper&&(v.infixOper=c.pop(),v.query=v.infixOper.query+" "+v.query),c.push(v),v=null));return c},k=function(a,b){return a?b?function(){return a.apply(window,
arguments)&&b.apply(window,arguments)}:a:b},c=function(a,b){var c=b||[];a&&c.push(a);return c},t=function(a){return 1==a.nodeType},q=function(a,b){return a?"class"==b?a.className||"":"for"==b?a.htmlFor||"":"style"==b?a.style.cssText||"":(p?a.getAttribute(b):a.getAttribute(b,2))||"":""},r={"*\x3d":function(a,b){return function(c){return 0<=q(c,a).indexOf(b)}},"^\x3d":function(a,b){return function(c){return 0==q(c,a).indexOf(b)}},"$\x3d":function(a,b){return function(c){c=" "+q(c,a);var e=c.lastIndexOf(b);
return-1<e&&e==c.length-b.length}},"~\x3d":function(a,b){var c=" "+b+" ";return function(b){return 0<=(" "+q(b,a)+" ").indexOf(c)}},"|\x3d":function(a,b){var c=b+"-";return function(e){e=q(e,a);return e==b||0==e.indexOf(c)}},"\x3d":function(a,b){return function(c){return q(c,a)==b}}},u="undefined"==typeof d.doc.firstChild.nextElementSibling,v=u?"nextSibling":"nextElementSibling",x=u?"previousSibling":"previousElementSibling",y=u?t:a,z=function(a){for(;a=a[x];)if(y(a))return!1;return!0},w=function(a){for(;a=
a[v];)if(y(a))return!1;return!0},B=function(a){var b=a.parentNode,b=7!=b.nodeType?b:b.nextSibling,c=0,e=b.children||b.childNodes,d=a._i||a.getAttribute("_i")||-1,k=b._l||("undefined"!==typeof b.getAttribute?b.getAttribute("_l"):-1);if(!e)return-1;e=e.length;if(k==e&&0<=d&&0<=k)return d;l("ie")&&"undefined"!==typeof b.setAttribute?b.setAttribute("_l",e):b._l=e;d=-1;for(b=b.firstElementChild||b.firstChild;b;b=b[v])y(b)&&(l("ie")?b.setAttribute("_i",++c):b._i=++c,a===b&&(d=c));return d},E=function(a){return!(B(a)%
2)},X=function(a){return B(a)%2},O={checked:function(a,b){return function(a){return!("checked"in a?!a.checked:!a.selected)}},disabled:function(a,b){return function(a){return a.disabled}},enabled:function(a,b){return function(a){return!a.disabled}},"first-child":function(){return z},"last-child":function(){return w},"only-child":function(a,b){return function(a){return z(a)&&w(a)}},empty:function(a,b){return function(a){var b=a.childNodes;for(a=a.childNodes.length-1;0<=a;a--){var c=b[a].nodeType;if(1===
c||3==c)return!1}return!0}},contains:function(a,b){var c=b.charAt(0);if('"'==c||"'"==c)b=b.slice(1,-1);return function(a){return 0<=a.innerHTML.indexOf(b)}},not:function(a,b){var c=e(b)[0],d={el:1};"*"!=c.tag&&(d.tag=1);c.classes.length||(d.classes=1);var k=P(c,d);return function(a){return!k(a)}},"nth-child":function(a,b){var c=parseInt;if("odd"==b)return X;if("even"==b)return E;if(-1!=b.indexOf("n")){var e=b.split("n",2),d=e[0]?"-"==e[0]?-1:c(e[0]):1,k=e[1]?c(e[1]):0,f=0,g=-1;0<d?0>k?k=k%d&&d+k%
d:0<k&&(k>=d&&(f=k-k%d),k%=d):0>d&&(d*=-1,0<k&&(g=k,k%=d));if(0<d)return function(a){a=B(a);return a>=f&&(0>g||a<=g)&&a%d==k};b=k}var h=c(b);return function(a){return B(a)==h}}},Q=9>l("ie")||9==l("ie")&&l("quirks")?function(a){var b=a.toLowerCase();"class"==b&&(a="className");return function(c){return p?c.getAttribute(a):c[a]||c[b]}}:function(a){return function(b){return b&&b.getAttribute&&b.hasAttribute(a)}},P=function(b,c){if(!b)return a;c=c||{};var e=null;"el"in c||(e=k(e,t));"tag"in c||"*"!=b.tag&&
(e=k(e,function(a){return a&&(p?a.tagName:a.tagName.toUpperCase())==b.getTag()}));"classes"in c||m(b.classes,function(a,b,c){var d=new RegExp("(?:^|\\s)"+a+"(?:\\s|$)");e=k(e,function(a){return d.test(a.className)});e.count=b});"pseudos"in c||m(b.pseudos,function(a){var b=a.name;O[b]&&(e=k(e,O[b](b,a.value)))});"attrs"in c||m(b.attrs,function(a){var b,c=a.attr;a.type&&r[a.type]?b=r[a.type](c,a.matchFor):c.length&&(b=Q(c));b&&(e=k(e,b))});"id"in c||b.id&&(e=k(e,function(a){return!!a&&a.id==b.id}));
e||"default"in c||(e=a);return e},S=function(a){return function(b,c,e){for(;b=b[v];)if(!u||t(b)){e&&!W(b,e)||!a(b)||c.push(b);break}return c}},J=function(a){return function(b,c,e){for(b=b[v];b;){if(y(b)){if(e&&!W(b,e))break;a(b)&&c.push(b)}b=b[v]}return c}},K=function(b){b=b||a;return function(a,c,e){for(var d=0,k=a.children||a.childNodes;a=k[d++];)y(a)&&(!e||W(a,e))&&b(a,d)&&c.push(a);return c}},R={},F=function(e){var k=R[e.query];if(k)return k;var f=e.infixOper,f=f?f.oper:"",g=P(e,{el:1}),m="*"==
e.tag,t=d.doc.getElementsByClassName;if(f)t={el:1},m&&(t.tag=1),g=P(e,t),"+"==f?k=S(g):"~"==f?k=J(g):"\x3e"==f&&(k=K(g));else if(e.id)g=!e.loops&&m?a:P(e,{el:1,id:1}),k=function(a,d){var k=b.byId(e.id,a.ownerDocument||a);if(k&&g(k)){if(9==a.nodeType)return c(k,d);for(var f=k.parentNode;f&&f!=a;)f=f.parentNode;if(f)return c(k,d)}};else if(t&&/\{\s*\[native code\]\s*\}/.test(String(t))&&e.classes.length&&!h)var g=P(e,{el:1,classes:1,id:1}),r=e.classes.join(" "),k=function(a,b,e){b=c(0,b);for(var k,
d=0,f=a.getElementsByClassName(r);k=f[d++];)g(k,a)&&W(k,e)&&b.push(k);return b};else m||e.loops?(g=P(e,{el:1,tag:1,id:1}),k=function(a,b,k){b=c(0,b);for(var d,f=0,h=(d=e.getTag())?a.getElementsByTagName(d):[];d=h[f++];)g(d,a)&&W(d,k)&&b.push(d);return b}):k=function(a,b,k){b=c(0,b);for(var d=0,f=e.getTag(),f=f?a.getElementsByTagName(f):[];a=f[d++];)W(a,k)&&b.push(a);return b};return R[e.query]=k},A={},D={},I=function(a){var b=e(f(a));if(1==b.length){var k=F(b[0]);return function(a){if(a=k(a,[]))a.nozip=
!0;return a}}return function(a){a=c(a);for(var e,k,d=b.length,f,g,h=0;h<d;h++){g=[];e=b[h];k=a.length-1;0<k&&(f={},g.nozip=!0);k=F(e);for(var m=0;e=a[m];m++)k(e,g,f);if(!g.length)break;a=g}return g}},ea=l("ie")?"commentStrip":"nozip",L=!!d.doc.querySelectorAll,C=/\\[>~+]|n\+\d|([^ \\])?([>~+])([^ =])?/g,M=function(a,b,c,e){return c?(b?b+" ":"")+c+(e?" "+e:""):a},G=/([^[]*)([^\]]*])?/g,H=function(a,b,c){return b.replace(C,M)+(c||"")},V=function(a,b){a=a.replace(G,H);if(L){var c=D[a];if(c&&!b)return c}if(c=
A[a])return c;var c=a.charAt(0),e=-1==a.indexOf(" ");0<=a.indexOf("#")&&e&&(b=!0);if(!L||b||-1!="\x3e~+".indexOf(c)||l("ie")&&-1!=a.indexOf(":")||h&&0<=a.indexOf(".")||-1!=a.indexOf(":contains")||-1!=a.indexOf(":checked")||-1!=a.indexOf("|\x3d")){var k=a.match(/([^\s,](?:"(?:\\.|[^"])+"|'(?:\\.|[^'])+'|[^,])*)/g);return A[a]=2>k.length?I(a):function(a){for(var b=0,c=[],e;e=k[b++];)c=c.concat(I(e)(a));return c}}var d=0<="\x3e~+".indexOf(a.charAt(a.length-1))?a+" *":a;return D[a]=function(b){try{if(9!=
b.nodeType&&!e)throw"";var c=b.querySelectorAll(d);c[ea]=!0;return c}catch(k){return V(a,!0)(b)}}},N=0,aa=l("ie")?function(a){return p?a.getAttribute("_uid")||a.setAttribute("_uid",++N)||N:a.uniqueID}:function(a){return a._uid||(a._uid=++N)},W=function(a,b){if(!b)return 1;var c=aa(a);return b[c]?0:b[c]=1},ba=function(a){if(a&&a.nozip)return a;if(!a||!a.length)return[];if(2>a.length)return[a[0]];var b=[];N++;var c,e;if(l("ie")&&p){var k=N+"";for(c=0;c<a.length;c++)(e=a[c])&&e.getAttribute("_zipIdx")!=
k&&(b.push(e),e.setAttribute("_zipIdx",k))}else if(l("ie")&&a.commentStrip)try{for(c=0;c<a.length;c++)(e=a[c])&&t(e)&&b.push(e)}catch(d){}else for(c=0;c<a.length;c++)(e=a[c])&&e._zipIdx!=N&&(b.push(e),e._zipIdx=N);return b},ca=function(a,b){b=b||d.doc;p="div"===(b.ownerDocument||b).createElement("div").tagName;var c=V(a)(b);return c&&c.nozip?c:ba(c)};ca.filter=function(a,c,k){for(var d=[],f=e(c),f=1!=f.length||/[^\w#\.]/.test(c)?function(a){return-1!=g.indexOf(ca(c,b.byId(k)),a)}:P(f[0]),h=0,m;m=
a[h];h++)f(m)&&d.push(m);return d};return ca})},"dojo/errors/RequestTimeoutError":function(){define(["./create","./RequestError"],function(b,l){return b("RequestTimeoutError",null,l,{dojoType:"timeout"})})},"dojo/dom-style":function(){define(["./sniff","./dom"],function(b,l){function g(c,k,d){k=k.toLowerCase();if(b("ie")||b("trident")){if("auto"==d){if("height"==k)return c.offsetHeight;if("width"==k)return c.offsetWidth}if("fontweight"==k)switch(d){case 700:return"bold";default:return"normal"}}k in
a||(a[k]=e.test(k));return a[k]?f(c,d):d}var n,d={};n=b("webkit")?function(a){var b;if(1==a.nodeType){var e=a.ownerDocument.defaultView;b=e.getComputedStyle(a,null);!b&&a.style&&(a.style.display="",b=e.getComputedStyle(a,null))}return b||{}}:b("ie")&&(9>b("ie")||b("quirks"))?function(a){return 1==a.nodeType&&a.currentStyle?a.currentStyle:{}}:function(a){return 1==a.nodeType?a.ownerDocument.defaultView.getComputedStyle(a,null):{}};d.getComputedStyle=n;var f;f=b("ie")?function(a,b){if(!b)return 0;if("medium"==
b)return 4;if(b.slice&&"px"==b.slice(-2))return parseFloat(b);var e=a.style,k=a.runtimeStyle,d=e.left,f=k.left;k.left=a.currentStyle.left;try{e.left=b,b=e.pixelLeft}catch(g){b=0}e.left=d;k.left=f;return b}:function(a,b){return parseFloat(b)||0};d.toPixelValue=f;var m=function(a,b){try{return a.filters.item("DXImageTransform.Microsoft.Alpha")}catch(e){return b?{}:null}},h=9>b("ie")||10>b("ie")&&b("quirks")?function(a){try{return m(a).Opacity/100}catch(b){return 1}}:function(a){return n(a).opacity},
p=9>b("ie")||10>b("ie")&&b("quirks")?function(a,b){""===b&&(b=1);var e=100*b;1===b?(a.style.zoom="",m(a)&&(a.style.filter=a.style.filter.replace(/\s*progid:DXImageTransform.Microsoft.Alpha\([^\)]+?\)/i,""))):(a.style.zoom=1,m(a)?m(a,1).Opacity=e:a.style.filter+=" progid:DXImageTransform.Microsoft.Alpha(Opacity\x3d"+e+")",m(a,1).Enabled=!0);if("tr"==a.tagName.toLowerCase())for(e=a.firstChild;e;e=e.nextSibling)"td"==e.tagName.toLowerCase()&&p(e,b);return b}:function(a,b){return a.style.opacity=b},a=
{left:!0,top:!0},e=/margin|padding|width|height|max|min|offset/,k={cssFloat:1,styleFloat:1,"float":1};d.get=function(a,b){var e=l.byId(a),f=arguments.length;if(2==f&&"opacity"==b)return h(e);b=k[b]?"cssFloat"in e.style?"cssFloat":"styleFloat":b;var m=d.getComputedStyle(e);return 1==f?m:g(e,b,m[b]||e.style[b])};d.set=function(a,b,e){var f=l.byId(a),g=arguments.length,h="opacity"==b;b=k[b]?"cssFloat"in f.style?"cssFloat":"styleFloat":b;if(3==g)return h?p(f,e):f.style[b]=e;for(var m in b)d.set(a,m,b[m]);
return d.getComputedStyle(f)};return d})},"dojo/dom-geometry":function(){define(["./sniff","./_base/window","./dom","./dom-style"],function(b,l,g,n){function d(a,b,k,c,d,f){f=f||"px";a=a.style;isNaN(b)||(a.left=b+f);isNaN(k)||(a.top=k+f);0<=c&&(a.width=c+f);0<=d&&(a.height=d+f)}function f(a){return"button"==a.tagName.toLowerCase()||"input"==a.tagName.toLowerCase()&&"button"==(a.getAttribute("type")||"").toLowerCase()}function m(a){return"border-box"==h.boxModel||"table"==a.tagName.toLowerCase()||
f(a)}var h={boxModel:"content-box"};b("ie")&&(h.boxModel="BackCompat"==document.compatMode?"border-box":"content-box");h.getPadExtents=function(a,b){a=g.byId(a);var k=b||n.getComputedStyle(a),c=n.toPixelValue,d=c(a,k.paddingLeft),f=c(a,k.paddingTop),h=c(a,k.paddingRight),k=c(a,k.paddingBottom);return{l:d,t:f,r:h,b:k,w:d+h,h:f+k}};h.getBorderExtents=function(a,b){a=g.byId(a);var k=n.toPixelValue,c=b||n.getComputedStyle(a),d="none"!=c.borderLeftStyle?k(a,c.borderLeftWidth):0,f="none"!=c.borderTopStyle?
k(a,c.borderTopWidth):0,h="none"!=c.borderRightStyle?k(a,c.borderRightWidth):0,k="none"!=c.borderBottomStyle?k(a,c.borderBottomWidth):0;return{l:d,t:f,r:h,b:k,w:d+h,h:f+k}};h.getPadBorderExtents=function(a,b){a=g.byId(a);var k=b||n.getComputedStyle(a),c=h.getPadExtents(a,k),k=h.getBorderExtents(a,k);return{l:c.l+k.l,t:c.t+k.t,r:c.r+k.r,b:c.b+k.b,w:c.w+k.w,h:c.h+k.h}};h.getMarginExtents=function(a,b){a=g.byId(a);var k=b||n.getComputedStyle(a),c=n.toPixelValue,d=c(a,k.marginLeft),f=c(a,k.marginTop),
h=c(a,k.marginRight),k=c(a,k.marginBottom);return{l:d,t:f,r:h,b:k,w:d+h,h:f+k}};h.getMarginBox=function(a,e){a=g.byId(a);var k=e||n.getComputedStyle(a),c=h.getMarginExtents(a,k),d=a.offsetLeft-c.l,f=a.offsetTop-c.t,m=a.parentNode,l=n.toPixelValue;if(b("mozilla")){var p=parseFloat(k.left),k=parseFloat(k.top);isNaN(p)||isNaN(k)?m&&m.style&&(m=n.getComputedStyle(m),"visible"!=m.overflow&&(d+="none"!=m.borderLeftStyle?l(a,m.borderLeftWidth):0,f+="none"!=m.borderTopStyle?l(a,m.borderTopWidth):0)):(d=p,
f=k)}else(b("opera")||8==b("ie")&&!b("quirks"))&&m&&(m=n.getComputedStyle(m),d-="none"!=m.borderLeftStyle?l(a,m.borderLeftWidth):0,f-="none"!=m.borderTopStyle?l(a,m.borderTopWidth):0);return{l:d,t:f,w:a.offsetWidth+c.w,h:a.offsetHeight+c.h}};h.getContentBox=function(a,e){a=g.byId(a);var d=e||n.getComputedStyle(a),c=a.clientWidth,f=h.getPadExtents(a,d),m=h.getBorderExtents(a,d);c?(d=a.clientHeight,m.w=m.h=0):(c=a.offsetWidth,d=a.offsetHeight);b("opera")&&(f.l+=m.l,f.t+=m.t);return{l:f.l,t:f.t,w:c-
f.w-m.w,h:d-f.h-m.h}};h.setContentSize=function(a,b,k){a=g.byId(a);var c=b.w;b=b.h;m(a)&&(k=h.getPadBorderExtents(a,k),0<=c&&(c+=k.w),0<=b&&(b+=k.h));d(a,NaN,NaN,c,b)};var p={l:0,t:0,w:0,h:0};h.setMarginBox=function(a,e,k){a=g.byId(a);var c=k||n.getComputedStyle(a);k=e.w;var l=e.h,q=m(a)?p:h.getPadBorderExtents(a,c),c=h.getMarginExtents(a,c);if(b("webkit")&&f(a)){var r=a.style;0<=k&&!r.width&&(r.width="4px");0<=l&&!r.height&&(r.height="4px")}0<=k&&(k=Math.max(k-q.w-c.w,0));0<=l&&(l=Math.max(l-q.h-
c.h,0));d(a,e.l,e.t,k,l)};h.isBodyLtr=function(a){a=a||l.doc;return"ltr"==(l.body(a).dir||a.documentElement.dir||"ltr").toLowerCase()};h.docScroll=function(a){a=a||l.doc;var e=l.doc.parentWindow||l.doc.defaultView;return"pageXOffset"in e?{x:e.pageXOffset,y:e.pageYOffset}:(e=b("quirks")?l.body(a):a.documentElement)&&{x:h.fixIeBiDiScrollLeft(e.scrollLeft||0,a),y:e.scrollTop||0}};b("ie")&&(h.getIeDocumentElementOffset=function(a){a=a||l.doc;a=a.documentElement;if(8>b("ie")){var e=a.getBoundingClientRect(),
d=e.left,e=e.top;7>b("ie")&&(d+=a.clientLeft,e+=a.clientTop);return{x:0>d?0:d,y:0>e?0:e}}return{x:0,y:0}});h.fixIeBiDiScrollLeft=function(a,e){e=e||l.doc;var d=b("ie");if(d&&!h.isBodyLtr(e)){var c=b("quirks"),f=c?l.body(e):e.documentElement,g=l.global;6==d&&!c&&g.frameElement&&f.scrollHeight>f.clientHeight&&(a+=f.clientLeft);return 8>d||c?a+f.clientWidth-f.scrollWidth:-a}return a};h.position=function(a,e){a=g.byId(a);var d=l.body(a.ownerDocument),c=a.getBoundingClientRect(),c={x:c.left,y:c.top,w:c.right-
c.left,h:c.bottom-c.top};if(9>b("ie")){var f=h.getIeDocumentElementOffset(a.ownerDocument);c.x-=f.x+(b("quirks")?d.clientLeft+d.offsetLeft:0);c.y-=f.y+(b("quirks")?d.clientTop+d.offsetTop:0)}e&&(d=h.docScroll(a.ownerDocument),c.x+=d.x,c.y+=d.y);return c};h.getMarginSize=function(a,b){a=g.byId(a);var d=h.getMarginExtents(a,b||n.getComputedStyle(a)),c=a.getBoundingClientRect();return{w:c.right-c.left+d.w,h:c.bottom-c.top+d.h}};h.normalizeEvent=function(a){"layerX"in a||(a.layerX=a.offsetX,a.layerY=
a.offsetY);if(!b("dom-addeventlistener")){var e=a.target,e=e&&e.ownerDocument||document,d=b("quirks")?e.body:e.documentElement,c=h.getIeDocumentElementOffset(e);a.pageX=a.clientX+h.fixIeBiDiScrollLeft(d.scrollLeft||0,e)-c.x;a.pageY=a.clientY+(d.scrollTop||0)-c.y}};return h})},"dojo/dom-prop":function(){define("exports ./_base/kernel ./sniff ./_base/lang ./dom ./dom-style ./dom-construct ./_base/connect".split(" "),function(b,l,g,n,d,f,m,h){function p(a){var b="";a=a.childNodes;for(var e=0,d;d=a[e];e++)8!=
d.nodeType&&(b=1==d.nodeType?b+p(d):b+d.nodeValue);return b}var a={},e=0,k=l._scopeName+"attrid";g.add("dom-textContent",function(a,b,e){return"textContent"in e});b.names={"class":"className","for":"htmlFor",tabindex:"tabIndex",readonly:"readOnly",colspan:"colSpan",frameborder:"frameBorder",rowspan:"rowSpan",textcontent:"textContent",valuetype:"valueType"};b.get=function(a,e){a=d.byId(a);var k=e.toLowerCase(),k=b.names[k]||e;return"textContent"!=k||g("dom-textContent")?a[k]:p(a)};b.set=function(c,
l,p){c=d.byId(c);if(2==arguments.length&&"string"!=typeof l){for(var r in l)b.set(c,r,l[r]);return c}r=l.toLowerCase();r=b.names[r]||l;if("style"==r&&"string"!=typeof p)return f.set(c,p),c;if("innerHTML"==r)return g("ie")&&c.tagName.toLowerCase()in{col:1,colgroup:1,table:1,tbody:1,tfoot:1,thead:1,tr:1,title:1}?(m.empty(c),c.appendChild(m.toDom(p,c.ownerDocument))):c[r]=p,c;if("textContent"==r&&!g("dom-textContent"))return m.empty(c),c.appendChild(c.ownerDocument.createTextNode(p)),c;if(n.isFunction(p)){var u=
c[k];u||(u=e++,c[k]=u);a[u]||(a[u]={});var v=a[u][r];if(v)h.disconnect(v);else try{delete c[r]}catch(x){}p?a[u][r]=h.connect(c,r,p):c[r]=null;return c}c[r]=p;return c}})},"dojo/when":function(){define(["./Deferred","./promise/Promise"],function(b,l){return function(g,n,d,f){var m=g&&"function"===typeof g.then,h=m&&g instanceof l;if(!m)return 1<arguments.length?n?n(g):g:(new b).resolve(g);h||(m=new b(g.cancel),g.then(m.resolve,m.reject,m.progress),g=m.promise);return n||d||f?g.then(n,d,f):g}})},"dojo/dom-attr":function(){define("exports ./sniff ./_base/lang ./dom ./dom-style ./dom-prop".split(" "),
function(b,l,g,n,d,f){function m(a,b){var d=a.getAttributeNode&&a.getAttributeNode(b);return!!d&&d.specified}var h={innerHTML:1,textContent:1,className:1,htmlFor:l("ie"),value:1},p={classname:"class",htmlfor:"for",tabindex:"tabIndex",readonly:"readOnly"};b.has=function(a,b){var d=b.toLowerCase();return h[f.names[d]||b]||m(n.byId(a),p[d]||b)};b.get=function(a,b){a=n.byId(a);var d=b.toLowerCase(),c=f.names[d]||b,l=a[c];if(h[c]&&"undefined"!=typeof l)return l;if("textContent"==c)return f.get(a,c);if("href"!=
c&&("boolean"==typeof l||g.isFunction(l)))return l;d=p[d]||b;return m(a,d)?a.getAttribute(d):null};b.set=function(a,e,k){a=n.byId(a);if(2==arguments.length){for(var c in e)b.set(a,c,e[c]);return a}c=e.toLowerCase();var m=f.names[c]||e,l=h[m];if("style"==m&&"string"!=typeof k)return d.set(a,k),a;if(l||"boolean"==typeof k||g.isFunction(k))return f.set(a,e,k);a.setAttribute(p[c]||e,k);return a};b.remove=function(a,b){n.byId(a).removeAttribute(p[b.toLowerCase()]||b)};b.getNodeProp=function(a,b){a=n.byId(a);
var d=b.toLowerCase(),c=f.names[d]||b;if(c in a&&"href"!=c)return a[c];d=p[d]||b;return m(a,d)?a.getAttribute(d):null}})},"dojo/dom-construct":function(){define("exports ./_base/kernel ./sniff ./_base/window ./dom ./dom-attr".split(" "),function(b,l,g,n,d,f){function m(a,b){var c=b.parentNode;c&&c.insertBefore(a,b)}function h(a){if("innerHTML"in a)try{a.innerHTML="";return}catch(b){}for(var c;c=a.lastChild;)a.removeChild(c)}var p={option:["select"],tbody:["table"],thead:["table"],tfoot:["table"],
tr:["table","tbody"],td:["table","tbody","tr"],th:["table","thead","tr"],legend:["fieldset"],caption:["table"],colgroup:["table"],col:["table","colgroup"],li:["ul"]},a=/<\s*([\w\:]+)/,e={},k=0,c="__"+l._scopeName+"ToDomId",t;for(t in p)p.hasOwnProperty(t)&&(l=p[t],l.pre="option"==t?'\x3cselect multiple\x3d"multiple"\x3e':"\x3c"+l.join("\x3e\x3c")+"\x3e",l.post="\x3c/"+l.reverse().join("\x3e\x3c/")+"\x3e");var q;8>=g("ie")&&(q=function(a){a.__dojo_html5_tested="yes";var b=r("div",{innerHTML:"\x3cnav\x3ea\x3c/nav\x3e",
style:{visibility:"hidden"}},a.body);1!==b.childNodes.length&&"abbr article aside audio canvas details figcaption figure footer header hgroup mark meter nav output progress section summary time video".replace(/\b\w+\b/g,function(b){a.createElement(b)});u(b)});b.toDom=function(b,d){d=d||n.doc;var f=d[c];f||(d[c]=f=++k+"",e[f]=d.createElement("div"));8>=g("ie")&&!d.__dojo_html5_tested&&d.body&&q(d);b+="";var h=b.match(a),m=h?h[1].toLowerCase():"",f=e[f];if(h&&p[m])for(h=p[m],f.innerHTML=h.pre+b+h.post,
h=h.length;h;--h)f=f.firstChild;else f.innerHTML=b;if(1==f.childNodes.length)return f.removeChild(f.firstChild);for(m=d.createDocumentFragment();h=f.firstChild;)m.appendChild(h);return m};b.place=function(a,c,e){c=d.byId(c);"string"==typeof a&&(a=/^\s*</.test(a)?b.toDom(a,c.ownerDocument):d.byId(a));if("number"==typeof e){var k=c.childNodes;!k.length||k.length<=e?c.appendChild(a):m(a,k[0>e?0:e])}else switch(e){case "before":m(a,c);break;case "after":e=a;(k=c.parentNode)&&(k.lastChild==c?k.appendChild(e):
k.insertBefore(e,c.nextSibling));break;case "replace":c.parentNode.replaceChild(a,c);break;case "only":b.empty(c);c.appendChild(a);break;case "first":if(c.firstChild){m(a,c.firstChild);break}default:c.appendChild(a)}return a};var r=b.create=function(a,c,e,k){var g=n.doc;e&&(e=d.byId(e),g=e.ownerDocument);"string"==typeof a&&(a=g.createElement(a));c&&f.set(a,c);e&&b.place(a,e,k);return a};b.empty=function(a){h(d.byId(a))};var u=b.destroy=function(a){if(a=d.byId(a)){var b=a;a=a.parentNode;b.firstChild&&
h(b);a&&(g("ie")&&a.canHaveChildren&&"removeNode"in b?b.removeNode(!1):a.removeChild(b))}}})},"dojo/request/xhr":function(){define(["../errors/RequestError","./watch","./handlers","./util","../has"],function(b,l,g,n,d){function f(a,c){var e=a.xhr;a.status=a.xhr.status;try{a.text=e.responseText}catch(d){}"xml"===a.options.handleAs&&(a.data=e.responseXML);if(!c)try{g(a)}catch(d){c=d}c?this.reject(c):n.checkStatus(e.status)?this.resolve(a):(c=new b("Unable to load "+a.url+" status: "+e.status,a),this.reject(c))}
function m(a){return this.xhr.getResponseHeader(a)}function h(g,u,v){var x=d("native-formdata")&&u&&u.data&&u.data instanceof FormData,y=n.parseArgs(g,n.deepCreate(q,u),x);g=y.url;u=y.options;var z,w=n.deferred(y,c,a,e,f,function(){z&&z()}),B=y.xhr=h._create();if(!B)return w.cancel(new b("XHR was not created")),v?w:w.promise;y.getHeader=m;k&&(z=k(B,w,y));var E=u.data,X=!u.sync,O=u.method;try{B.open(O,g,X,u.user||t,u.password||t);u.withCredentials&&(B.withCredentials=u.withCredentials);d("native-response-type")&&
u.handleAs in p&&(B.responseType=p[u.handleAs]);var Q=u.headers;g=x?!1:"application/x-www-form-urlencoded";if(Q)for(var P in Q)"content-type"===P.toLowerCase()?g=Q[P]:Q[P]&&B.setRequestHeader(P,Q[P]);g&&!1!==g&&B.setRequestHeader("Content-Type",g);Q&&"X-Requested-With"in Q||B.setRequestHeader("X-Requested-With","XMLHttpRequest");n.notify&&n.notify.emit("send",y,w.promise.cancel);B.send(E)}catch(S){w.reject(S)}l(w);B=null;return v?w:w.promise}d.add("native-xhr",function(){return"undefined"!==typeof XMLHttpRequest});
d.add("dojo-force-activex-xhr",function(){return d("activex")&&!document.addEventListener&&"file:"===window.location.protocol});d.add("native-xhr2",function(){if(d("native-xhr")){var a=new XMLHttpRequest;return"undefined"!==typeof a.addEventListener&&("undefined"===typeof opera||"undefined"!==typeof a.upload)}});d.add("native-formdata",function(){return"undefined"!==typeof FormData});d.add("native-response-type",function(){return d("native-xhr")&&"undefined"!==typeof(new XMLHttpRequest).responseType});
d.add("native-xhr2-blob",function(){if(d("native-response-type")){var a=new XMLHttpRequest;a.open("GET","/",!0);a.responseType="blob";var b=a.responseType;a.abort();return"blob"===b}});var p={blob:d("native-xhr2-blob")?"blob":"arraybuffer",document:"document",arraybuffer:"arraybuffer"},a,e,k,c;d("native-xhr2")?(a=function(a){return!this.isFulfilled()},c=function(a,b){b.xhr.abort()},k=function(a,c,e){function d(a){c.handleResponse(e)}function k(a){a=new b("Unable to load "+e.url+" status: "+a.target.status,
e);c.handleResponse(e,a)}function f(a){a.lengthComputable?(e.loaded=a.loaded,e.total=a.total,c.progress(e)):3===e.xhr.readyState&&(e.loaded=a.position,c.progress(e))}a.addEventListener("load",d,!1);a.addEventListener("error",k,!1);a.addEventListener("progress",f,!1);return function(){a.removeEventListener("load",d,!1);a.removeEventListener("error",k,!1);a.removeEventListener("progress",f,!1);a=null}}):(a=function(a){return a.xhr.readyState},e=function(a){return 4===a.xhr.readyState},c=function(a,
b){var c=b.xhr,e=typeof c.abort;"function"!==e&&"object"!==e&&"unknown"!==e||c.abort()});var t,q={data:null,query:null,sync:!1,method:"GET"};h._create=function(){throw Error("XMLHTTP not available");};if(d("native-xhr")&&!d("dojo-force-activex-xhr"))h._create=function(){return new XMLHttpRequest};else if(d("activex"))try{new ActiveXObject("Msxml2.XMLHTTP"),h._create=function(){return new ActiveXObject("Msxml2.XMLHTTP")}}catch(r){try{new ActiveXObject("Microsoft.XMLHTTP"),h._create=function(){return new ActiveXObject("Microsoft.XMLHTTP")}}catch(u){}}n.addCommonMethods(h);
return h})},"dojo/keys":function(){define(["./_base/kernel","./sniff"],function(b,l){return b.keys={BACKSPACE:8,TAB:9,CLEAR:12,ENTER:13,SHIFT:16,CTRL:17,ALT:18,META:l("webkit")?91:224,PAUSE:19,CAPS_LOCK:20,ESCAPE:27,SPACE:32,PAGE_UP:33,PAGE_DOWN:34,END:35,HOME:36,LEFT_ARROW:37,UP_ARROW:38,RIGHT_ARROW:39,DOWN_ARROW:40,INSERT:45,DELETE:46,HELP:47,LEFT_WINDOW:91,RIGHT_WINDOW:92,SELECT:93,NUMPAD_0:96,NUMPAD_1:97,NUMPAD_2:98,NUMPAD_3:99,NUMPAD_4:100,NUMPAD_5:101,NUMPAD_6:102,NUMPAD_7:103,NUMPAD_8:104,
NUMPAD_9:105,NUMPAD_MULTIPLY:106,NUMPAD_PLUS:107,NUMPAD_ENTER:108,NUMPAD_MINUS:109,NUMPAD_PERIOD:110,NUMPAD_DIVIDE:111,F1:112,F2:113,F3:114,F4:115,F5:116,F6:117,F7:118,F8:119,F9:120,F10:121,F11:122,F12:123,F13:124,F14:125,F15:126,NUM_LOCK:144,SCROLL_LOCK:145,UP_DPAD:175,DOWN_DPAD:176,LEFT_DPAD:177,RIGHT_DPAD:178,copyKey:l("mac")&&!l("air")?l("safari")?91:224:17}})},"dojo/domReady":function(){define(["./has"],function(b){function l(a){p.push(a);h&&g()}function g(){if(!a){for(a=!0;p.length;)try{p.shift()(d)}catch(b){}a=
!1;l._onQEmpty()}}var n=function(){return this}(),d=document,f={loaded:1,complete:1},m="string"!=typeof d.readyState,h=!!f[d.readyState],p=[],a;l.load=function(a,b,c){l(c)};l._Q=p;l._onQEmpty=function(){};m&&(d.readyState="loading");if(!h){var e=[],k=function(a){a=a||n.event;h||"readystatechange"==a.type&&!f[d.readyState]||(m&&(d.readyState="complete"),h=1,g())},c=function(a,b){a.addEventListener(b,k,!1);p.push(function(){a.removeEventListener(b,k,!1)})};if(!b("dom-addeventlistener")){var c=function(a,
b){b="on"+b;a.attachEvent(b,k);p.push(function(){a.detachEvent(b,k)})},t=d.createElement("div");try{t.doScroll&&null===n.frameElement&&e.push(function(){try{return t.doScroll("left"),1}catch(a){}})}catch(r){}}c(d,"DOMContentLoaded");c(n,"load");"onreadystatechange"in d?c(d,"readystatechange"):m||e.push(function(){return f[d.readyState]});if(e.length){var q=function(){if(!h){for(var a=e.length;a--;)if(e[a]()){k("poller");return}setTimeout(q,30)}};q()}}return l})},"dojo/_base/lang":function(){define(["./kernel",
"../has","../sniff"],function(b,l){l.add("bug-for-in-skips-shadowed",function(){for(var a in{toString:1})return 0;return 1});var g=l("bug-for-in-skips-shadowed")?"hasOwnProperty valueOf isPrototypeOf propertyIsEnumerable toLocaleString toString constructor".split(" "):[],n=g.length,d=function(a,e,d){d||(d=a[0]&&b.scopeMap[a[0]]?b.scopeMap[a.shift()][1]:b.global);try{for(var c=0;c<a.length;c++){var f=a[c];if(!(f in d))if(e)d[f]={};else return;d=d[f]}return d}catch(g){}},f=Object.prototype.toString,
m=function(a,b,d){return(d||[]).concat(Array.prototype.slice.call(a,b||0))},h=/\{([^\}]+)\}/g,p={_extraNames:g,_mixin:function(a,b,d){var c,f,h,m={};for(c in b)f=b[c],c in a&&(a[c]===f||c in m&&m[c]===f)||(a[c]=d?d(f):f);if(l("bug-for-in-skips-shadowed")&&b)for(h=0;h<n;++h)c=g[h],f=b[c],c in a&&(a[c]===f||c in m&&m[c]===f)||(a[c]=d?d(f):f);return a},mixin:function(a,b){a||(a={});for(var d=1,c=arguments.length;d<c;d++)p._mixin(a,arguments[d]);return a},setObject:function(a,b,k){var c=a.split(".");
a=c.pop();return(k=d(c,!0,k))&&a?k[a]=b:void 0},getObject:function(a,b,k){return d(a?a.split("."):[],b,k)},exists:function(a,b){return void 0!==p.getObject(a,!1,b)},isString:function(a){return"string"==typeof a||a instanceof String},isArray:function(a){return a&&(a instanceof Array||"array"==typeof a)},isFunction:function(a){return"[object Function]"===f.call(a)},isObject:function(a){return void 0!==a&&(null===a||"object"==typeof a||p.isArray(a)||p.isFunction(a))},isArrayLike:function(a){return a&&
void 0!==a&&!p.isString(a)&&!p.isFunction(a)&&!(a.tagName&&"form"==a.tagName.toLowerCase())&&(p.isArray(a)||isFinite(a.length))},isAlien:function(a){return a&&!p.isFunction(a)&&/\{\s*\[native code\]\s*\}/.test(String(a))},extend:function(a,b){for(var d=1,c=arguments.length;d<c;d++)p._mixin(a.prototype,arguments[d]);return a},_hitchArgs:function(a,e){var d=p._toArray(arguments,2),c=p.isString(e);return function(){var f=p._toArray(arguments),g=c?(a||b.global)[e]:e;return g&&g.apply(a||this,d.concat(f))}},
hitch:function(a,e){if(2<arguments.length)return p._hitchArgs.apply(b,arguments);e||(e=a,a=null);if(p.isString(e)){a=a||b.global;if(!a[e])throw['lang.hitch: scope["',e,'"] is null (scope\x3d"',a,'")'].join("");return function(){return a[e].apply(a,arguments||[])}}return a?function(){return e.apply(a,arguments||[])}:e},delegate:function(){function a(){}return function(b,d){a.prototype=b;var c=new a;a.prototype=null;d&&p._mixin(c,d);return c}}(),_toArray:l("ie")?function(){function a(a,b,c){c=c||[];
for(b=b||0;b<a.length;b++)c.push(a[b]);return c}return function(b){return(b.item?a:m).apply(this,arguments)}}():m,partial:function(a){return p.hitch.apply(b,[null].concat(p._toArray(arguments)))},clone:function(a){if(!a||"object"!=typeof a||p.isFunction(a))return a;if(a.nodeType&&"cloneNode"in a)return a.cloneNode(!0);if(a instanceof Date)return new Date(a.getTime());if(a instanceof RegExp)return new RegExp(a);var b,d,c;if(p.isArray(a))for(b=[],d=0,c=a.length;d<c;++d)d in a&&b.push(p.clone(a[d]));
else b=a.constructor?new a.constructor:{};return p._mixin(b,a,p.clone)},trim:String.prototype.trim?function(a){return a.trim()}:function(a){return a.replace(/^\s\s*/,"").replace(/\s\s*$/,"")},replace:function(a,b,d){return a.replace(d||h,p.isFunction(b)?b:function(a,d){return p.getObject(d,!1,b)})}};p.mixin(b,p);return p})},"dojo/request/util":function(){define("exports ../errors/RequestError ../errors/CancelError ../Deferred ../io-query ../_base/array ../_base/lang ../promise/Promise".split(" "),
function(b,l,g,n,d,f,m,h){function p(a){return e(a)}function a(a){return a.data||a.text}b.deepCopy=function(a,c){for(var e in c){var d=a[e],f=c[e];d!==f&&(d&&"object"===typeof d&&f&&"object"===typeof f?b.deepCopy(d,f):a[e]=f)}return a};b.deepCreate=function(a,c){c=c||{};var e=m.delegate(a),d,f;for(d in a)(f=a[d])&&"object"===typeof f&&(e[d]=b.deepCreate(f,c[d]));return b.deepCopy(e,c)};var e=Object.freeze||function(a){return a};b.deferred=function(d,c,f,q,r,u){var v=new n(function(a){c&&c(v,d);return a&&
(a instanceof l||a instanceof g)?a:new g("Request canceled",d)});v.response=d;v.isValid=f;v.isReady=q;v.handleResponse=r;f=v.then(p).otherwise(function(a){a.response=d;throw a;});b.notify&&f.then(m.hitch(b.notify,"emit","load"),m.hitch(b.notify,"emit","error"));q=f.then(a);r=new h;for(var x in q)q.hasOwnProperty(x)&&(r[x]=q[x]);r.response=f;e(r);u&&v.then(function(a){u.call(v,a)},function(a){u.call(v,d,a)});v.promise=r;v.then=r.then;return v};b.addCommonMethods=function(a,b){f.forEach(b||["GET","POST",
"PUT","DELETE"],function(b){a[("DELETE"===b?"DEL":b).toLowerCase()]=function(c,e){e=m.delegate(e||{});e.method=b;return a(c,e)}})};b.parseArgs=function(a,b,e){var f=b.data,g=b.query;f&&!e&&"object"===typeof f&&(b.data=d.objectToQuery(f));g?("object"===typeof g&&(g=d.objectToQuery(g)),b.preventCache&&(g+=(g?"\x26":"")+"request.preventCache\x3d"+ +new Date)):b.preventCache&&(g="request.preventCache\x3d"+ +new Date);a&&g&&(a+=(~a.indexOf("?")?"\x26":"?")+g);return{url:a,options:b,getHeader:function(a){return null}}};
b.checkStatus=function(a){a=a||0;return 200<=a&&300>a||304===a||1223===a||!a}})},"dojo/Evented":function(){define(["./aspect","./on"],function(b,l){function g(){}var n=b.after;g.prototype={on:function(b,f){return l.parse(this,b,f,function(b,d){return n(b,"on"+d,f,!0)})},emit:function(b,f){var g=[this];g.push.apply(g,arguments);return l.emit.apply(l,g)}};return g})},"dojo/mouse":function(){define(["./_base/kernel","./on","./has","./dom","./_base/window"],function(b,l,g,n,d){function f(b,d){var g=function(a,
e){return l(a,b,function(b){if(d)return d(b,e);if(!n.isDescendant(b.relatedTarget,a))return e.call(this,b)})};g.bubble=function(a){return f(b,function(b,d){var c=a(b.target),f=b.relatedTarget;if(c&&c!=(f&&1==f.nodeType&&a(f)))return d.call(c,b)})};return g}g.add("dom-quirks",d.doc&&"BackCompat"==d.doc.compatMode);g.add("events-mouseenter",d.doc&&"onmouseenter"in d.doc.createElement("div"));g.add("events-mousewheel",d.doc&&"onmousewheel"in d.doc);d=g("dom-quirks")&&g("ie")||!g("dom-addeventlistener")?
{LEFT:1,MIDDLE:4,RIGHT:2,isButton:function(b,d){return b.button&d},isLeft:function(b){return b.button&1},isMiddle:function(b){return b.button&4},isRight:function(b){return b.button&2}}:{LEFT:0,MIDDLE:1,RIGHT:2,isButton:function(b,d){return b.button==d},isLeft:function(b){return 0==b.button},isMiddle:function(b){return 1==b.button},isRight:function(b){return 2==b.button}};b.mouseButtons=d;b=g("events-mousewheel")?"mousewheel":function(b,d){return l(b,"DOMMouseScroll",function(b){b.wheelDelta=-b.detail;
d.call(this,b)})};return{_eventHandler:f,enter:f("mouseover"),leave:f("mouseout"),wheel:b,isLeft:d.isLeft,isMiddle:d.isMiddle,isRight:d.isRight}})},"dojo/topic":function(){define(["./Evented"],function(b){var l=new b;return{publish:function(b,n){return l.emit.apply(l,arguments)},subscribe:function(b,n){return l.on.apply(l,arguments)}}})},"dojo/_base/xhr":function(){define("./kernel ./sniff require ../io-query ../dom ../dom-form ./Deferred ./config ./json ./lang ./array ../on ../aspect ../request/watch ../request/xhr ../request/util".split(" "),
function(b,l,g,n,d,f,m,h,p,a,e,k,c,t,q,r){b._xhrObj=q._create;var u=b.config;b.objectToQuery=n.objectToQuery;b.queryToObject=n.queryToObject;b.fieldToObject=f.fieldToObject;b.formToObject=f.toObject;b.formToQuery=f.toQuery;b.formToJson=f.toJson;b._blockAsync=!1;var v=b._contentHandlers=b.contentHandlers={text:function(a){return a.responseText},json:function(a){return p.fromJson(a.responseText||null)},"json-comment-filtered":function(a){a=a.responseText;var b=a.indexOf("/*"),c=a.lastIndexOf("*/");
if(-1==b||-1==c)throw Error("JSON was not comment filtered");return p.fromJson(a.substring(b+2,c))},javascript:function(a){return b.eval(a.responseText)},xml:function(a){var b=a.responseXML;b&&l("dom-qsa2.1")&&!b.querySelectorAll&&l("dom-parser")&&(b=(new DOMParser).parseFromString(a.responseText,"application/xml"));if(l("ie")&&(!b||!b.documentElement)){var c=function(a){return"MSXML"+a+".DOMDocument"},c=["Microsoft.XMLDOM",c(6),c(4),c(3),c(2)];e.some(c,function(c){try{var e=new ActiveXObject(c);
e.async=!1;e.loadXML(a.responseText);b=e}catch(d){return!1}return!0})}return b},"json-comment-optional":function(a){return a.responseText&&/^[^{\[]*\/\*/.test(a.responseText)?v["json-comment-filtered"](a):v.json(a)}};b._ioSetArgs=function(c,e,k,g){var h={args:c,url:c.url},l=null;if(c.form){var l=d.byId(c.form),p=l.getAttributeNode("action");h.url=h.url||(p?p.value:null);l=f.toObject(l)}p=[{}];l&&p.push(l);c.content&&p.push(c.content);c.preventCache&&p.push({"dojo.preventCache":(new Date).valueOf()});
h.query=n.objectToQuery(a.mixin.apply(null,p));h.handleAs=c.handleAs||"text";var q=new m(function(a){a.canceled=!0;e&&e(a);var b=a.ioArgs.error;b||(b=Error("request cancelled"),b.dojoType="cancel",a.ioArgs.error=b);return b});q.addCallback(k);var r=c.load;r&&a.isFunction(r)&&q.addCallback(function(a){return r.call(c,a,h)});var t=c.error;t&&a.isFunction(t)&&q.addErrback(function(a){return t.call(c,a,h)});var v=c.handle;v&&a.isFunction(v)&&q.addBoth(function(a){return v.call(c,a,h)});q.addErrback(function(a){return g(a,
q)});u.ioPublish&&b.publish&&!1!==h.args.ioPublish&&(q.addCallbacks(function(a){b.publish("/dojo/io/load",[q,a]);return a},function(a){b.publish("/dojo/io/error",[q,a]);return a}),q.addBoth(function(a){b.publish("/dojo/io/done",[q,a]);return a}));q.ioArgs=h;return q};var x=function(a){a=v[a.ioArgs.handleAs](a.ioArgs.xhr);return void 0===a?null:a},y=function(a,b){return a},z=function(a){0>=w&&(w=0,u.ioPublish&&b.publish&&(!a||a&&!1!==a.ioArgs.args.ioPublish)&&b.publish("/dojo/io/stop"))},w=0;c.after(t,
"_onAction",function(){--w});c.after(t,"_onInFlight",z);b._ioCancelAll=t.cancelAll;b._ioNotifyStart=function(a){u.ioPublish&&b.publish&&!1!==a.ioArgs.args.ioPublish&&(w||b.publish("/dojo/io/start"),w+=1,b.publish("/dojo/io/send",[a]))};b._ioWatch=function(b,c,e,d){b.ioArgs.options=b.ioArgs.args;a.mixin(b,{response:b.ioArgs,isValid:function(a){return c(b)},isReady:function(a){return e(b)},handleResponse:function(a){return d(b)}});t(b);z(b)};b._ioAddQueryToUrl=function(a){a.query.length&&(a.url+=(-1==
a.url.indexOf("?")?"?":"\x26")+a.query,a.query=null)};b.xhr=function(a,c,e){var d,f=b._ioSetArgs(c,function(a){d&&d.cancel()},x,y),k=f.ioArgs;"postData"in c?k.query=c.postData:"putData"in c?k.query=c.putData:"rawBody"in c?k.query=c.rawBody:(2<arguments.length&&!e||-1==="POST|PUT".indexOf(a.toUpperCase()))&&b._ioAddQueryToUrl(k);var g={method:a,handleAs:"text",timeout:c.timeout,withCredentials:c.withCredentials,ioArgs:k};"undefined"!==typeof c.headers&&(g.headers=c.headers);"undefined"!==typeof c.contentType&&
(g.headers||(g.headers={}),g.headers["Content-Type"]=c.contentType);"undefined"!==typeof k.query&&(g.data=k.query);"undefined"!==typeof c.sync&&(g.sync=c.sync);b._ioNotifyStart(f);try{d=q(k.url,g,!0)}catch(h){return f.cancel(),f}f.ioArgs.xhr=d.response.xhr;d.then(function(){f.resolve(f)}).otherwise(function(a){k.error=a;a.response&&(a.status=a.response.status,a.responseText=a.response.text,a.xhr=a.response.xhr);f.reject(a)});return f};b.xhrGet=function(a){return b.xhr("GET",a)};b.rawXhrPost=b.xhrPost=
function(a){return b.xhr("POST",a,!0)};b.rawXhrPut=b.xhrPut=function(a){return b.xhr("PUT",a,!0)};b.xhrDelete=function(a){return b.xhr("DELETE",a)};b._isDocumentOk=function(a){return r.checkStatus(a.status)};b._getText=function(a){var c;b.xhrGet({url:a,sync:!0,load:function(a){c=a}});return c};a.mixin(b.xhr,{_xhrObj:b._xhrObj,fieldToObject:f.fieldToObject,formToObject:f.toObject,objectToQuery:n.objectToQuery,formToQuery:f.toQuery,formToJson:f.toJson,queryToObject:n.queryToObject,contentHandlers:v,
_ioSetArgs:b._ioSetArgs,_ioCancelAll:b._ioCancelAll,_ioNotifyStart:b._ioNotifyStart,_ioWatch:b._ioWatch,_ioAddQueryToUrl:b._ioAddQueryToUrl,_isDocumentOk:b._isDocumentOk,_getText:b._getText,get:b.xhrGet,post:b.xhrPost,put:b.xhrPut,del:b.xhrDelete});return b.xhr})},"dojo/_base/unload":function(){define(["./kernel","./lang","../on"],function(b,l,g){var n=window,d={addOnWindowUnload:function(d,m){b.windowUnloaded||g(n,"unload",b.windowUnloaded=function(){});g(n,"unload",l.hitch(d,m))},addOnUnload:function(b,
d){g(n,"beforeunload",l.hitch(b,d))}};b.addOnWindowUnload=d.addOnWindowUnload;b.addOnUnload=d.addOnUnload;return d})},"dojo/Deferred":function(){define(["./has","./_base/lang","./errors/CancelError","./promise/Promise","./promise/instrumentation"],function(b,l,g,n,d){var f=Object.freeze||function(){},m=function(a,b,d,f,g){2===b&&e.instrumentRejected&&0===a.length&&e.instrumentRejected(d,!1,f,g);for(g=0;g<a.length;g++)h(a[g],b,d,f)},h=function(b,c,d,f){var g=b[c],h=b.deferred;if(g)try{var m=g(d);if(0===
c)"undefined"!==typeof m&&a(h,c,m);else{if(m&&"function"===typeof m.then){b.cancel=m.cancel;m.then(p(h,1),p(h,2),p(h,0));return}a(h,1,m)}}catch(l){a(h,2,l)}else a(h,c,d);2===c&&e.instrumentRejected&&e.instrumentRejected(d,!!g,f,h.promise)},p=function(b,c){return function(e){a(b,c,e)}},a=function(a,b,e){if(!a.isCanceled())switch(b){case 0:a.progress(e);break;case 1:a.resolve(e);break;case 2:a.reject(e)}},e=function(a){var b=this.promise=new n,d=this,l,p,u,v=!1,x=[];Error.captureStackTrace&&(Error.captureStackTrace(d,
e),Error.captureStackTrace(b,e));this.isResolved=b.isResolved=function(){return 1===l};this.isRejected=b.isRejected=function(){return 2===l};this.isFulfilled=b.isFulfilled=function(){return!!l};this.isCanceled=b.isCanceled=function(){return v};this.progress=function(a,e){if(l){if(!0===e)throw Error("This deferred has already been fulfilled.");return b}m(x,0,a,null,d);return b};this.resolve=function(a,e){if(l){if(!0===e)throw Error("This deferred has already been fulfilled.");return b}m(x,l=1,p=a,
null,d);x=null;return b};var y=this.reject=function(a,e){if(l){if(!0===e)throw Error("This deferred has already been fulfilled.");return b}Error.captureStackTrace&&Error.captureStackTrace(u={},y);m(x,l=2,p=a,u,d);x=null;return b};this.then=b.then=function(a,d,f){var g=[f,a,d];g.cancel=b.cancel;g.deferred=new e(function(a){return g.cancel&&g.cancel(a)});l&&!x?h(g,l,p,u):x.push(g);return g.deferred.promise};this.cancel=b.cancel=function(b,c){if(!l){if(a){var e=a(b);b="undefined"===typeof e?b:e}v=!0;
if(!l)return"undefined"===typeof b&&(b=new g),y(b),b;if(2===l&&p===b)return b}else if(!0===c)throw Error("This deferred has already been fulfilled.");};f(b)};e.prototype.toString=function(){return"[object Deferred]"};d&&d(e);return e})},"dojo/_base/NodeList":function(){define(["./kernel","../query","./array","./html","../NodeList-dom"],function(b,l,g){l=l.NodeList;var n=l.prototype;n.connect=l._adaptAsForEach(function(){return b.connect.apply(this,arguments)});n.coords=l._adaptAsMap(b.coords);l.events=
"blur focus change click error keydown keypress keyup load mousedown mouseenter mouseleave mousemove mouseout mouseover mouseup submit".split(" ");g.forEach(l.events,function(b){var f="on"+b;n[f]=function(b,d){return this.connect(f,b,d)}});return b.NodeList=l})},"dojo/_base/Color":function(){define(["./kernel","./lang","./array","./config"],function(b,l,g,n){var d=b.Color=function(b){b&&this.setColor(b)};d.named={black:[0,0,0],silver:[192,192,192],gray:[128,128,128],white:[255,255,255],maroon:[128,
0,0],red:[255,0,0],purple:[128,0,128],fuchsia:[255,0,255],green:[0,128,0],lime:[0,255,0],olive:[128,128,0],yellow:[255,255,0],navy:[0,0,128],blue:[0,0,255],teal:[0,128,128],aqua:[0,255,255],transparent:n.transparentColor||[0,0,0,0]};l.extend(d,{r:255,g:255,b:255,a:1,_set:function(b,d,g,l){this.r=b;this.g=d;this.b=g;this.a=l},setColor:function(b){l.isString(b)?d.fromString(b,this):l.isArray(b)?d.fromArray(b,this):(this._set(b.r,b.g,b.b,b.a),b instanceof d||this.sanitize());return this},sanitize:function(){return this},
toRgb:function(){return[this.r,this.g,this.b]},toRgba:function(){return[this.r,this.g,this.b,this.a]},toHex:function(){return"#"+g.map(["r","g","b"],function(b){b=this[b].toString(16);return 2>b.length?"0"+b:b},this).join("")},toCss:function(b){var d=this.r+", "+this.g+", "+this.b;return(b?"rgba("+d+", "+this.a:"rgb("+d)+")"},toString:function(){return this.toCss(!0)}});d.blendColors=b.blendColors=function(b,m,h,l){var a=l||new d;g.forEach(["r","g","b","a"],function(e){a[e]=b[e]+(m[e]-b[e])*h;"a"!=
e&&(a[e]=Math.round(a[e]))});return a.sanitize()};d.fromRgb=b.colorFromRgb=function(b,g){var h=b.toLowerCase().match(/^rgba?\(([\s\.,0-9]+)\)/);return h&&d.fromArray(h[1].split(/\s*,\s*/),g)};d.fromHex=b.colorFromHex=function(b,m){var h=m||new d,l=4==b.length?4:8,a=(1<<l)-1;b=Number("0x"+b.substr(1));if(isNaN(b))return null;g.forEach(["b","g","r"],function(e){var d=b&a;b>>=l;h[e]=4==l?17*d:d});h.a=1;return h};d.fromArray=b.colorFromArray=function(b,g){var h=g||new d;h._set(Number(b[0]),Number(b[1]),
Number(b[2]),Number(b[3]));isNaN(h.a)&&(h.a=1);return h.sanitize()};d.fromString=b.colorFromString=function(b,g){var h=d.named[b];return h&&d.fromArray(h,g)||d.fromRgb(b,g)||d.fromHex(b,g)};return d})},"dojo/promise/instrumentation":function(){define(["./tracer","../has","../_base/lang","../_base/array"],function(b,l,g,n){function d(a,b,e){b&&b.stack&&b.stack.split("\n").slice(1).join("\n").replace(/^\s+/," ")}function f(a,b,e,f){b||d(a,e,f)}function m(b,c,d,f){n.some(p,function(a){if(a.error===b)return c&&
(a.handled=!0),!0})||p.push({error:b,rejection:d,handled:c,deferred:f,timestamp:(new Date).getTime()});a||(a=setTimeout(h,e))}function h(){var b=(new Date).getTime(),c=b-e;p=n.filter(p,function(a){return a.timestamp<c?(a.handled||d(a.error,a.rejection,a.deferred),!1):!0});a=p.length?setTimeout(h,p[0].timestamp+e-b):!1}l.add("config-useDeferredInstrumentation","report-unhandled-rejections");var p=[],a=!1,e=1E3;return function(a){var c=l("config-useDeferredInstrumentation");if(c){b.on("resolved",g.hitch(console,
"log","resolved"));b.on("rejected",g.hitch(console,"log","rejected"));b.on("progress",g.hitch(console,"log","progress"));var d=[];"string"===typeof c&&(d=c.split(","),c=d.shift());if("report-rejections"===c)a.instrumentRejected=f;else if("report-unhandled-rejections"===c||!0===c||1===c)a.instrumentRejected=m,e=parseInt(d[0],10)||e;else throw Error("Unsupported instrumentation usage \x3c"+c+"\x3e");}}})},"dojo/selector/_loader":function(){define(["../has","require"],function(b,l){var g=document.createElement("div");
b.add("dom-qsa2.1",!!g.querySelectorAll);b.add("dom-qsa3",function(){try{return g.innerHTML="\x3cp class\x3d'TEST'\x3e\x3c/p\x3e",1==g.querySelectorAll(".TEST:empty").length}catch(b){}});var n;return{load:function(d,f,g,h){h=l;d="default"==d?b("config-selectorEngine")||"css3":d;d="css2"==d||"lite"==d?"./lite":"css2.1"==d?b("dom-qsa2.1")?"./lite":"./acme":"css3"==d?b("dom-qsa3")?"./lite":"./acme":"acme"==d?"./acme":(h=f)&&d;if("?"==d.charAt(d.length-1)){d=d.substring(0,d.length-1);var p=!0}if(p&&(b("dom-compliant-qsa")||
n))return g(n);h([d],function(a){"./lite"!=d&&(n=a);g(a)})}}})},"dojo/promise/Promise":function(){define(["../_base/lang"],function(b){function l(){throw new TypeError("abstract");}return b.extend(function(){},{then:function(b,n,d){l()},cancel:function(b,n){l()},isResolved:function(){l()},isRejected:function(){l()},isFulfilled:function(){l()},isCanceled:function(){l()},always:function(b){return this.then(b,b)},otherwise:function(b){return this.then(null,b)},trace:function(){return this},traceRejected:function(){return this},
toString:function(){return"[object Promise]"}})})},"dojo/request/watch":function(){define("./util ../errors/RequestTimeoutError ../errors/CancelError ../_base/array ../_base/window ../has!host-browser?dom-addeventlistener?:../on:".split(" "),function(b,l,g,n,d,f){function m(){for(var b=+new Date,d=0,c;d<a.length&&(c=a[d]);d++){var f=c.response,g=f.options;c.isCanceled&&c.isCanceled()||c.isValid&&!c.isValid(f)?(a.splice(d--,1),h._onAction&&h._onAction()):c.isReady&&c.isReady(f)?(a.splice(d--,1),c.handleResponse(f),
h._onAction&&h._onAction()):c.startTime&&c.startTime+(g.timeout||0)<b&&(a.splice(d--,1),c.cancel(new l("Timeout exceeded",f)),h._onAction&&h._onAction())}h._onInFlight&&h._onInFlight(c);a.length||(clearInterval(p),p=null)}function h(b){b.response.options.timeout&&(b.startTime=+new Date);b.isFulfilled()||(a.push(b),p||(p=setInterval(m,50)),b.response.options.sync&&m())}var p=null,a=[];h.cancelAll=function(){try{n.forEach(a,function(a){try{a.cancel(new g("All requests canceled."))}catch(b){}})}catch(b){}};
d&&f&&d.doc.attachEvent&&f(d.global,"unload",function(){h.cancelAll()});return h})},"dojo/on":function(){define(["./has!dom-addeventlistener?:./aspect","./_base/kernel","./sniff"],function(b,l,g){function n(a,b,c,d,e){if(d=b.match(/(.*):(.*)/))return b=d[2],d=d[1],h.selector(d,b).call(e,a,c);g("touch")&&(p.test(b)&&(c=B(c)),g("event-orientationchange")||"orientationchange"!=b||(b="resize",a=window,c=B(c)));t&&(c=t(c));if(a.addEventListener){var f=b in k,m=f?k[b]:b;a.addEventListener(m,c,f);return{remove:function(){a.removeEventListener(m,
c,f)}}}if(v&&a.attachEvent)return v(a,"on"+b,c);throw Error("Target must be an event emitter");}function d(){this.cancelable=!1;this.defaultPrevented=!0}function f(){this.bubbles=!1}var m=window.ScriptEngineMajorVersion;g.add("jscript",m&&m()+ScriptEngineMinorVersion()/10);g.add("event-orientationchange",g("touch")&&!g("android"));g.add("event-stopimmediatepropagation",window.Event&&!!window.Event.prototype&&!!window.Event.prototype.stopImmediatePropagation);g.add("event-focusin",function(a,b,c){return"onfocusin"in
c});g("touch")&&g.add("touch-can-modify-event-delegate",function(){var a=function(){};a.prototype=document.createEvent("MouseEvents");try{var b=new a;b.target=null;return null===b.target}catch(c){return!1}});var h=function(a,b,c,d){return"function"!=typeof a.on||"function"==typeof b||a.nodeType?h.parse(a,b,c,n,d,this):a.on(b,c)};h.pausable=function(a,b,c,d){var e;a=h(a,b,function(){if(!e)return c.apply(this,arguments)},d);a.pause=function(){e=!0};a.resume=function(){e=!1};return a};h.once=function(a,
b,c,d){var e=h(a,b,function(){e.remove();return c.apply(this,arguments)});return e};h.parse=function(a,b,c,d,e,f){if(b.call)return b.call(f,a,c);if(b instanceof Array)g=b;else if(-1<b.indexOf(","))var g=b.split(/\s*,\s*/);if(g){var k=[];b=0;for(var m;m=g[b++];)k.push(h.parse(a,m,c,d,e,f));k.remove=function(){for(var a=0;a<k.length;a++)k[a].remove()};return k}return d(a,b,c,e,f)};var p=/^touch/;h.matches=function(a,b,c,d,e){e=e&&e.matches?e:l.query;d=!1!==d;1!=a.nodeType&&(a=a.parentNode);for(;!e.matches(a,
b,c);)if(a==c||!1===d||!(a=a.parentNode)||1!=a.nodeType)return!1;return a};h.selector=function(a,b,c){return function(d,e){function f(b){return h.matches(b,a,d,c,g)}var g="function"==typeof a?{matches:a}:this,k=b.bubble;return k?h(d,k(f),e):h(d,b,function(a){var b=f(a.target);if(b)return e.call(b,a)})}};var a=[].slice,e=h.emit=function(b,c,e){var g=a.call(arguments,2),k="on"+c;if("parentNode"in b){var h=g[0]={},m;for(m in e)h[m]=e[m];h.preventDefault=d;h.stopPropagation=f;h.target=b;h.type=c;e=h}do b[k]&&
b[k].apply(b,g);while(e&&e.bubbles&&(b=b.parentNode));return e&&e.cancelable&&e},k=g("event-focusin")?{}:{focusin:"focus",focusout:"blur"};if(!g("event-stopimmediatepropagation"))var c=function(){this.modified=this.immediatelyStopped=!0},t=function(a){return function(b){if(!b.immediatelyStopped)return b.stopImmediatePropagation=c,a.apply(this,arguments)}};if(g("dom-addeventlistener"))h.emit=function(a,b,c){if(a.dispatchEvent&&document.createEvent){var d=(a.ownerDocument||document).createEvent("HTMLEvents");
d.initEvent(b,!!c.bubbles,!!c.cancelable);for(var f in c)f in d||(d[f]=c[f]);return a.dispatchEvent(d)&&d}return e.apply(h,arguments)};else{h._fixEvent=function(a,b){a||(a=(b&&(b.ownerDocument||b.document||b).parentWindow||window).event);if(!a)return a;try{q&&a.type==q.type&&a.srcElement==q.target&&(a=q)}catch(c){}if(!a.target)switch(a.target=a.srcElement,a.currentTarget=b||a.srcElement,"mouseover"==a.type&&(a.relatedTarget=a.fromElement),"mouseout"==a.type&&(a.relatedTarget=a.toElement),a.stopPropagation||
(a.stopPropagation=x,a.preventDefault=y),a.type){case "keypress":var d="charCode"in a?a.charCode:a.keyCode;10==d?(d=0,a.keyCode=13):13==d||27==d?d=0:3==d&&(d=99);a.charCode=d;d=a;d.keyChar=d.charCode?String.fromCharCode(d.charCode):"";d.charOrCode=d.keyChar||d.keyCode}return a};var q,r=function(a){this.handle=a};r.prototype.remove=function(){delete _dojoIEListeners_[this.handle]};var u=function(a){return function(b){b=h._fixEvent(b,this);var c=a.call(this,b);b.modified&&(q||setTimeout(function(){q=
null}),q=b);return c}},v=function(a,c,d){d=u(d);if(((a.ownerDocument?a.ownerDocument.parentWindow:a.parentWindow||a.window||window)!=top||5.8>g("jscript"))&&!g("config-_allow_leaks")){"undefined"==typeof _dojoIEListeners_&&(_dojoIEListeners_=[]);var e=a[c];if(!e||!e.listeners){var f=e,e=Function("event","var callee \x3d arguments.callee; for(var i \x3d 0; i\x3ccallee.listeners.length; i++){var listener \x3d _dojoIEListeners_[callee.listeners[i]]; if(listener){listener.call(this,event);}}");e.listeners=
[];a[c]=e;e.global=this;f&&e.listeners.push(_dojoIEListeners_.push(f)-1)}e.listeners.push(a=e.global._dojoIEListeners_.push(d)-1);return new r(a)}return b.after(a,c,d,!0)},x=function(){this.cancelBubble=!0},y=h._preventDefault=function(){this.bubbledKeyCode=this.keyCode;if(this.ctrlKey)try{this.keyCode=0}catch(a){}this.defaultPrevented=!0;this.returnValue=!1;this.modified=!0}}if(g("touch"))var z=function(){},w=window.orientation,B=function(a){return function(b){var c=b.corrected;if(!c){var d=b.type;
try{delete b.type}catch(e){}if(b.type){if(g("touch-can-modify-event-delegate"))z.prototype=b,c=new z;else{var c={},f;for(f in b)c[f]=b[f]}c.preventDefault=function(){b.preventDefault()};c.stopPropagation=function(){b.stopPropagation()}}else c=b,c.type=d;b.corrected=c;if("resize"==d){if(w==window.orientation)return null;w=window.orientation;c.type="orientationchange";return a.call(this,c)}"rotation"in c||(c.rotation=0,c.scale=1);var d=c.changedTouches[0],k;for(k in d)delete c[k],c[k]=d[k]}return a.call(this,
c)}};return h})},"dojo/_base/sniff":function(){define(["./kernel","./lang","../sniff"],function(b,l,g){b._name="browser";l.mixin(b,{isBrowser:!0,isFF:g("ff"),isIE:g("ie"),isKhtml:g("khtml"),isWebKit:g("webkit"),isMozilla:g("mozilla"),isMoz:g("mozilla"),isOpera:g("opera"),isSafari:g("safari"),isChrome:g("chrome"),isMac:g("mac"),isIos:g("ios"),isAndroid:g("android"),isWii:g("wii"),isQuirks:g("quirks"),isAir:g("air")});return g})},"dojo/errors/create":function(){define(["../_base/lang"],function(b){return function(l,
g,n,d){n=n||Error;var f=function(b){if(n===Error){Error.captureStackTrace&&Error.captureStackTrace(this,f);var d=Error.call(this,b),l;for(l in d)d.hasOwnProperty(l)&&(this[l]=d[l]);this.message=b;this.stack=d.stack}else n.apply(this,arguments);g&&g.apply(this,arguments)};f.prototype=b.delegate(n.prototype,d);f.prototype.name=l;return f.prototype.constructor=f}})},"dojo/_base/array":function(){define(["./kernel","../has","./lang"],function(b,l,g){function n(a){return m[a]=new Function("item","index",
"array",a)}function d(a){var b=!a;return function(d,c,f){var g=0,h=d&&d.length||0,l;h&&"string"==typeof d&&(d=d.split(""));"string"==typeof c&&(c=m[c]||n(c));if(f)for(;g<h;++g){if(l=!c.call(f,d[g],g,d),a^l)return!l}else for(;g<h;++g)if(l=!c(d[g],g,d),a^l)return!l;return b}}function f(a){var b=1,d=0,c=0;a||(b=d=c=-1);return function(f,g,m,l){if(l&&0<b)return p.lastIndexOf(f,g,m);l=f&&f.length||0;var n=a?l+c:d;m===h?m=a?d:l+c:0>m?(m=l+m,0>m&&(m=d)):m=m>=l?l+c:m;for(l&&"string"==typeof f&&(f=f.split(""));m!=
n;m+=b)if(f[m]==g)return m;return-1}}var m={},h,p={every:d(!1),some:d(!0),indexOf:f(!0),lastIndexOf:f(!1),forEach:function(a,b,d){var c=0,f=a&&a.length||0;f&&"string"==typeof a&&(a=a.split(""));"string"==typeof b&&(b=m[b]||n(b));if(d)for(;c<f;++c)b.call(d,a[c],c,a);else for(;c<f;++c)b(a[c],c,a)},map:function(a,b,d,c){var f=0,g=a&&a.length||0;c=new (c||Array)(g);g&&"string"==typeof a&&(a=a.split(""));"string"==typeof b&&(b=m[b]||n(b));if(d)for(;f<g;++f)c[f]=b.call(d,a[f],f,a);else for(;f<g;++f)c[f]=
b(a[f],f,a);return c},filter:function(a,b,d){var c=0,f=a&&a.length||0,g=[],h;f&&"string"==typeof a&&(a=a.split(""));"string"==typeof b&&(b=m[b]||n(b));if(d)for(;c<f;++c)h=a[c],b.call(d,h,c,a)&&g.push(h);else for(;c<f;++c)h=a[c],b(h,c,a)&&g.push(h);return g},clearCache:function(){m={}}};g.mixin(b,p);return p})},"dojo/_base/json":function(){define(["./kernel","../json"],function(b,l){b.fromJson=function(b){return eval("("+b+")")};b._escapeString=l.stringify;b.toJsonIndentStr="\t";b.toJson=function(g,
n){return l.stringify(g,function(b,f){if(f){var g=f.__json__||f.json;if("function"==typeof g)return g.call(f)}return f},n&&b.toJsonIndentStr)};return b})},"dojo/_base/window":function(){define(["./kernel","./lang","../sniff"],function(b,l,g){var n={global:b.global,doc:b.global.document||null,body:function(d){d=d||b.doc;return d.body||d.getElementsByTagName("body")[0]},setContext:function(d,f){b.global=n.global=d;b.doc=n.doc=f},withGlobal:function(d,f,g,h){var l=b.global;try{return b.global=n.global=
d,n.withDoc.call(null,d.document,f,g,h)}finally{b.global=n.global=l}},withDoc:function(d,f,m,h){var l=n.doc,a=g("quirks"),e=g("ie"),k,c,t;try{return b.doc=n.doc=d,b.isQuirks=g.add("quirks","BackCompat"==b.doc.compatMode,!0,!0),g("ie")&&(t=d.parentWindow)&&t.navigator&&(k=parseFloat(t.navigator.appVersion.split("MSIE ")[1])||void 0,(c=d.documentMode)&&5!=c&&Math.floor(k)!=c&&(k=c),b.isIE=g.add("ie",k,!0,!0)),m&&"string"==typeof f&&(f=m[f]),f.apply(m,h||[])}finally{b.doc=n.doc=l,b.isQuirks=g.add("quirks",
a,!0,!0),b.isIE=g.add("ie",e,!0,!0)}}};l.mixin(b,n);return n})},"dojo/dom-class":function(){define(["./_base/lang","./_base/array","./dom"],function(b,l,g){function n(b){if("string"==typeof b||b instanceof String){if(b&&!f.test(b))return m[0]=b,m;b=b.split(f);b.length&&!b[0]&&b.shift();b.length&&!b[b.length-1]&&b.pop();return b}return b?l.filter(b,function(a){return a}):[]}var d,f=/\s+/,m=[""],h={};return d={contains:function(b,a){return 0<=(" "+g.byId(b).className+" ").indexOf(" "+a+" ")},add:function(b,
a){b=g.byId(b);a=n(a);var d=b.className,f,d=d?" "+d+" ":" ";f=d.length;for(var c=0,h=a.length,m;c<h;++c)(m=a[c])&&0>d.indexOf(" "+m+" ")&&(d+=m+" ");f<d.length&&(b.className=d.substr(1,d.length-2))},remove:function(d,a){d=g.byId(d);var e;if(void 0!==a){a=n(a);e=" "+d.className+" ";for(var f=0,c=a.length;f<c;++f)e=e.replace(" "+a[f]+" "," ");e=b.trim(e)}else e="";d.className!=e&&(d.className=e)},replace:function(b,a,e){b=g.byId(b);h.className=b.className;d.remove(h,e);d.add(h,a);b.className!==h.className&&
(b.className=h.className)},toggle:function(b,a,e){b=g.byId(b);if(void 0===e){a=n(a);for(var f=0,c=a.length,h;f<c;++f)h=a[f],d[d.contains(b,h)?"remove":"add"](b,h)}else d[e?"add":"remove"](b,a);return e}}})},"dojo/_base/config":function(){define(["../has","require"],function(b,l){var g={},n=l.rawConfig,d;for(d in n)g[d]=n[d];!g.locale&&"undefined"!=typeof navigator&&(n=navigator.language||navigator.userLanguage)&&(g.locale=n.toLowerCase());return g})},"dojo/_base/event":function(){define(["./kernel",
"../on","../has","../dom-geometry"],function(b,l,g,n){if(l._fixEvent){var d=l._fixEvent;l._fixEvent=function(b,f){(b=d(b,f))&&n.normalizeEvent(b);return b}}var f={fix:function(b,d){return l._fixEvent?l._fixEvent(b,d):b},stop:function(b){g("dom-addeventlistener")||b&&b.preventDefault?(b.preventDefault(),b.stopPropagation()):(b=b||window.event,b.cancelBubble=!0,l._preventDefault.call(b))}};b.fixEvent=f.fix;b.stopEvent=f.stop;return f})},"dojo/main":function(){define("./_base/kernel ./has require ./sniff ./_base/lang ./_base/array ./_base/config ./ready ./_base/declare ./_base/connect ./_base/Deferred ./_base/json ./_base/Color ./has!dojo-firebug?./_firebug/firebug ./_base/browser ./_base/loader".split(" "),
function(b,l,g,n,d,f,m,h){m.isDebug&&g(["./_firebug/firebug"]);var p=m.require;p&&(p=f.map(d.isArray(p)?p:[p],function(a){return a.replace(/\./g,"/")}),b.isAsync?g(p):h(1,function(){g(p)}));return b})},"dojo/sniff":function(){define(["./has"],function(b){var l=navigator,g=l.userAgent,l=l.appVersion,n=parseFloat(l);b.add("air",0<=g.indexOf("AdobeAIR"));b.add("msapp",parseFloat(g.split("MSAppHost/")[1])||void 0);b.add("khtml",0<=l.indexOf("Konqueror")?n:void 0);b.add("webkit",parseFloat(g.split("WebKit/")[1])||
void 0);b.add("chrome",parseFloat(g.split("Chrome/")[1])||void 0);b.add("safari",0<=l.indexOf("Safari")&&!b("chrome")?parseFloat(l.split("Version/")[1]):void 0);b.add("mac",0<=l.indexOf("Macintosh"));b.add("quirks","BackCompat"==document.compatMode);if(g.match(/(iPhone|iPod|iPad)/)){var d=RegExp.$1.replace(/P/,"p"),f=g.match(/OS ([\d_]+)/)?RegExp.$1:"1",f=parseFloat(f.replace(/_/,".").replace(/_/g,""));b.add(d,f);b.add("ios",f)}b.add("android",parseFloat(g.split("Android ")[1])||void 0);b.add("bb",
(0<=g.indexOf("BlackBerry")||0<=g.indexOf("BB10"))&&parseFloat(g.split("Version/")[1])||void 0);b.add("trident",parseFloat(l.split("Trident/")[1])||void 0);b.add("svg","undefined"!==typeof SVGAngle);b("webkit")||(0<=g.indexOf("Opera")&&b.add("opera",9.8<=n?parseFloat(g.split("Version/")[1])||n:n),0<=g.indexOf("Gecko")&&!b("khtml")&&!b("webkit")&&!b("trident")&&b.add("mozilla",n),b("mozilla")&&b.add("ff",parseFloat(g.split("Firefox/")[1]||g.split("Minefield/")[1])||void 0),document.all&&!b("opera")&&
(g=parseFloat(l.split("MSIE ")[1])||void 0,(l=document.documentMode)&&5!=l&&Math.floor(g)!=l&&(g=l),b.add("ie",g)),b.add("wii","undefined"!=typeof opera&&opera.wiiremote));return b})},"dojo/request/handlers":function(){define(["../json","../_base/kernel","../_base/array","../has","../selector/_loader"],function(b,l,g,n){function d(b){var d=a[b.options.handleAs];b.data=d?d(b):b.data||b.text;return b}n.add("activex","undefined"!==typeof ActiveXObject);n.add("dom-parser",function(a){return"DOMParser"in
a});var f;if(n("activex")){var m=["Msxml2.DOMDocument.6.0","Msxml2.DOMDocument.4.0","MSXML2.DOMDocument.3.0","MSXML.DOMDocument"],h;f=function(a){function b(a){try{var e=new ActiveXObject(a);e.async=!1;e.loadXML(d);c=e;h=a}catch(f){return!1}return!0}var c=a.data,d=a.text;c&&n("dom-qsa2.1")&&!c.querySelectorAll&&n("dom-parser")&&(c=(new DOMParser).parseFromString(d,"application/xml"));c&&c.documentElement||h&&b(h)||g.some(m,b);return c}}var p=function(a){return n("native-xhr2-blob")||"blob"!==a.options.handleAs||
"undefined"===typeof Blob?a.xhr.response:new Blob([a.xhr.response],{type:a.xhr.getResponseHeader("Content-Type")})},a={javascript:function(a){return l.eval(a.text||"")},json:function(a){return b.parse(a.text||null)},xml:f,blob:p,arraybuffer:p,document:p};d.register=function(b,d){a[b]=d};return d})},"dojo/ready":function(){define(["./_base/kernel","./has","require","./domReady","./_base/lang"],function(b,l,g,n,d){var f=0,m=[],h=0;l=function(){f=1;b._postLoad=b.config.afterOnLoad=!0;p()};var p=function(){if(!h){for(h=
1;f&&(!n||0==n._Q.length)&&(g.idle?g.idle():1)&&m.length;){var a=m.shift();try{a()}catch(b){if(b.info=b.message,g.signal)g.signal("error",b);else throw b;}}h=0}};g.on&&g.on("idle",p);n&&(n._onQEmpty=p);var a=b.ready=b.addOnLoad=function(a,c,e){var f=d._toArray(arguments);"number"!=typeof a?(e=c,c=a,a=1E3):f.shift();e=e?d.hitch.apply(b,f):function(){c()};e.priority=a;for(f=0;f<m.length&&a>=m[f].priority;f++);m.splice(f,0,e);p()},e=b.config.addOnLoad;if(e)a[d.isArray(e)?"apply":"call"](b,e);b.config.parseOnLoad&&
!b.isAsync&&a(99,function(){b.parser||(b.deprecated("Add explicit require(['dojo/parser']);","","2.0"),g(["dojo/parser"]))});n?n(l):l();return a})},"dojo/aspect":function(){define([],function(){function b(b,d,a,e){var f=b[d],c="around"==d,g;if(c){var m=a(function(){return f.advice(this,arguments)});g={remove:function(){m&&(m=b=a=null)},advice:function(a,b){return m?m.apply(a,b):f.advice(a,b)}}}else g={remove:function(){if(g.advice){var c=g.previous,e=g.next;e||c?(c?c.next=e:b[d]=e,e&&(e.previous=
c)):delete b[d];b=a=g.advice=null}},id:n++,advice:a,receiveArguments:e};if(f&&!c)if("after"==d){for(;f.next&&(f=f.next););f.next=g;g.previous=f}else"before"==d&&(b[d]=g,g.next=f,f.previous=g);else b[d]=g;return g}function l(d){return function(f,a,e,k){var c=f[a],m;c&&c.target==f||(f[a]=m=function(){for(var a=n,b=arguments,c=m.before;c;)b=c.advice.apply(this,b)||b,c=c.next;if(m.around)var d=m.around.advice(this,b);for(c=m.after;c&&c.id<a;){if(c.receiveArguments)var e=c.advice.apply(this,b),d=e===g?
d:e;else d=c.advice.call(this,d,b);c=c.next}return d},c&&(m.around={advice:function(a,b){return c.apply(a,b)}}),m.target=f);f=b(m||c,d,e,k);e=null;return f}}var g,n=0,d=l("after"),f=l("before"),m=l("around");return{before:f,around:m,after:d}})},"dojo/_base/connect":function(){define("./kernel ../on ../topic ../aspect ./event ../mouse ./sniff ./lang ../keys".split(" "),function(b,l,g,n,d,f,m,h){function p(a,c,d,e,g){e=h.hitch(d,e);if(!a||!a.addEventListener&&!a.attachEvent)return n.after(a||b.global,
c,e,!0);"string"==typeof c&&"on"==c.substring(0,2)&&(c=c.substring(2));a||(a=b.global);if(!g)switch(c){case "keypress":c=t;break;case "mouseenter":c=f.enter;break;case "mouseleave":c=f.leave}return l(a,c,e,g)}function a(a){a.keyChar=a.charCode?String.fromCharCode(a.charCode):"";a.charOrCode=a.keyChar||a.keyCode}m.add("events-keypress-typed",function(){var a={charCode:0};try{a=document.createEvent("KeyboardEvent"),(a.initKeyboardEvent||a.initKeyEvent).call(a,"keypress",!0,!0,null,!1,!1,!1,!1,9,3)}catch(b){}return 0==
a.charCode&&!m("opera")});var e={106:42,111:47,186:59,187:43,188:44,189:45,190:46,191:47,192:96,219:91,220:92,221:93,222:39,229:113},k=m("mac")?"metaKey":"ctrlKey",c=function(b,c){var d=h.mixin({},b,c);a(d);d.preventDefault=function(){b.preventDefault()};d.stopPropagation=function(){b.stopPropagation()};return d},t;t=m("events-keypress-typed")?function(a,b){var d=l(a,"keydown",function(a){var d=a.keyCode,f=13!=d&&32!=d&&(27!=d||!m("ie"))&&(48>d||90<d)&&(96>d||111<d)&&(186>d||192<d)&&(219>d||222<d)&&
229!=d;if(f||a.ctrlKey){f=f?0:d;if(a.ctrlKey){if(3==d||13==d)return b.call(a.currentTarget,a);f=95<f&&106>f?f-48:!a.shiftKey&&65<=f&&90>=f?f+32:e[f]||f}d=c(a,{type:"keypress",faux:!0,charCode:f});b.call(a.currentTarget,d);if(m("ie"))try{a.keyCode=d.keyCode}catch(g){}}}),f=l(a,"keypress",function(a){var d=a.charCode;a=c(a,{charCode:32<=d?d:0,faux:!0});return b.call(this,a)});return{remove:function(){d.remove();f.remove()}}}:m("opera")?function(a,b){return l(a,"keypress",function(a){var d=a.which;3==
d&&(d=99);d=32>d&&!a.shiftKey?0:d;a.ctrlKey&&!a.shiftKey&&65<=d&&90>=d&&(d+=32);return b.call(this,c(a,{charCode:d}))})}:function(b,c){return l(b,"keypress",function(b){a(b);return c.call(this,b)})};var q={_keypress:t,connect:function(a,b,c,d,e){var f=arguments,g=[],h=0;g.push("string"==typeof f[0]?null:f[h++],f[h++]);var k=f[h+1];g.push("string"==typeof k||"function"==typeof k?f[h++]:null,f[h++]);for(k=f.length;h<k;h++)g.push(f[h]);return p.apply(this,g)},disconnect:function(a){a&&a.remove()},subscribe:function(a,
b,c){return g.subscribe(a,h.hitch(b,c))},publish:function(a,b){return g.publish.apply(g,[a].concat(b))},connectPublisher:function(a,b,c){var d=function(){q.publish(a,arguments)};return c?q.connect(b,c,d):q.connect(b,d)},isCopyKey:function(a){return a[k]}};q.unsubscribe=q.disconnect;h.mixin(b,q);return q})},"dojo/errors/CancelError":function(){define(["./create"],function(b){return b("CancelError",null,null,{dojoType:"cancel"})})}}});
require({cache:{"dojo/date/stamp":function(){define(["../_base/lang","../_base/array"],function(b,l){var g={};b.setObject("dojo.date.stamp",g);g.fromISOString=function(b,d){g._isoRegExp||(g._isoRegExp=/^(?:(\d{4})(?:-(\d{2})(?:-(\d{2}))?)?)?(?:T(\d{2}):(\d{2})(?::(\d{2})(.\d+)?)?((?:[+-](\d{2}):(\d{2}))|Z)?)?$/);var f=g._isoRegExp.exec(b),m=null;if(f){f.shift();f[1]&&f[1]--;f[6]&&(f[6]*=1E3);d&&(d=new Date(d),l.forEach(l.map("FullYear Month Date Hours Minutes Seconds Milliseconds".split(" "),function(a){return d["get"+
a]()}),function(a,b){f[b]=f[b]||a}));m=new Date(f[0]||1970,f[1]||0,f[2]||1,f[3]||0,f[4]||0,f[5]||0,f[6]||0);100>f[0]&&m.setFullYear(f[0]||1970);var h=0,p=f[7]&&f[7].charAt(0);"Z"!=p&&(h=60*(f[8]||0)+(Number(f[9])||0),"-"!=p&&(h*=-1));p&&(h-=m.getTimezoneOffset());h&&m.setTime(m.getTime()+6E4*h)}return m};g.toISOString=function(b,d){var f=function(a){return 10>a?"0"+a:a};d=d||{};var g=[],h=d.zulu?"getUTC":"get",l="";"time"!=d.selector&&(l=b[h+"FullYear"](),l=["0000".substr((l+"").length)+l,f(b[h+"Month"]()+
1),f(b[h+"Date"]())].join("-"));g.push(l);if("date"!=d.selector){l=[f(b[h+"Hours"]()),f(b[h+"Minutes"]()),f(b[h+"Seconds"]())].join(":");h=b[h+"Milliseconds"]();d.milliseconds&&(l+="."+(100>h?"0":"")+f(h));if(d.zulu)l+="Z";else if("time"!=d.selector)var h=b.getTimezoneOffset(),a=Math.abs(h),l=l+((0<h?"-":"+")+f(Math.floor(a/60))+":"+f(a%60));g.push(l)}return g.join("T")};return g})},"dojo/parser":function(){define("require ./_base/kernel ./_base/lang ./_base/array ./_base/config ./dom ./_base/window ./_base/url ./aspect ./promise/all ./date/stamp ./Deferred ./has ./query ./on ./ready".split(" "),
function(b,l,g,n,d,f,m,h,p,a,e,k,c,t,q,r){function u(a){return eval("("+a+")")}function v(a){var b=a._nameCaseMap,c=a.prototype;if(!b||b._extendCnt<y){var b=a._nameCaseMap={},d;for(d in c)"_"!==d.charAt(0)&&(b[d.toLowerCase()]=d);b._extendCnt=y}return b}function x(a,c){var d=a.join();if(!z[d]){for(var e=[],f=0,h=a.length;f<h;f++){var k=a[f];e[e.length]=z[k]=z[k]||g.getObject(k)||~k.indexOf("/")&&(c?c(k):b(k))}f=e.shift();z[d]=e.length?f.createSubclass?f.createSubclass(e):f.extend.apply(f,e):f}return z[d]}
new Date("X");var y=0;p.after(g,"extend",function(){y++},!0);var z={},w={_clearCache:function(){y++;z={}},_functionFromScript:function(a,b){var c="",d="",e=a.getAttribute(b+"args")||a.getAttribute("args"),f=a.getAttribute("with"),e=(e||"").split(/\s*,\s*/);f&&f.length&&n.forEach(f.split(/\s*,\s*/),function(a){c+="with("+a+"){";d+="}"});return new Function(e,c+a.innerHTML+d)},instantiate:function(a,b,c){b=b||{};c=c||{};var d=(c.scope||l._scopeName)+"Type",e="data-"+(c.scope||l._scopeName)+"-",f=e+
"type",g=e+"mixins",h=[];n.forEach(a,function(a){var c=d in b?b[d]:a.getAttribute(f)||a.getAttribute(d);if(c){var e=a.getAttribute(g),c=e?[c].concat(e.split(/\s*,\s*/)):[c];h.push({node:a,types:c})}});return this._instantiate(h,b,c)},_instantiate:function(b,c,d,e){function f(a){c._started||d.noStart||n.forEach(a,function(a){"function"!==typeof a.startup||a._started||a.startup()});return a}b=n.map(b,function(a){var b=a.ctor||x(a.types,d.contextRequire);if(!b)throw Error("Unable to resolve constructor for: '"+
a.types.join()+"'");return this.construct(b,a.node,c,d,a.scripts,a.inherited)},this);return e?a(b).then(f):f(b)},construct:function(a,b,d,f,k,m){function r(a){z&&g.setObject(z,a);for(D=0;D<G.length;D++)p[G[D].advice||"after"](a,G[D].method,g.hitch(a,G[D].func),!0);for(D=0;D<H.length;D++)H[D].call(a);for(D=0;D<V.length;D++)a.watch(V[D].prop,V[D].func);for(D=0;D<N.length;D++)q(a,N[D].event,N[D].func);return a}var x=a&&a.prototype;f=f||{};var w={};f.defaults&&g.mixin(w,f.defaults);m&&g.mixin(w,m);var y;
c("dom-attributes-explicit")?y=b.attributes:c("dom-attributes-specified-flag")?y=n.filter(b.attributes,function(a){return a.specified}):(m=(/^input$|^img$/i.test(b.nodeName)?b:b.cloneNode(!1)).outerHTML.replace(/=[^\s"']+|="[^"]*"|='[^']*'/g,"").replace(/^\s*<[a-zA-Z0-9]*\s*/,"").replace(/\s*>.*$/,""),y=n.map(m.split(/\s+/),function(a){var c=a.toLowerCase();return{name:a,value:"LI"==b.nodeName&&"value"==a||"enctype"==c?b.getAttribute(c):b.getAttributeNode(c).value}}));var F=f.scope||l._scopeName;
m="data-"+F+"-";var A={};"dojo"!==F&&(A[m+"props"]="data-dojo-props",A[m+"type"]="data-dojo-type",A[m+"mixins"]="data-dojo-mixins",A[F+"type"]="dojoType",A[m+"id"]="data-dojo-id");for(var D=0,I,F=[],z,L;I=y[D++];){var C=I.name,M=C.toLowerCase();I=I.value;switch(A[M]||M){case "data-dojo-type":case "dojotype":case "data-dojo-mixins":break;case "data-dojo-props":L=I;break;case "data-dojo-id":case "jsid":z=I;break;case "data-dojo-attach-point":case "dojoattachpoint":w.dojoAttachPoint=I;break;case "data-dojo-attach-event":case "dojoattachevent":w.dojoAttachEvent=
I;break;case "class":w["class"]=b.className;break;case "style":w.style=b.style&&b.style.cssText;break;default:if(C in x||(C=v(a)[M]||C),C in x)switch(typeof x[C]){case "string":w[C]=I;break;case "number":w[C]=I.length?Number(I):NaN;break;case "boolean":w[C]="false"!=I.toLowerCase();break;case "function":""===I||-1!=I.search(/[^\w\.]+/i)?w[C]=new Function(I):w[C]=g.getObject(I,!1)||new Function(I);F.push(C);break;default:M=x[C],w[C]=M&&"length"in M?I?I.split(/\s*,\s*/):[]:M instanceof Date?""==I?new Date(""):
"now"==I?new Date:e.fromISOString(I):M instanceof h?l.baseUrl+I:u(I)}else w[C]=I}}for(y=0;y<F.length;y++)A=F[y].toLowerCase(),b.removeAttribute(A),b[A]=null;if(L)try{L=u.call(f.propsThis,"{"+L+"}"),g.mixin(w,L)}catch(aa){throw Error(aa.toString()+" in data-dojo-props\x3d'"+L+"'");}g.mixin(w,d);k||(k=a&&(a._noScript||x._noScript)?[]:t("\x3e script[type^\x3d'dojo/']",b));var G=[],H=[],V=[],N=[];if(k)for(D=0;D<k.length;D++)A=k[D],b.removeChild(A),d=A.getAttribute(m+"event")||A.getAttribute("event"),
f=A.getAttribute(m+"prop"),L=A.getAttribute(m+"method"),F=A.getAttribute(m+"advice"),y=A.getAttribute("type"),A=this._functionFromScript(A,m),d?"dojo/connect"==y?G.push({method:d,func:A}):"dojo/on"==y?N.push({event:d,func:A}):w[d]=A:"dojo/aspect"==y?G.push({method:L,advice:F,func:A}):"dojo/watch"==y?V.push({prop:f,func:A}):H.push(A);a=(k=a.markupFactory||x.markupFactory)?k(w,b,a):new a(w,b);return a.then?a.then(r):r(a)},scan:function(a,d){function e(a){if(!a.inherited){a.inherited={};var b=a.node,
c=e(a.parent),b={dir:b.getAttribute("dir")||c.dir,lang:b.getAttribute("lang")||c.lang,textDir:b.getAttribute(u)||c.textDir},d;for(d in b)b[d]&&(a.inherited[d]=b[d])}return a.inherited}var f=[],g=[],h={},m=(d.scope||l._scopeName)+"Type",p="data-"+(d.scope||l._scopeName)+"-",q=p+"type",u=p+"textdir",p=p+"mixins",r=a.firstChild,t=d.inherited;if(!t){var v=function(a,b){return a.getAttribute&&a.getAttribute(b)||a.parentNode&&v(a.parentNode,b)},t={dir:v(a,"dir"),lang:v(a,"lang"),textDir:v(a,u)},w;for(w in t)t[w]||
delete t[w]}for(var t={inherited:t},y,z;;)if(r)if(1!=r.nodeType)r=r.nextSibling;else if(y&&"script"==r.nodeName.toLowerCase())(C=r.getAttribute("type"))&&/^dojo\/\w/i.test(C)&&y.push(r),r=r.nextSibling;else if(z)r=r.nextSibling;else{var C=r.getAttribute(q)||r.getAttribute(m);w=r.firstChild;if(C||w&&(3!=w.nodeType||w.nextSibling)){z=null;if(C){var M=r.getAttribute(p);y=M?[C].concat(M.split(/\s*,\s*/)):[C];try{z=x(y,d.contextRequire)}catch(H){}z||n.forEach(y,function(a){~a.indexOf("/")&&!h[a]&&(h[a]=
!0,g[g.length]=a)});M=z&&!z.prototype._noScript?[]:null;t={types:y,ctor:z,parent:t,node:r,scripts:M};t.inherited=e(t);f.push(t)}else t={node:r,scripts:y,parent:t};y=M;z=r.stopParser||z&&z.prototype.stopParser&&!d.template;r=w}else r=r.nextSibling}else{if(!t||!t.node)break;r=t.node.nextSibling;z=!1;t=t.parent;y=t.scripts}var G=new k;g.length?(c("dojo-debug-messages"),(d.contextRequire||b)(g,function(){G.resolve(n.filter(f,function(a){if(!a.ctor)try{a.ctor=x(a.types,d.contextRequire)}catch(b){}for(var c=
a.parent;c&&!c.types;)c=c.parent;var e=a.ctor&&a.ctor.prototype;a.instantiateChildren=!(e&&e.stopParser&&!d.template);a.instantiate=!c||c.instantiate&&c.instantiateChildren;return a.instantiate}))})):G.resolve(f);return G.promise},_require:function(a,c){var d=u("{"+a.innerHTML+"}"),e=[],f=[],h=new k,m=c&&c.contextRequire||b,l;for(l in d)e.push(l),f.push(d[l]);m(f,function(){for(var a=0;a<e.length;a++)g.setObject(e[a],arguments[a]);h.resolve(arguments)});return h.promise},_scanAmd:function(a,b){var c=
new k,d=c.promise;c.resolve(!0);var e=this;t("script[type\x3d'dojo/require']",a).forEach(function(a){d=d.then(function(){return e._require(a,b)});a.parentNode.removeChild(a)});return d},parse:function(a,b){!a||"string"==typeof a||"nodeType"in a||(b=a,a=b.rootNode);var c=a?f.byId(a):m.body();b=b||{};var d=b.template?{template:!0}:{},e=[],h=this,k=this._scanAmd(c,b).then(function(){return h.scan(c,b)}).then(function(a){return h._instantiate(a,d,b,!0)}).then(function(a){return e=e.concat(a)}).otherwise(function(a){throw a;
});g.mixin(e,k);return e}};l.parser=w;d.parseOnLoad&&r(100,w,"parse");return w})},"dojo/_base/url":function(){define(["./kernel"],function(b){var l=/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/,g=/^((([^\[:]+):)?([^@]+)@)?(\[([^\]]+)\]|([^\[:]*))(:([0-9]+))?$/,n=function(){for(var b=arguments,f=[b[0]],m=1;m<b.length;m++)if(b[m]){var h=new n(b[m]+""),f=new n(f[0]+"");if(""==h.path&&!h.scheme&&!h.authority&&!h.query)null!=h.fragment&&(f.fragment=h.fragment),h=f;else if(!h.scheme&&(h.scheme=
f.scheme,!h.authority&&(h.authority=f.authority,"/"!=h.path.charAt(0)))){for(var f=(f.path.substring(0,f.path.lastIndexOf("/")+1)+h.path).split("/"),p=0;p<f.length;p++)"."==f[p]?p==f.length-1?f[p]="":(f.splice(p,1),p--):0<p&&(1!=p||""!=f[0])&&".."==f[p]&&".."!=f[p-1]&&(p==f.length-1?(f.splice(p,1),f[p-1]=""):(f.splice(p-1,2),p-=2));h.path=f.join("/")}f=[];h.scheme&&f.push(h.scheme,":");h.authority&&f.push("//",h.authority);f.push(h.path);h.query&&f.push("?",h.query);h.fragment&&f.push("#",h.fragment)}this.uri=
f.join("");b=this.uri.match(l);this.scheme=b[2]||(b[1]?"":null);this.authority=b[4]||(b[3]?"":null);this.path=b[5];this.query=b[7]||(b[6]?"":null);this.fragment=b[9]||(b[8]?"":null);null!=this.authority&&(b=this.authority.match(g),this.user=b[3]||null,this.password=b[4]||null,this.host=b[6]||b[7],this.port=b[9]||null)};n.prototype.toString=function(){return this.uri};return b._Url=n})},"dojo/promise/all":function(){define(["../_base/array","../Deferred","../when"],function(b,l,g){var n=b.some;return function(b){var f,
m;b instanceof Array?m=b:b&&"object"===typeof b&&(f=b);var h,p=[];if(f){m=[];for(var a in f)Object.hasOwnProperty.call(f,a)&&(p.push(a),m.push(f[a]));h={}}else m&&(h=[]);if(!m||!m.length)return(new l).resolve(h);var e=new l;e.promise.always(function(){h=p=null});var k=m.length;n(m,function(a,b){f||p.push(b);g(a,function(a){e.isFulfilled()||(h[p[b]]=a,0===--k&&e.resolve(h))},e.reject);return e.isFulfilled()});return e.promise}})},"mojo/signup-forms/embed":function(){define([],1)},"dojo/request/script":function(){define("module ./watch ./util ../_base/kernel ../_base/array ../_base/lang ../on ../dom ../dom-construct ../has ../_base/window".split(" "),
function(b,l,g,n,d,f,m,h,p,a,e){function k(a,b){a.canDelete&&u._remove(a.id,b.options.frameDoc,!0)}function c(a){B&&B.length&&(d.forEach(B,function(a){u._remove(a.id,a.frameDoc);a.frameDoc=null}),B=[]);return a.options.jsonp?!a.data:!0}function t(a){return!!this.scriptLoaded}function q(a){return(a=a.options.checkString)&&eval("typeof("+a+') !\x3d\x3d "undefined"')}function r(a,b){if(this.canDelete){var c=this.response.options;B.push({id:this.id,frameDoc:c.ioArgs?c.ioArgs.frameDoc:c.frameDoc});c.ioArgs&&
(c.ioArgs.frameDoc=null);c.frameDoc=null}b?this.reject(b):this.resolve(a)}function u(a,b,d){var e=g.parseArgs(a,g.deepCopy({},b));a=e.url;b=e.options;var h=g.deferred(e,k,c,b.jsonp?null:b.checkString?q:t,r);f.mixin(h,{id:v+x++,canDelete:!1});b.jsonp&&((new RegExp("[?\x26]"+b.jsonp+"\x3d")).test(a)||(a+=(~a.indexOf("?")?"\x26":"?")+b.jsonp+"\x3d"+(b.frameDoc?"parent.":"")+v+"_callbacks."+h.id),h.canDelete=!0,w[h.id]=function(a){e.data=a;h.handleResponse(e)});g.notify&&g.notify.emit("send",e,h.promise.cancel);
if(!b.canAttach||b.canAttach(h)){var n=u._attach(h.id,a,b.frameDoc);if(!b.jsonp&&!b.checkString)var p=m(n,y,function(a){if("load"===a.type||z.test(n.readyState))p.remove(),h.scriptLoaded=a})}l(h);return d?h:h.promise}a.add("script-readystatechange",function(a,b){return"undefined"!==typeof b.createElement("script").onreadystatechange&&("undefined"===typeof a.opera||"[object Opera]"!==a.opera.toString())});var v=b.id.replace(/[\/\.\-]/g,"_"),x=0,y=a("script-readystatechange")?"readystatechange":"load",
z=/complete|loaded/,w=n.global[v+"_callbacks"]={},B=[];u.get=u;u._attach=function(a,b,c){c=c||e.doc;var d=c.createElement("script");d.type="text/javascript";d.src=b;d.id=a;d.async=!0;d.charset="utf-8";return c.getElementsByTagName("head")[0].appendChild(d)};u._remove=function(a,b,c){p.destroy(h.byId(a,b));w[a]&&(c?w[a]=function(){delete w[a]}:delete w[a])};u._callbacksProperty=v+"_callbacks";return u})},"dijit/main":function(){define(["dojo/_base/kernel"],function(b){return b.dijit})},"dijit/_base/manager":function(){define(["dojo/_base/array",
"dojo/_base/config","dojo/_base/lang","../registry","../main"],function(b,l,g,n,d){var f={};b.forEach("byId getUniqueId findWidgets _destroyAll byNode getEnclosingWidget".split(" "),function(b){f[b]=n[b]});g.mixin(f,{defaultDuration:l.defaultDuration||200});g.mixin(d,f);return d})},"dijit/registry":function(){define(["dojo/_base/array","dojo/_base/window","./main"],function(b,l,g){var n={},d={},f={length:0,add:function(b){if(d[b.id])throw Error("Tried to register widget with id\x3d\x3d"+b.id+" but that id is already registered");
d[b.id]=b;this.length++},remove:function(b){d[b]&&(delete d[b],this.length--)},byId:function(b){return"string"==typeof b?d[b]:b},byNode:function(b){return d[b.getAttribute("widgetId")]},toArray:function(){var b=[],f;for(f in d)b.push(d[f]);return b},getUniqueId:function(b){var f;do f=b+"_"+(b in n?++n[b]:n[b]=0);while(d[f]);return"dijit"==g._scopeName?f:g._scopeName+"_"+f},findWidgets:function(b,f){function g(b){for(b=b.firstChild;b;b=b.nextSibling)if(1==b.nodeType){var k=b.getAttribute("widgetId");
k?(k=d[k])&&a.push(k):b!==f&&g(b)}}var a=[];g(b);return a},_destroyAll:function(){g._curFocus=null;g._prevFocus=null;g._activeStack=[];b.forEach(f.findWidgets(l.body()),function(b){b._destroyed||(b.destroyRecursive?b.destroyRecursive():b.destroy&&b.destroy())})},getEnclosingWidget:function(b){for(;b;){var f=1==b.nodeType&&b.getAttribute("widgetId");if(f)return d[f];b=b.parentNode}return null},_hash:d};return g.registry=f})},"mojo/signup-forms/Loader":function(){define(["dojo/request/script"],function(b){return{start:function(l){if(!(l&&
l.baseUrl&&l.uuid&&l.lid))throw Error("Missing base url, uuid and/or lid parameter(s).");var g=this._getFormSettingsUrl(l.baseUrl,l.uuid,l.lid),n=this._getFormSubscribeUrl(l.baseUrl,l.uuid,l.lid),d=this._getHoneypotFieldName(l.uuid,l.lid),f=this._getEnv(l.env),m=this;b.get(g,{jsonp:"c",query:{u:l.uuid,id:l.lid}}).then(function(b){"error"!=b.result&&("dev"===f?m._setupPopupSignupForm(b,n,d,f):require(["//downloads.mailchimp.com/js/signup-forms/popup/"+b.version+"/popup.js"],function(){m._setupPopupSignupForm(b,
n,d,f)}))})},_getFormSettingsUrl:function(b,g,n){return"//"+b+"/subscribe/form-settings?u\x3d"+g+"\x26id\x3d"+n},_getFormSubscribeUrl:function(b,g,n){return"//"+b+"/subscribe/form-post?u\x3d"+g+"\x26id\x3d"+n+"\x26popup\x3dtrue"},_getHoneypotFieldName:function(b,g){return"b_"+b+"_"+g},_getEnv:function(b){return"undefined"===typeof b?"prod":"dev"},_setupPopupSignupForm:function(b,g,n,d){require(["mojo/signup-forms/PopupSignupForm"]);b=new PopupSignupForm({config:b,subscribeUrl:g,honeypotFieldName:n,
env:d});b.placeAt(document.body);b.startup()}}})}}});(function(){var b=this.require;b({cache:{}});!b.async&&b(["dojo"]);b.boot&&b.apply(null,b.boot)})();